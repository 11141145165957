import * as React from 'react';
import AppContext from '../../context';
import {
    faGroupCreate, faGroupUpdate, faGroupDelete, faAnswerSubmit,
    facilities as selectFacilities, faQuestionsList
} from '../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate } from './../../components/Components';

const initValues = {
    facility_id: null,
    name: "",
}

export default function FacilityAssessment({ history }) {
    const queryClient = useQueryClient();

    const [queryName] = React.useState('facility-assessment-group');
    const [formID] = React.useState('form-facility-assessment-group');
    const { toggleFormState } = useFormState(formID);

    const { dispatch, setQueryString, useQueryString, setStateData, notify, currentPath,
        formActionType, errorResponse, formatDate, checkObject, checkArray } = React.useContext(AppContext);
    let queryString = useQueryString();
    const [defaultValues, setDefaultValues] = React.useState(initValues);
    const [facilities, setFacilities] = React.useState([]);
    const [faQuestions, setFaQuestions] = React.useState([]);
    const [faGroup, setFaGroup] = React.useState([]);
    const [currentIndex, setCurrentIndex] = React.useState(1);
    const [formsData, setFormsData] = React.useState({});

    /* Modal hook */
    const { modalState, setModalState, modalTitle, setModalTitle, closeModal } = useModal();

    const [query, setQuery] = React.useState({
        page: (queryString.get("page") && !isNaN(queryString.get("page"))
            ? Number(queryString.get("page")) : 1)
    });

    const handleCloseModal = () => {
        setModalState(false);
        setDefaultValues(initValues);
    }

    /* Facilities */
    useQuery('select-facilities', () => selectFacilities(''), {
        onError: (error) => setFacilities([]),
        onSuccess: ({ data }) => {
            if (data.length > 0) {
                const faformat = data.map(dt => ({ value: dt.id, label: dt.name, lga: dt.lga }))
                setFacilities(faformat);
            }
        },
    });

    /* Facility assessment questions */
    useQuery('select-faqs', faQuestionsList, {
        onError: (error) => setFaQuestions([]),
        onSuccess: (data) => {
            if (data.length > 0) {
                const result = data.reduce((h, dt) => Object.assign(h, {
                    [dt.group_id]: (h[dt.group_id] || [])
                        .concat(dt)/* .map((dd, index) => {
                            let group = dd?.group;
                            group = { ...group, order: (index + 1) }
                            return { ...dd, group }
                        }) */
                }), {});

                /* const rs = data.reduce((h, dt) => Object.assign(h, {
                    [dt?.group?.name]: (h[dt?.group?.name] || [])
                        .concat(dt)
                }), {});
                 */

                //const bGroup = Object.entries(result).map((value, key) => ({ name: value[0], key }));
                const bGroup = Object.entries(result).map((value, key) => value[1][0])
                    .map((dd, index) => ({ id: dd.group_id, name: dd?.group?.name, index: (index + 1) }));
                console.log(bGroup, result, 'result..');

                setFaQuestions(result);
                setFaGroup(bGroup);

                /* const result = data.reduce((h, dt) => Object.assign(h, {
                    [dt?.benefit_type?.name]: (h[dt?.benefit_type?.name] || [])
                        .concat({ value: dt.id, label: dt.name })
                }), {});
                //console.log(result, 'result..')
                //const result = data.map(dt => ({ value: dt.id, label: dt.name }));
                const bGroup = Object.entries(result).map((value, key) => value[0]);
                //console.log(bGroup, 'result..');
                setBenefits(result);
                setBenefitGroup(bGroup); */
            }
        },
    });

    /* Page data */
    const { isLoading, isFetching, isError, data, error } = useQuery([queryName, query],
        () => selectFacilities(setQueryString(query)), {
        keepPreviousData: true,
        staleTime: 5000,
        onError: (error) => errorResponse({ error, history, dispatch }),
        //onSuccess: () => 
    });

    /* Add data */
    const { mutate: handleSubmitAdd } = useMutation(({ id, values }) => faAnswerSubmit({ id, values }), {
        onSuccess: () => {
            handleCloseModal();
            notify('success', 'Data Added', 'New data successfully added!');
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => {
            toggleFormState(false);
            queryClient.refetchQueries([queryName, query])
        },
    });

    /* Edit data */
    const { mutate: handleSubmitEdit } = useMutation(({ id, values }) => faGroupUpdate({ id, values }), {
        onSuccess: () => {
            notify('success', 'Data Modified', 'Data successfully modified!');
            handleCloseModal();
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => {
            queryClient.refetchQueries([queryName, query]);
            toggleFormState(false);
        }
    });

    /* Delete data */
    const { mutate: handleSubmitDelete } = useMutation(id => faGroupDelete(id), {
        onSuccess: () => {
            notify('success', 'Data Deleted', 'Data successfully deleted!');
        },
        onMutate: (id) => {
            queryClient.cancelQueries([queryName, query]);
            const previousData = queryClient.getQueryData([queryName, query]);
            const updateValue = previousData?.data;

            const removeDeleted = updateValue.filter(dt => dt.id !== id);
            const newData = { ...previousData, data: removeDeleted };
            return queryClient.setQueryData([queryName, query], newData);
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => queryClient.refetchQueries([queryName, query])
    });

    /* Requery on data, query change */
    React.useEffect(() => {
        if (data?.next_page_url) {
            let nextPage = { ...query, page: query.page + 1 };
            queryClient.prefetchQuery([queryName, nextPage],
                () => selectFacilities(setQueryString(nextPage)))
        }
    }, [data, query, queryClient])


    /* handle paginate data */
    const handlePageClick = ({ selected }) => {
        const page = selected + 1;
        let nQ = { ...query, page };
        setQuery(nQ);
        history.push(`${currentPath}${setQueryString(nQ)}`);
    }

    const initAdd = () => {
        setModalTitle('Facility Assesment');
        setStateData(dispatch, 'formActionType', 1);
        setModalState(true);
    }

    const initEdit = (row) => {
        setModalTitle('Edit Assessment Group');
        setStateData(dispatch, 'formActionType', 2);
        setDefaultValues(row)
        setModalState(true);
    }

    const onSubmit = async (formsData, facility_id) => {
        //console.log(formsData, 'formsData...')
        toggleFormState(true, 'saving...');
        let formData = new FormData();
        //formData.append('facility_id', facility_id)

        if (checkObject(formsData)) {
            Object.entries(formsData).forEach(async ([key, value]) => {
                await new Promise(resolve => formData.append(`${key}`, value[0]?.size ? value[0] : value));
            });
        }
        //console.log([...formData.entries()], 'formData.entries..')
        handleSubmitAdd({ id: facility_id, values: formData })
    }

    const initDelete = (id) => {
        const conf = window.confirm('Are you sure?');
        if (!conf) return;
        handleSubmitDelete(id);
    }

    const handeSelectFacility = (data) => {
        //console.log(data, 'data..')
        setDefaultValues({ ...defaultValues, facility_id: data?.facility_id });
    }

    return (<>
        {isLoading && <div>loading...</div>}
        {!isLoading && error && <div>error: {error.message}...</div>}

        {data?.data && (<div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col float-right">
                                <div className="text-sm-right">
                                    <button type="button" onClick={initAdd}
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                                        <i className="mdi mdi-plus mr-1"></i> New Assessment
                                        </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <Modal.Header closeButton>
                                    <Modal.Title>Facility Assesment</Modal.Title>
                                </Modal.Header>
                                <Form
                                    handleCloseModal={handleCloseModal}
                                    defaultValues={defaultValues}
                                    facilities={facilities}
                                    onSubmit={onSubmit}
                                    handeSelectFacility={handeSelectFacility}
                                    formID={formID}
                                    faQuestions={faQuestions}
                                    faGroup={faGroup}
                                    currentIndex={currentIndex}
                                    setCurrentIndex={setCurrentIndex}
                                    formsData={formsData}
                                    setFormsData={setFormsData}
                                />
                            </div>
                        </div> */}

                        {!isLoading && data?.data && data?.data?.length === 0 && <div className="no-data-box">No data found!</div>}

                        {data?.data?.length > 0 && (<div className="table-responsive">
                            <table className="table table-centered table-nowrap table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-right">Created At</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data?.map((row) => {
                                        return (<tr key={row.id}>
                                            <td>{row.name}</td>
                                            <td className="text-center">
                                                <span className="badge badge-success font-size-12">Active</span>
                                            </td>
                                            <td className="text-right">{formatDate(row.created_at)}</td>
                                            <td width="1%" className="text-center">
                                                <div className="dropdown">
                                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><a
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => initEdit(row)}
                                                            className="dropdown-item"><i className="fas fa-pencil-alt text-success mr-1"></i> Edit</a></li>
                                                        <li><a style={{ cursor: 'pointer' }}
                                                            onClick={() => initDelete(row.id)}
                                                            className="dropdown-item">
                                                            <i className="fas fa-trash-alt text-danger mr-1"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            <Paginate data={data} onPageChange={handlePageClick} />
                        </div>)}

                    </div>
                </div>
            </div>
        </div>)}

        <Modal show={modalState} onHide={handleCloseModal} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Form
                handleCloseModal={handleCloseModal}
                defaultValues={defaultValues}
                facilities={facilities}
                onSubmit={onSubmit}
                handeSelectFacility={handeSelectFacility}
                formID={formID}
                faQuestions={faQuestions}
                faGroup={faGroup}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                formsData={formsData}
                setFormsData={setFormsData}
            />
        </Modal>
    </>);
}