import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from './../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	formID,
	mdas,
}) {
	const { register, errors, handleSubmit } = useForm({ criteriaMode: 'all' });

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='row'>
						<div className='form-group col-md-6'>
							<label>First Name</label>
							<input
								type='text'
								className='form-control'
								name='first_name'
								placeholder='First Name'
								defaultValue={defaultValues.first_name}
								ref={register({
									required: 'First name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='first_name' />
						</div>
						<div className='form-group col-md-6'>
							<label>Last Name</label>
							<input
								type='text'
								className='form-control'
								name='last_name'
								placeholder='Last Name'
								defaultValue={defaultValues.last_name}
								ref={register({
									required: 'Last name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='last_name' />
						</div>

						<div className='form-group col-md-6'>
							<label>Email Address</label>
							<input
								type='text'
								className='form-control'
								name='email'
								placeholder='Email Address'
								defaultValue={defaultValues.email}
								ref={register({
									required: 'Email address is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='email' />
						</div>
						<div className='form-group col-md-6'>
							<label>Phone Number</label>
							<input
								type='number'
								className='form-control'
								name='phone'
								placeholder='Phone Number'
								defaultValue={defaultValues.phone}
								ref={register({
									required: 'Phone number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='phone' />
						</div>

						<div className='form-group col-md-6'>
							<label>Password</label>
							<input
								type='password'
								className='form-control'
								name='password'
								placeholder='Password'
								ref={register({
									required: 'Password is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='password' />
						</div>

						<div className='form-group col-md-6'>
							<label>Confirm Password</label>
							<input
								type='password'
								className='form-control'
								name='password_confirmation'
								placeholder='Confirm Password'
								ref={register({
									required: 'Password confirmation is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='password_confirmation' />
						</div>

						<div className='form-group col'>
							<label>Business Name</label>
							<input
								type='text'
								className='form-control'
								name='business_name'
								placeholder='Business Name'
								defaultValue={defaultValues.business_name}
								ref={register}
							/>
							<ErrorMsg errors={errors} name='business_name' />
						</div>

						<div className='form-group col-12'>
							<label>Address</label>
							<textarea
								className='form-control'
								name='full_address'
								ref={register({
									required: 'Address is required!',
								})}
								defaultValue={defaultValues.full_address}
								placeholder='Address'
							></textarea>
							<ErrorMsg errors={errors} name='full_address' />
						</div>

						<div className='form-group col-md-6'>
							<label>Bank Name</label>
							<input
								type='text'
								className='form-control'
								name='bank'
								placeholder='Bank Name'
								defaultValue={defaultValues.bank}
								ref={register({
									required: 'Bank name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='bank' />
						</div>
						<div className='form-group col-md-6'>
							<label>Account Number</label>
							<input
								type='text'
								className='form-control'
								name='account_number'
								placeholder='Account Number'
								defaultValue={defaultValues.account_number}
								ref={register({
									required: 'Account number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='account_number' />
						</div>

						<div className='form-group col-12'>
							<label>Commission Percent</label>
							<input
								type='number'
								step='any'
								className='form-control'
								name='commission_percent'
								min={0}
								placeholder='Commission Percent'
								defaultValue={defaultValues.commission_percent}
								ref={register({
									required: 'Commission percent is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='commission_percent' />
						</div>

						<div className='form-group col-12'>
							<label>Guarantor Name</label>
							<input
								type='text'
								className='form-control'
								name='guarantor_name'
								placeholder='Guarantor Name'
								defaultValue={defaultValues.guarantor_name}
								ref={register}
							/>
						</div>

						<div className='form-group col-12'>
							<label>Guarantor Address</label>
							<textarea
								className='form-control'
								name='guarantor_address'
								ref={register}
								defaultValue={defaultValues.guarantor_address}
								placeholder='Guarantor Address'
							></textarea>
						</div>

						<div className='form-group col-md-6'>
							<label>Guarantor Email</label>
							<input
								type='text'
								className='form-control'
								name='guarantor_email'
								placeholder='Guarantor Email'
								defaultValue={defaultValues.guarantor_email}
								ref={register}
							/>
						</div>
						<div className='form-group col-md-6'>
							<label>Guarantor Phone Number</label>
							<input
								type='number'
								className='form-control'
								name='guarantor_phone'
								placeholder='Guarantor Phone Number'
								defaultValue={defaultValues.guarantor_phone}
								ref={register}
							/>
						</div>

						<div className='form-group col-12'>
							<label>MDA</label>
							<select
								className='form-control'
								name='mda'
								defaultValue={defaultValues.mda}
								ref={register}
							>
								<option value=''> - Select -</option>
								{mdas &&
									mdas.length > 0 &&
									mdas.map((row, index) => {
										return (
											<option key={index} value={row}>
												{row}
											</option>
										);
									})}
							</select>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
