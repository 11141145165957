import * as React from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from './../../components/Components';
import Qforms from './../../components/Qforms';
import Select from 'react-select';

export default function Form({ handleCloseModal, onSubmit, defaultValues, formID, facilities,
    handeSelectFacility, faQuestions, faGroup, currentIndex, setCurrentIndex, formsData, setFormsData }) {
    const { lengthValidate, checkObject, capitalize } = React.useContext(AppContext);
    const { register, errors, handleSubmit, control, getValues } = useForm({ criteriaMode: "all", defaultValues });
    const [totalGroup] = React.useState(faGroup.length)

    /* const onsubmit = (data) => {
        const totalGroup = faGroup.length;
        //const newFormsData = Object.assign({}, formsData, data);
        const newFormsData = { ...formsData, ...data };
        console.log(newFormsData, 'newFormsData..');
        setFormsData(newFormsData);
        if (currentIndex === totalGroup) {
            onSubmit(newFormsData);
        } else {
            setCurrentIndex(currentIndex + 1)
        }

    } 

    const onsubmit = (data) => {
        //const newFormsData = Object.assign({}, formsData, data);
        console.log(formData, 'current newFormsData..');
        formData = { ...formData, ...data };
        console.log(formData, 'newFormsData..');
        //setFormsData(newFormsData);
        if (currentIndex === totalGroup) {
            //onSubmit(formData);
        } else {
            setCurrentIndex(currentIndex + 1)
        }

    }
    */

    const onsubmit = (data) => {
        const newfd = { ...formsData, ...data };
        setFormsData(newfd)
        if (currentIndex === totalGroup) {
            onSubmit(newfd, defaultValues?.facility_id?.value);
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }

    React.useEffect(() => {
        console.log(currentIndex, 'currentIndex...')
    }, [currentIndex])


    return (<>
        {defaultValues.facility_id ? (<>


            <Modal.Body>

                <div className="form-group">
                    <label className="mb-0"><b>Facility</b></label>
                    <div>{defaultValues?.facility_id.label}, {defaultValues?.facility_id.lga}</div>
                </div>
                <hr />

                {checkObject(faQuestions) && Object.entries(faQuestions).map((value, key) => {
                    const group = faGroup.filter(ft => ft.id === Number(value[0]))[0];
                    const active = group.index === currentIndex ? 'q-form-active' : '';
                    const questions = value[1];
                    return (<div key={key}>
                        {group.index === currentIndex && (
                            <form id={formID} onSubmit={handleSubmit(onsubmit)}>

                                <div className="q-slide-box">
                                    <div className="q-count">Questionaire {currentIndex} of {totalGroup}</div>

                                    <div className="q-header-box">
                                        <div className="q-title">{group?.name}</div>
                                        <div className="q-nav">
                                            {currentIndex !== 1 && currentIndex !== totalGroup ? (
                                                <i onClick={() => setCurrentIndex(currentIndex - 1)} className="dripicons-chevron-left"></i>
                                            ) : (<i className="dripicons-chevron-left"></i>)}
                                            
                                            <i className="dripicons-chevron-right"></i>
                                        </div>
                                    </div>
                                    <div className="q-stage-bar">
                                        <div className={`q-stage`} style={{ width: (currentIndex * (100 / totalGroup)) + '%' }} />
                                    </div>
                                </div>



                                {questions && questions.length > 0 && questions.map((quest, index) => {
                                    return (<Qforms
                                        key={quest.id}
                                        data={quest}
                                        register={register}
                                        ErrorMsg={ErrorMsg}
                                        errors={errors}
                                        getValues={getValues}
                                        lengthValidate={lengthValidate}
                                    />)
                                })}

                                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                                    <input type="button" onClick={handleCloseModal}
                                        className="btn btn-default" value="Cancel" />
                                    <div>
                                        <button type="submit" className="btn btn-success btn-main">
                                            {totalGroup === currentIndex ? 'Submit Assessment' : 'Continue'}
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </form>)}

                    </div>)
                })}


            </Modal.Body>

        </>) : (<form id={formID} onSubmit={handleSubmit(handeSelectFacility)}>

            <Modal.Body>

                <div className="form-group">
                    <label>Select Facility to begin</label>
                    <Controller
                        name="facility_id"
                        control={control}
                        rules={{ required: 'Please select facility!' }}
                        render={({ onChange, value }) => (
                            <Select
                                onChange={onChange}
                                options={facilities}
                                className="basic-single"
                                classNamePrefix="form-control"
                            />
                        )}
                    />
                    <ErrorMsg errors={errors} name="facility_id" />
                </div>

            </Modal.Body>

            <Modal.Footer style={{ justifyContent: 'space-between' }}>
                <input type="button" onClick={handleCloseModal}
                    className="btn btn-default" value="Cancel" />
                <div>
                    <button type="submit" className="btn btn-success" >Continue</button>
                </div>
            </Modal.Footer>
        </form >)
        }
    </>);
}