import { useState, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Modal } from 'react-bootstrap';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import useRouter from '../../hooks/useRouter';

import AppContext from '../../context';

import { initiateFacilityChange } from '../../Services';

const InitiateFacilityChange = ({
	data,
	facilities,
	loading,
	setFacilityChangeModalState,
}) => {
	const queryClient = useQueryClient();
	const router = useRouter();

	const { dispatch, notify, errorResponse } = useContext(AppContext);

	const [newFacility, setNewFacility] = useState('');
	const [comment, setComment] = useState('');
	const [queryName] = useState('enrollee-details');
	const [query, setQuery] = useState(router.query);

	const facilityOptions = facilities?.map((facility) => ({
		name: facility.name,
		value: facility.uuid,
	}));

	const { mutate: handleFacilityChange } = useMutation(
		(values) => initiateFacilityChange(values),
		{
			onSuccess: () => {
				notify(
					'success',
					'Facility Change Requested',
					'Facility Change successfully Requested!'
				);

				setNewFacility('');
				setComment('');
				setFacilityChangeModalState(false);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const handleChangeFacility = (newFacility) => {
		const facilityChangeData = {
			comment: comment,
			enrollee_uuid: data?.uuid,
			from_uuid: data?.facilities && data?.facilities[0].uuid,
			to_uuid: newFacility,
		};

		handleFacilityChange(facilityChangeData);
	};

	if (loading) return <h4>Loading...</h4>;

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>Change Facility - {data?.insurance_id}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='col-12'>
					<h3>Current Facility</h3>
					<p>
						<b>{data?.facilities && data?.facilities[0].name}</b>
					</p>
				</div>
				<div className='col-md-6 my-4'>
					<div className='form-group'>
						<label>New Facility</label>
						<SelectSearch
							search
							filterOptions={fuzzySearch}
							options={facilityOptions}
							value={newFacility}
							name='facility'
							placeholder='Select Facility'
							onChange={(value) => setNewFacility(value)}
						/>
					</div>
				</div>
				<div className='col-12'>
					<label>Comment</label>
					<textarea
						className='form-control my-3'
						name='comment'
						cols='30'
						rows='5'
						placeholder='Your comment here'
						onChange={(e) => setComment(e.target.value)}
					></textarea>
				</div>
				<div className='col-md-6'>
					<button
						className='btn btn-primary w-75'
						onClick={() => handleChangeFacility(newFacility)}
					>
						Submit
					</button>
				</div>
			</Modal.Body>
		</>
	);
};

export default InitiateFacilityChange;
