import { useEffect, useState } from 'react';

import notificationSound from '../assets/audio/notification.mp3';

const useAudio = () => {
	const [audio] = useState(new Audio(notificationSound));
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [audio, playing]);

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));

		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, [audio]);

	return [playing, toggle];
};

export default useAudio;
