const enrollmentSummaryOptions = {
	chart: {
		id: 'enrolment-summary',
		toolbar: {
			show: false,
		},
	},
	xaxis: {
		categories: [],
	},
	yaxis: {
		show: false,
	},
	colors: ['#389E7F'],
	dataLabels: {
		enabled: false,
	},
	grid: {
		show: false,
	},
	noData: {
		text: 'no data',
		align: 'center',
		verticalAlign: 'middle',
		offsetX: 0,
		offsetY: 0,
		style: {
			color: undefined,
			fontSize: '14px',
			fontFamily: undefined,
		},
	},
};

export default enrollmentSummaryOptions;
