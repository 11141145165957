import * as React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import 'react-datepicker/dist/react-datepicker.css';
import '../../utils/select-search.css';

import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Form({
	searchValues,
	onExportSubmit,
	resetExport,
	lgas,
	packages,
	searchType,
	closeModal,
	onSearchSubmit,
	setSearchValues,
	exporting,
	exportData,
	exportReady,
	mdas,
	facilities,
}) {
	const [startDate, setStartDate] = React.useState(searchValues?.start_date);
	const [endDate, setEndDate] = React.useState(searchValues?.end_date);
	const [type, setType] = React.useState(1);

	const form = document.getElementById('form-search');
	const allElements = form?.elements;

	React.useEffect(() => {
		setSearchValues({
			...searchValues,
			start_date: startDate,
			end_date: endDate,
		});
	}, [startDate, endDate]);

	React.useEffect(() => {
		if (exporting) {
			if (allElements && allElements.length > 0) {
				for (let i = 0, l = allElements.length; i < l; ++i) {
					allElements[i].disabled = true;
				}
			}
		} else {
			if (allElements && allElements.length > 0) {
				for (let i = 0, l = allElements.length; i < l; ++i) {
					allElements[i].disabled = false;
				}
			}
		}
	}, [exporting]);

	const handleSearchChange = (e) => {
		const { name, value } = e.target;
		setSearchValues({ ...searchValues, [name]: value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		let newSearch = {};

		if (
			(searchValues?.start_date && !searchValues?.end_date) ||
			(searchValues?.end_date && !searchValues?.start_date)
		) {
			alert('Both start and end date must be selected!');
			return;
		}

		if (searchValues) {
			Object.entries(searchValues).forEach(async ([key, value]) => {
				if (
					value ||
					(key === 'has_nin' && value === 0) ||
					(key === 'is_dependant' && value === 0)
				) {
					if (key === 'start_date' || key === 'end_date') {
						newSearch = {
							...newSearch,
							[key]: moment(value).format('YYYY-MM-DD'),
						};
					} else {
						newSearch = {
							...newSearch,
							[key]: value,
						};
					}
				}
			});
		}

		if (Object.keys(newSearch).length === 0) {
			alert('At least one search value is required!');
			return;
		}

		const { page: remove, ...params } = newSearch;

		if (searchType === 1) {
			onSearchSubmit(params);
		} else {
			onExportSubmit(params);
		}
	};

	const allMDAs =
		mdas && mdas.length
			? [{ name: '- MDA/Business Name -', value: '' }].concat(
					mdas.sort().map((row, index) => {
						return { name: row, value: row };
					})
			  )
			: [];

	const hasNIN = [
		{ name: '- Has NIN -', value: '' },
		{ name: 'Yes', value: 1 },
		{ name: 'No', value: 0 },
	];

	const enrolleeType = [
		{ name: '- Enrollee Type -', value: '' },
		{ name: 'Principals', value: 0 },
		{ name: 'Dependants', value: 1 },
	];

	const allFacilities = facilities
		? [{ name: '- Facility -', value: '' }].concat(
				facilities
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((row, index) => {
						return { name: row.name, value: row.id };
					})
		  )
		: [];

	const handleMDAChange = (value) => {
		setSearchValues({ ...searchValues, mda: value });
	};

	const handleFacilityChange = (value) => {
		setSearchValues({ ...searchValues, facility_id: value });
	};

	const handleEnrolleeTypeChange = (value) => {
		value === 0 &&
			setSearchValues({ ...searchValues, is_principal: 1, is_dependant: 0 });
		value === 1 &&
			setSearchValues({ ...searchValues, is_dependant: 1, is_principal: 0 });
	};

	const handleHasNINChange = (value) => {
		setSearchValues({ ...searchValues, has_nin: value });
	};

	return (
		<>
			<form id='form-search' onSubmit={onSubmit}>
				<Modal.Body>
					{exportReady ? (
						<div className='py-3 text-center'>File is ready!</div>
					) : (
						<div className='row'>
							<div className='form-group col-6'>
								<input
									type='text'
									value={searchValues?.q}
									onChange={handleSearchChange}
									className='form-control'
									name='q'
									placeholder='First, middle, last name, phone...'
								/>
							</div>

							<div className='form-group col-6'>
								<input
									type='text'
									value={searchValues?.insurance_id}
									onChange={handleSearchChange}
									className='form-control'
									name='insurance_id'
									placeholder='Insurance ID'
								/>
							</div>

							<div className='form-group col-6'>
								<input
									type='text'
									value={searchValues?.employee_number}
									onChange={handleSearchChange}
									className='form-control'
									name='employee_number'
									placeholder='Employee ID'
								/>
							</div>
							<div className='form-group col-6'>
								{/* <input
									type='text'
									value={searchValues?.dependant}
									onChange={handleSearchChange}
									className='form-control'
									name='dependant_name'
									placeholder='Dependant Name'
								/> */}
								<SelectSearch
									search
									filterOptions={fuzzySearch}
									options={enrolleeType}
									value={searchValues?.is_dependant}
									name='enrolleeType'
									placeholder='Enrollee Type?'
									onChange={handleEnrolleeTypeChange}
								/>
							</div>
							<div className='form-group col-md-6'>
								{/* <select
									className='form-control'
									name='facility_id'
									value={searchValues?.facility_id}
									onChange={handleSearchChange}
								>
									<option value=''> - Facility -</option>
									{facilities &&
										facilities.length > 0 &&
										facilities.map((row, index) => {
											return (
												<option key={index} value={row.id}>
													{row.name}
												</option>
											);
										})}
								</select> */}
								<SelectSearch
									search
									filterOptions={fuzzySearch}
									options={allFacilities}
									value={searchValues?.facility_id}
									name='facility'
									placeholder='Select Facility'
									onChange={handleFacilityChange}
								/>
							</div>

							<div className='form-group col-md-6'>
								<SelectSearch
									search
									filterOptions={fuzzySearch}
									options={allMDAs}
									value={searchValues?.mda}
									name='mda'
									onChange={handleMDAChange}
									placeholder='Select or search MDA'
								/>
								{/* <select className="form-control"
                            name="mda"
                            value={searchValues?.mda}
                            onChange={handleSearchChange}
                        >
                            <option value=""> - MDA -</option>
                            {mdas && mdas.length > 0 && mdas.map((row, index) => {
                                return (<option key={index} value={row}>{row}</option>)
                            })}
                        </select> */}
							</div>

							<div className='form-group col-md-6'>
								<select
									className='form-control'
									name='gender'
									value={searchValues?.gender}
									onChange={handleSearchChange}
									style={{
										color: '#74788d',
									}}
								>
									<option value=''>- Gender -</option>
									<option value='Female'>Female</option>
									<option value='Male'>Male</option>
								</select>
							</div>

							<div className='form-group col-md-6'>
								<select
									className='form-control'
									name='lga'
									value={searchValues?.lga}
									onChange={handleSearchChange}
									style={{
										color: '#74788d',
									}}
								>
									<option value=''> - LGA -</option>
									{lgas &&
										lgas.length > 0 &&
										lgas.map((row, index) => {
											return (
												<option key={row.code} value={row.name}>
													{row.name}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<select
									className='form-control '
									name='plan_id'
									value={searchValues?.plan_id}
									onChange={handleSearchChange}
									style={{
										color: '#74788d',
									}}
								>
									<option className='control-color' value=''>
										{' '}
										- Plan -
									</option>
									{packages &&
										packages.length > 0 &&
										packages
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((row) => {
												return (
													<option key={row.id} value={row.id}>
														{row.name}
													</option>
												);
											})}
								</select>
							</div>
							<div className='form-group col-md-6'>
								<SelectSearch
									search
									filterOptions={fuzzySearch}
									options={hasNIN}
									value={searchValues?.has_nin}
									name='has_nin'
									placeholder='Has NIN'
									onChange={handleHasNINChange}
								/>
							</div>

							<div className='col-12'>
								<div className='input-group input-group-picker'>
									<DatePicker
										className='form-control'
										autoComplete='off'
										name='start_date'
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										selectsStart
										startDate={startDate}
										endDate={endDate}
										placeholderText='Reg. start date'
										isClearable
										dateFormat='MMM d, yyyy'
									/>
									<DatePicker
										className='form-control'
										autoComplete='off'
										name='end_date'
										selected={endDate}
										onChange={(date) => setEndDate(date)}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										placeholderText='Reg. end date'
										isClearable
										dateFormat='MMM d, yyyy'
									/>
								</div>
							</div>
						</div>
					)}
				</Modal.Body>

				<Modal.Footer style={{ justifyContent: 'space-between' }}>
					{searchType === 1 ? (
						<>
							<input
								type='button'
								onClick={closeModal}
								className='btn btn-default'
								value='Close'
							/>
							<button
								className='btn btn-success btn-main'
								onClick={() => setType(1)}
								type='submit'
							>
								Submit Search
							</button>
						</>
					) : (
						<>
							<input
								type='button'
								onClick={resetExport}
								className='btn btn-default'
								value='Close'
							/>

							{exportReady ? (
								<>
									<ExcelFile
										filename='Enrollees'
										element={
											<button
												className='btn btn-success btn-download'
												type='button'
											>
												Download file
											</button>
										}
									>
										<ExcelSheet data={exportData} name='Enrollees'>
											<ExcelColumn label='Enrollee ID' value='insurance_id' />
											<ExcelColumn label='First Name' value='first_name' />
											<ExcelColumn label='Middle Name' value='middle_name' />
											<ExcelColumn label='Last Name' value='last_name' />
											<ExcelColumn label='Status' value='status' />
											<ExcelColumn label='Gender' value='gender' />
											<ExcelColumn label='Religion' value='religion' />
											<ExcelColumn label='Occupation' value='occupation' />
											<ExcelColumn
												label='Birth Date'
												value={(col) =>
													col.dob ? moment(col.dob).format('ll') : col.dob
												}
											/>
											<ExcelColumn
												label='Marital Status'
												value='marital_status'
											/>
											<ExcelColumn
												label='Educational Status'
												value='educational_status'
											/>
											<ExcelColumn
												label='Special Needs'
												value='special_needs'
											/>
											<ExcelColumn label='NIN' value='nin' />
											<ExcelColumn
												label='Facility'
												value={(col) =>
													col?.facilities.length > 0
														? `${col?.facilities[0]?.name}, ${col?.facilities[0]?.lga}`
														: ''
												}
											/>
											{
												<ExcelColumn
													label='Package'
													value={(col) =>
														col?.package
															? `${col?.package?.name}`
															: col?.benefactor_id !== null
															? 'Dependant'
															: 'None'
													}
												/>
											}

											<ExcelColumn
												label='Package'
												value={(col) =>
													col?.benefactor_id
														? 'Dependant'
														: col?.package
														? `${col?.package?.name}`
														: 'None'
												}
											/>

											<ExcelColumn label='Address' value='address' />
											<ExcelColumn label='LGA. of Residence' value='lga' />
											<ExcelColumn label='State' value='state' />
											<ExcelColumn label='Ward' value='ward' />
											<ExcelColumn label='Community' value='community' />
											<ExcelColumn label='Phone Number' value='phone' />
											<ExcelColumn label='Email Address' value='email' />

											<ExcelColumn
												label='Business Name'
												value='business_name'
											/>
											<ExcelColumn
												label='Office Address'
												value='office_address'
											/>
											<ExcelColumn label='Office LGA' value='office_lga' />

											<ExcelColumn label='Genotype' value='genotype' />
											<ExcelColumn label='Blood Group' value='blood_group' />
											<ExcelColumn label='Allergies' value='allergies' />
										</ExcelSheet>
									</ExcelFile>
								</>
							) : (
								<>
									<button
										className='btn btn-secondary btn-export'
										type='submit'
										onClick={() => setType(2)}
									>
										{exporting
											? `Exporting (${exportData.length})...`
											: 'Export to Excel'}
									</button>
								</>
							)}
						</>
					)}
				</Modal.Footer>
			</form>
		</>
	);
}
