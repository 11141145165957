import * as React from 'react';
import AppContext from '../../context';
import {
	packages,
	packageCreate,
	packageUpdate,
	packageDelete,
	packagesSubscriptions,
	benefitsList,
} from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate, Currency } from './../../components/Components';

const initValues = {
	type: '',
	benefits: [],
};

export default function Packages({ history }) {
	const queryClient = useQueryClient();

	const [queryName] = React.useState('facilities');
	const [formID] = React.useState('form-facilities');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const { toggleFormState } = useFormState(formID);

	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		notify,
		currentPath,
		formActionType,
		errorResponse,
		formatDate,
		checkObject,
		capitalize,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);
	const [benefitDefaultValues, setBenefitDefaultValues] = React.useState([]);
	const [benefits, setBenefits] = React.useState([]);
	const [planBenefits, setPlanBenefits] = React.useState([]);
	const [planBenefitModal, setPlanBenefitModal] = React.useState(false);
	const [benefitGroup, setBenefitGroup] = React.useState([]);

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	const handleCloseModal = () => {
		closeModal(false);
		setDefaultValues(initValues);
	};

	/* Benefits */
	useQuery('select-benefits', benefitsList, {
		onError: (error) => setBenefits([]),
		onSuccess: (data) => {
			if (data.length > 0) {
				const result = data.reduce(
					(h, dt) =>
						Object.assign(h, {
							[dt?.benefit_type?.name]: (
								h[dt?.benefit_type?.name] || []
							).concat({ value: dt.id, label: dt.name }),
						}),
					{}
				);
				//const result = data.map(dt => ({ value: dt.id, label: dt.name }));
				const bGroup = Object.entries(result).map((value, key) => value[0]);
				//console.log(bGroup, 'result..');
				setBenefits(result);
				setBenefitGroup(bGroup);
			}
		},
	});

	/* Page data */
	const { isLoading, isFetching, isError, data, error } = useQuery(
		[queryName, query],
		() => packages(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { data: subscriptionData } = useQuery(
		['package-subscriptions'],
		() => packagesSubscriptions(),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const { mutate: handleSubmitAdd } = useMutation(
		(values) => packageCreate(values),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Added', 'Data successfully added!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Edit data */
	const { mutate: handleSubmitEdit } = useMutation(
		({ id, values }) => packageUpdate({ id, values }),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Modified', 'Data successfully modified!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Delete data */
	const { mutate: handleSubmitDelete } = useMutation(
		(id) => packageDelete(id),
		{
			onSuccess: () => {
				notify('success', 'Data Deleted', 'Data successfully deleted!');
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				packages(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setStateData(dispatch, 'formActionType', 1);
		setModalTitle('New Package');
		setModalState(true);
	};

	const initEdit = (data) => {
		let selectedGroup = [];
		const benefits = data?.benefits?.length > 0 && data.benefits;
		const bdv =
			benefits.length > 0 &&
			benefits.map((dt) => ({
				value: dt.id,
				label: dt.name,
				type: dt?.benefit_type?.name,
			}));

		benefitGroup?.map((bGroup) => {
			const filterGroup = bdv && bdv.filter((dt) => dt.type === bGroup);
			data = { ...data, [`${bGroup}-benefit`]: filterGroup };
			//console.log(bGroup, filterGroup, data, 'filterGroup..');
		});

		setBenefitDefaultValues(bdv);
		setStateData(dispatch, 'formActionType', 2);
		setModalTitle('Edit Package');
		setDefaultValues(data);
		setModalState(true);
	};

	const initBenefits = (benefits, name) => {
		const groupData = benefits.reduce(
			(h, dt) =>
				Object.assign(h, {
					[dt.type]: (h[dt.type] || []).concat({ id: dt.id, name: dt.name }),
				}),
			{}
		);

		setPlanBenefits(groupData);
		setModalTitle(name);
		setPlanBenefitModal(true);
	};

	const onSubmit = (data) => {
		const bnt =
			checkObject(benefits) &&
			Object.entries(benefits).map((value, key) => value[0]);
		let dataBenefits = [];
		//console.log(data, bnt, 'ons data...');
		if (bnt) {
			bnt.map((dt) => {
				const key = `${dt}-benefit`;
				if (data[key]) dataBenefits = [...dataBenefits, ...data[key]];
			});
			dataBenefits = dataBenefits.map((dt) => dt.value);
		}

		toggleFormState(true, 'saving...');
		if (formActionType === 1) {
			handleSubmitAdd({ ...data, benefits: dataBenefits });
		} else {
			handleSubmitEdit({
				id: defaultValues.id,
				values: { ...data, benefits: dataBenefits },
			});
		}
	};

	const initDelete = (id) => {
		const conf = window.confirm('Are you sure?');
		if (!conf) return;
		handleSubmitDelete(id);
	};

	const checkValue = (arr, name) => {
		const found = arr.some((el) => el.name === name);

		if (found) {
			const newFound = arr.filter((el) => el.name === name);
			return newFound[0].subscriptions_count;
		}

		return found;
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col float-right'>
										<div className='text-sm-right'>
											<button
												type='button'
												onClick={initAdd}
												className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
											>
												<i className='mdi mdi-plus mr-1'></i> New Package
											</button>
										</div>
									</div>
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered  table-striped'>
											<thead>
												<tr>
													<th width='25%'>Name</th>
													<th width='10%'>Enrollees</th>
													<th width='10%'>Type</th>
													<th width='14%'>Amount</th>
													<th width='15%'>Family Amount</th>
													<th width='10%' className='text-center'>
														Status
													</th>
													<th width='15%' className='text-right'>
														Created At
													</th>
													<th width='1%' className='text-center'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>{row.name}</td>
															<td>
																<b>
																	{checkValue(
																		subscriptionData,
																		row.name
																	).toLocaleString()}
																</b>
															</td>
															<td>{row.type}</td>
															<td>
																<Currency value={row.amount} />
															</td>
															<td>
																{row?.family_amount && (
																	<Currency value={row?.family_amount} />
																)}
															</td>
															<td className='text-center'>
																<span className='badge badge-success font-size-12'>
																	Active
																</span>
															</td>
															<td className='text-right'>
																{formatDate(row.created_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					initBenefits(row.benefits, row.name)
																				}
																				className='dropdown-item'
																			>
																				<i className='fas fa-eye text-primary mr-1'></i>{' '}
																				View Benefits
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initEdit(row)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																				Edit
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDelete(row.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-trash-alt text-danger mr-1'></i>{' '}
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										<Paginate data={data} onPageChange={handlePageClick} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				backdrop='static'
				keyboard={false}
				size='lg'
				onHide={handleCloseModal}
				animation={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Form
					handleCloseModal={handleCloseModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					benefits={benefits}
					formID={formID}
					benefitDefaultValues={benefitDefaultValues}
					setBenefitDefaultValues={setBenefitDefaultValues}
				/>
			</Modal>

			<Modal
				show={planBenefitModal}
				onHide={() => setPlanBenefitModal(false)}
				animation={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{checkObject(planBenefits) && (
						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							{Object.entries(planBenefits).map((value, key) => {
								return (
									<React.Fragment key={value[0]}>
										<thead>
											<tr>
												{/* <th width='100%'>{capitalize(value[0])}</th> */}
											</tr>
										</thead>
										<tbody>
											{value[1] &&
												value[1].map((row, index) => {
													return (
														<tr key={row.id}>
															<td scope='row'>{row.name}</td>
														</tr>
													);
												})}
										</tbody>
									</React.Fragment>
								);
							})}
						</table>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
