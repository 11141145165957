import NotFound from './pages/not-found';
import Login from './pages/login';
import CreatePassword from './pages/create-password';
import ChangePassword from './pages/change-password';
import Dashboard from './pages/dashboard';
import Notifications from './pages/notifications';
import FacilityServices from './pages/facilities/services';
import FacilityTypes from './pages/facilities/types';
import Facilities from './pages/facilities';
// import FacilityClaims from './pages/facility-claims';
import AdminUsers from './pages/admin';
import Agents from './pages/agents';
import Claims from './pages/claims';
import Authorization from './pages/authorizations';
import Benefits from './pages/benefits';
import BenefitTypes from './pages/benefits/types';
import BenefitCategories from './pages/benefits/categories';
import Packages from './pages/packages';
import Occupations from './pages/occupations';
import Enrollment from './pages/enrollment';
import EnrolleeRegister from './pages/enrollment/register';
import RegisterDependant from './pages/enrollment/register/form-dependant';
import InactiveEnrollees from './pages/enrollment/inactive';
import NotEnrolledEnrollees from './pages/enrollment/not-enrolled';
import EnrolleeFacilityChange from './pages/enrollment/change-facility';
import Referrals from './pages/referrals';
import EnrolleeExport from './pages/enrollment/export-enrollees';
import EnrolleeDesign from './pages/enrollment/design';

import FacilityAssessmentGroup from './pages/facilities-assessment/group';
import FacilityAssessmentQuestions from './pages/facilities-assessment/questions';
import FacilityAssessments from './pages/facilities-assessment';

import Corporates from './pages/corporates';
// import CorporatesEnrollees from "./pages/corporates/corporateEnrollees";

const routes = [
	{
		id: 2,
		path: '/login',
		component: Login,
		auth: false,
		exact: true,
		route: true,
		pageTitle: 'Login',
	},
	{
		id: 21,
		path: '/create-password/:token',
		component: CreatePassword,
		auth: false,
		exact: false,
		route: true,
		pageTitle: 'Create Password',
	},
	{
		id: 221,
		path: '/change-password/',
		component: ChangePassword,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Change Password',
	},
	{
		id: 222,
		path: '/notifications',
		component: Notifications,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Notifications',
		menu: {
			status: true,
			title: 'Notifications',
			order: 2,
			main: true,
			icon: 'bx bx-bell',
		},
	},
	{
		id: 3,
		path: '/admin-users',
		component: AdminUsers,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Manage Admin Users',
		menu: {
			status: true,
			title: 'Admin User',
			order: 3,
			main: true,
			icon: 'bx bx-user-circle',
			exclude: 'health admin',
		},
	},
	{
		id: 3.01,
		menu: {
			status: true,
			title: 'Manage Claims',
			main: true,
			hasSub: true,
			order: 4,
			icon: 'bx bx-plus',
			exclude: 'health admin',
		},
	},
	{
		id: 3.011,
		path: '/authorizations',
		component: Authorization,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Authorizations',
		menu: {
			status: true,
			title: 'Authorizations',
			parent: 3.01,
			order: 1,
			icon: 'bx bx-key',
		},
	},
	{
		id: 3.012,
		path: '/referrals',
		component: Referrals,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Referrals',
		menu: {
			status: true,
			title: 'Referrals',
			parent: 3.01,
			order: 2,
			icon: 'bx bx-share',
		},
	},
	{
		id: 3.013,
		path: '/claims',
		component: Claims,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Claims',
		menu: {
			status: true,
			title: 'Claims',
			parent: 3.01,
			order: 3,
			icon: 'bx bx-plus-medical',
		},
	},
	/* ****** AGENTS ****** */
	{
		id: 3.1,
		menu: {
			status: true,
			title: 'Manage Agents',
			main: true,
			hasSub: true,
			order: 5,
			icon: 'bx bx-user-pin',
			exclude: 'health admin',
		},
	},
	{
		id: 3.2,
		path: '/agents',
		component: Agents,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Agents',
		menu: {
			status: true,
			title: 'Agents',
			parent: 3.1,
			order: 1,
			icon: 'bx bx-user-circle',
		},
	},
	/* ****** FACILITIES ****** */
	{
		id: 4,
		menu: {
			status: true,
			title: 'Facilities',
			main: true,
			hasSub: true,
			order: 6,
			icon: 'bx bx-building',
		},
	},
	{
		id: 41,
		path: '/facilities',
		component: Facilities,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Manage Facilities',
		menu: {
			status: true,
			title: 'Manage Facilities',
			parent: 4,
			order: 1,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 42,
		path: '/facility-services',
		component: FacilityServices,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Facility Services',
		menu: {
			status: true,
			title: 'Facility Services',
			parent: 4,
			order: 2,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 43,
		path: '/facility-types',
		component: FacilityTypes,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Facility Types',
		menu: {
			status: true,
			title: 'Facility Types',
			parent: 4,
			order: 3,
			icon: 'bx bx-cog',
			exclude: 'health admin',
		},
	},
	{
		id: 44,
		path: '/facility-assessment-group',
		component: FacilityAssessmentGroup,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Assessment Group',
		menu: {
			status: true,
			title: 'Assessment Group',
			parent: 4,
			order: 4,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 45,
		path: '/facility-assessment-questions',
		component: FacilityAssessmentQuestions,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Assessment Questions',
		menu: {
			status: true,
			title: 'Assessment Questions',
			parent: 4,
			order: 5,
			icon: 'bx bx-question-mark',
		},
	},
	{
		id: 46,
		path: '/facility-assessment',
		component: FacilityAssessments,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Facility Assessment',
		menu: {
			status: true,
			title: 'Facility Assessment',
			parent: 4,
			order: 6,
			icon: 'bx bx-cog',
		},
	},

	/* ****** /FACILITIES END ****** */

	{
		id: 5,
		menu: {
			status: true,
			title: 'Packages',
			main: true,
			hasSub: true,
			order: 7,
			icon: 'bx bx-user-circle',
		},
	},
	{
		id: 5.1,
		path: '/packages',
		component: Packages,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Manage Packages',
		menu: {
			status: true,
			title: 'Manage Packages',
			parent: 5,
			order: 1,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 5.2,
		path: '/benefits',
		component: Benefits,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Benefits',
		menu: {
			status: true,
			title: 'Benefits',
			parent: 5,
			order: 2,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 5.3,
		path: '/benefit-types',
		component: BenefitTypes,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Benefit Types',
		menu: {
			status: true,
			title: 'Benefit Types',
			parent: 5,
			order: 3,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 5.4,
		path: '/benefit-categories',
		component: BenefitCategories,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Benefit Categories',
		menu: {
			status: true,
			title: 'Benefit Categories',
			parent: 5,
			order: 4,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 61,
		path: '/occupations',
		component: Occupations,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Manage Occupations',
		menu: {
			status: true,
			title: 'Occupations',
			main: true,
			order: 8,
			icon: 'bx bx-briefcase',
		},
	},

	{
		id: 7,
		menu: {
			status: true,
			title: 'Enrollment',
			main: true,
			hasSub: true,
			order: 9,
			icon: 'bx bx-user-circle',
		},
	},
	{
		id: 8,
		path: '/enrollees',
		component: Enrollment,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Active Enrollees',
		menu: {
			status: true,
			title: 'Active Enrollees',
			parent: 7,
			order: 1,
			icon: 'bx bx-user',
		},
	},
	{
		id: 9.2,
		path: '/enrollee/registrants',
		component: NotEnrolledEnrollees,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Registrants',
		menu: {
			status: true,
			title: 'Registrants',
			parent: 7,
			order: 2,
			icon: 'bx bx-user-pin',
		},
	},
	{
		id: 9,
		path: '/enrollee/inactive-enrollees',
		component: InactiveEnrollees,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Inactive Enrollees',
		menu: {
			status: true,
			title: 'Inactive Enrollees',
			parent: 7,
			order: 3,
			icon: 'bx bx-user-x',
		},
	},
	{
		id: 9.3,
		path: '/enrollee/enrollees-facility-change',
		component: EnrolleeFacilityChange,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Facility Change',
		menu: {
			status: true,
			title: 'Facility Change',
			parent: 7,
			order: 4,
			icon: 'bx bx-move',
		},
	},
	{
		id: 9.4,
		path: '/enrollee/enrollees-export',
		component: EnrolleeExport,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Enrollees Export',
		menu: {
			status: true,
			title: 'Enrollees Export',
			parent: 7,
			order: 5,
			icon: 'bx bx-share-alt',
		},
	},
	{
		id: 9.1,
		path: '/enrollee/enrollees-design',
		component: EnrolleeDesign,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Enrollees Design',
		//menu: { status: true, title: 'Enrollees Design', parent: 7, order: 3, icon: 'bx bx-cog' }
	},
	{
		id: 81,
		path: '/enrollee/create',
		component: EnrolleeRegister,
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Create Enrollee',
	},
	{
		id: 82,
		path: '/enrollee/add-dependant',
		component: RegisterDependant,
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Add Enrollee Dependant',
	},
	{
		id: 83,
		path: '/corporates',
		component: Corporates,
		title: 'Corporates',
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Corporates',
		menu: {
			status: true,
			title: 'Corporates',
			order: 10,
			main: true,
			icon: 'bx bx-buildings',
		},
	},
	{
		id: 1,
		path: '/',
		component: Dashboard,
		title: 'Dashboard',
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Dashboard',
		menu: {
			status: true,
			title: 'Dashboard',
			order: 1,
			main: true,
			icon: 'bx bx-home-circle',
		},
	},
	{
		id: 404,
		path: '/*',
		component: NotFound,
		exact: false,
		route: true,
		pageTitle: 'Page Not Found!',
		menu: { status: false },
	},
];

export default routes;
