import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from './../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	userRoleData,
	formID,
	facilities,
}) {
	const { register, errors, handleSubmit, watch } = useForm({
		defaultValues,
	});

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='row'>
						<div className='form-group col-md-6'>
							<label>First Name</label>
							<input
								type='text'
								className='form-control'
								name='first_name'
								placeholder='First Name'
								defaultValue={defaultValues.first_name}
								ref={register({
									required: 'First name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='first_name' />
						</div>
						<div className='form-group col-md-6'>
							<label>Last Name</label>
							<input
								type='text'
								className='form-control'
								name='last_name'
								placeholder='Last Name'
								defaultValue={defaultValues.last_name}
								ref={register({
									required: 'Last name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='last_name' />
						</div>

						<div className='form-group col-md-6'>
							<label>Email Address</label>
							<input
								type='text'
								className='form-control'
								name='email'
								placeholder='Email Address'
								defaultValue={defaultValues.email}
								ref={register({
									required: 'Email address is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='email' />
						</div>

						<div className='form-group col-md-6'>
							<label>Phone Number</label>
							<input
								type='number'
								className='form-control'
								name='phone'
								placeholder='Phone Number'
								defaultValue={defaultValues.phone}
								ref={register({
									required: 'Phone number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='phone' />
						</div>

						<div className='form-group col'>
							<label>User Role</label>
							<select
								className='form-control'
								name='role_id'
								defaultValue={defaultValues.role_id}
								ref={register({
									required: 'Select user role!',
								})}
							>
								<option value=''> - Select -</option>
								{userRoleData &&
									userRoleData.length > 0 &&
									userRoleData.map((row, index) => {
										return (
											<option key={index} value={row.id}>
												{row.name}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='role_id' />
						</div>

						{(watch('role_id') === '9' || watch('role_id') === '10') && (
							<div className='form-group col'>
								<label>Facility</label>
								<select
									className='form-control'
									name='facility_id'
									defaultValue={defaultValues.facility_id}
									ref={register()}
								>
									<option value=''> - Select -</option>
									{facilities &&
										facilities.length > 0 &&
										facilities.map((row, index) => {
											return (
												<option key={index} value={row.id}>
													{row.name}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='facility_id' />
							</div>
						)}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
