import * as React from 'react';
import { useHistory, Link } from 'react-router-dom';
import AppContext from '../context';
import { login } from '../Services';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from '../components/Components';

export default function Login() {
	const {
		dispatch,
		baseUrl,
		setUserData,
		validateEmail,
		disableForms,
		errorResponse,
		notify,
	} = React.useContext(AppContext);
	const { register, errors, handleSubmit, getValues } = useForm({
		criteriaMode: 'all',
	});

	let history = useHistory();

	// const { mutate: handleSubmitEnrollement } = useMutation(
	// 	(values) => login(values),
	// 	{
	// 		onSuccess: (data) => {
	// 			const { token, user, expires_in } = data;
	// 			setUserData(dispatch, token, user, expires_in);
	// 			history.replace('/');
	// 		},
	// 		//onError: (error) => errorResponse({ error, dispatch }),
	// 	}
	// );

	React.useEffect(() => {
		disableForms('form-login', false);
		return () => {
			disableForms('form-login', false);
		};
	}, [disableForms]);

	const onSubmit = (data) => {
		disableForms('form-login', true);
		login(data)
			.then((response) => {
				const { token, user, expires_in } = response;
				setUserData(dispatch, token, user, expires_in);
				history.replace('/');
			})
			.catch((error) => {
				disableForms('form-login', false);
				errorResponse({ error, exclude: [401] });

				notify(
					'danger',
					'Invalid Credentials!',
					'Invalid login credentials. Please login again!'
				);
				//console.log(err?.response?.status, 'err...');
			});
	};

	return (
		<div className='login-page'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-md-8 col-lg-6 col-xl-5'>
						<div className='card overflow-hidden'>
							<div className='card-body'>
								<div className='p-2'>
									<div className='text-center mb-4'>
										<img
											src={`${baseUrl}assets/images/logo-edo.svg`}
											className='mr-2'
											alt='Logo'
										/>
										<img
											src={`${baseUrl}assets/images/logo.svg`}
											width='60'
											alt='Logo'
										/>
									</div>
									<div>
										<h4 className='mb-1'>Welcome!</h4>
										<p className='text-muted'>Login to your account to start</p>
									</div>
									<form
										className='form-horizontal'
										id='form-login'
										onSubmit={handleSubmit(onSubmit)}
									>
										<div className='form-group'>
											<label htmlFor='email'>Email address</label>
											<input
												type='text'
												className='form-control'
												name='email'
												ref={register({
													validate: () => validateEmail(getValues('email')),
												})}
												placeholder='Email address'
											/>
											<ErrorMsg errors={errors} name='email' />
										</div>

										<div className='form-group'>
											<label htmlFor='userpassword'>Password</label>
											<input
												type='password'
												className='form-control'
												name='password'
												ref={register({
													required: 'Password is required!',
												})}
												placeholder='Enter Password'
											/>
											<ErrorMsg errors={errors} name='password' />
										</div>

										<div className='row mb-5'>
											<div className='col-6'>
												<div className='custom-control custom-checkbox'>
													<input
														type='checkbox'
														className='custom-control-input'
														id='customControlInline'
													/>
													<label
														className='custom-control-label'
														htmlFor='customControlInline'
													>
														Remember me
													</label>
												</div>
											</div>
											<div className='col-6 text-right'>
												<Link href='/forgot-password'>Forgot password?</Link>
											</div>
										</div>

										<button
											className='btn btn-success btn-block waves-effect waves-light bg-theme-success btn-main'
											type='submit'
										>
											Log In
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
