/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../hooks/useModal';
import { Paginate } from '../../components/Components';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import '../../utils/select-search.css';

import ExportExcel from './exportExcel';

import { allReferrals, facilitiesList } from '../../Services';

export default function AllReferrals({ history, setQuery, query }) {
	const queryClient = useQueryClient();

	const [currentPage, setCurrentPage] = useState('all-referrals');
	const [queryName] = useState('all-referrals');
	const [modalType, setModalType] = useState('details');

	const initFilterData = {
		start_date: '',
		end_date: '',
		submission_date_start: '',
		submission_date_end: '',
		approval_date_start: '',
		approval_date_end: '',
		facility: '',
		destination: '',
		insurance_id: '',
	};

	const [filterData, setFilterData] = useState(initFilterData);

	const [showApprove, hideApprove] = useState(false);
	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		useContext(AppContext);

	const [queryExport, setQueryExport] = useState({});
	const [exportData, setExportData] = useState([]);
	const [exporting, setExporting] = useState(false);
	const [exportReady, setExportReady] = useState(false);

	const [referralId, setReferralId] = useState(null);

	const handleCloseModal = () => {
		closeModal();
		setReferralId(null);
		history.replace({
			search: '',
		});
	};

	/* Export data */
	useQuery(
		['export-referrals', queryExport],
		() => allReferrals(setQueryString(queryExport)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQueryExport({ ...queryExport, page: queryExport.page + 1 || 1 });
				} else {
					setExporting(false);
					setExportReady(true);
					setQueryExport({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { data, isLoading, isError } = useQuery(
		[queryName, query],
		() => allReferrals(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { data: facilities } = useQuery(['listFacilities'], () =>
		facilitiesList()
	);

	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['all-referrals', nextPage], () =>
				allReferrals(setQueryString(query))
			);
		}
	}, [data, query, queryClient]);

	const facilityOptions =
		facilities &&
		facilities?.map((facility) => ({
			name: facility.name,
			value: facility.name,
		}));

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		setModalType('details');
		setReferralId(row.uuid);
		showModal();
	};

	const initFilter = () => {
		setModalType('filter');
		showModal();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setQuery(filterData);
		setQueryExport(filterData);
		closeModal();
	};

	if (isLoading) {
		return <div>loading...</div>;
	}

	return (
		<>
			{!isLoading && isError && <div>error: {isError.message}...</div>}

			<div className='card'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-md-3'>
							<button className='btn btn-success w-100' onClick={initFilter}>
								Filter
							</button>
						</div>

						<div className='col-md-3 mt-2 mt-md-0'>
							<button
								className='btn btn-secondary w-100'
								onClick={() => {
									setFilterData(initFilterData);
									setQuery({ page: 1 });
									setQueryExport({});
									setExportReady(false);
									setExportData([]);
								}}
							>
								Reset Filters
							</button>
						</div>

						<div className='col-md-3 mt-2 mt-md-0'>
							{exportReady ? (
								<ExportExcel data={exportData} />
							) : (
								<button
									type='button'
									onClick={() => setExporting(true)}
									className='btn btn-secondary btn-rounded waves-effect waves-light w-100'
								>
									<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
									{exporting ? 'Exporting...' : 'Export to Excel'}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			{data && data?.data?.length > 0 ? (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-dark'>All Referrals</h3>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='30%'>Enrollee</th>
												<th width='10%'>Code</th>
												<th width='25%'>Service Requested</th>
												<th width='10%'>Facility</th>
												<th width='14%'>Status</th>
												<th width='10%'>Date</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{data?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.code}</td>
														<td>
															{row?.comment.length > 20
																? `${row?.comment.substring(0, 20)}...`
																: row?.comment}
														</td>
														<td>
															{row?.facility?.name.indexOf('(') > -1
																? row?.facility?.name.substring(
																		0,
																		row?.facility?.name.indexOf('(')
																  )
																: row?.facility?.name}
														</td>
														<td
															className={`${
																row?.status === 'approved'
																	? 'text-success'
																	: row?.status === 'pending'
																	? 'text-warning'
																	: 'text-error'
															}`}
														>
															{row?.status}
														</td>
														<td className='text-right'>
															{formatDate(row?.created_at, 'll')}
															<span className='text-muted ml-2'>
																{formatDate(row?.created_at, 'hh:mm A')}
															</span>
														</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={data} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='px-4'>
					<h4>No Referrals</h4>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Details
						handleCloseModal={handleCloseModal}
						id={referralId}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						history={history}
					/>
				</Modal>
			)}

			{modalType === 'filter' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Filter Referrals</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form>
							<div className='row p-3'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Facility (From)</label>

										<SelectSearch
											search
											filterOptions={fuzzySearch}
											options={facilityOptions}
											value={filterData.facility}
											name='facility'
											placeholder='Select Facility'
											onChange={(value) =>
												setFilterData({
													...filterData,
													facility: value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Facility (To)</label>

										<SelectSearch
											search
											filterOptions={fuzzySearch}
											options={facilityOptions}
											value={filterData.destination}
											name='facility'
											placeholder='Select Facility'
											onChange={(value) =>
												setFilterData({
													...filterData,
													destination: value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Start Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.start_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													start_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>End Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.end_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													end_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Submission Date (Start)</label>
										<input
											type='date'
											className='form-control'
											value={filterData.submission_date_start}
											onChange={(e) =>
												setFilterData({
													...filterData,
													submission_date_start: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Submission Date (End)</label>
										<input
											type='date'
											className='form-control'
											value={filterData.submission_date_end}
											onChange={(e) =>
												setFilterData({
													...filterData,
													submission_date_end: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Approval Date (Start)</label>
										<input
											type='date'
											className='form-control'
											value={filterData.approval_date_start}
											onChange={(e) =>
												setFilterData({
													...filterData,
													approval_date_start: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Approval Date (End)</label>
										<input
											type='date'
											className='form-control'
											value={filterData.approval_date_end}
											onChange={(e) =>
												setFilterData({
													...filterData,
													approval_date_end: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Insurance ID</label>
										<input
											type='text'
											className='form-control'
											value={filterData.insurance_id}
											placeholder='Enter Insurance ID'
											onChange={(e) =>
												setFilterData({
													...filterData,
													insurance_id: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'></div>

								<div className='col-md-6'>
									<div className='btn btn-dark w-50' onClick={handleSubmit}>
										{isLoading ? 'Filtering...' : 'Filter'}
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
