import * as React from 'react';
import AppContext from '../../context';
import { occupations, occupationCreate, occupationUpdate, occupationDelete } from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import { Paginate } from './../../components/Components';
import useFormState from './../../hooks/useFormState';

const initValues = {}

export default function Occupations({ history }) {
    const queryClient = useQueryClient();
    const [queryName] = React.useState('occupations');
    const [formID] = React.useState('form-occupation');

    const { dispatch, setQueryString, useQueryString, setStateData, currentPath, notify,
        formActionType, errorResponse, formatDate } = React.useContext(AppContext);
    let queryString = useQueryString();
    const [defaultValues, setDefaultValues] = React.useState(initValues);
    /* Modal hook */
    const { modalState, setModalState, modalTitle, setModalTitle, closeModal } = useModal();
    const { toggleFormState } = useFormState(formID);

    const [query, setQuery] = React.useState({
        page: (queryString.get("page") && !isNaN(queryString.get("page"))
            ? Number(queryString.get("page")) : 1)
    });

    const handleCloseModal = () => {
        closeModal();
        setDefaultValues(initValues);
    }

    /* Page data */
    const { isLoading, isFetching, isError, data, error } = useQuery([queryName, query],
        () => occupations(setQueryString(query)), {
        keepPreviousData: true,
        staleTime: 5000,
        onError: (error) => errorResponse({ error, history, dispatch }),
    });

    /* Add data */
    const { mutate: handleSubmitAdd } = useMutation(values => occupationCreate(values), {
        onSuccess: () => {
            handleCloseModal();
            notify('success', 'Data Added', 'Data successfully added!');
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => {
            queryClient.refetchQueries([queryName, query]);
            toggleFormState(false);
        },
    });

    /* Edit data */
    const { mutate: handleSubmitEdit } = useMutation(({ id, values }) => occupationUpdate({ id, values }), {
        onSuccess: () => {
            handleCloseModal();
            notify('success', 'Data Modified', 'Data successfully modified!');
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => {
            queryClient.refetchQueries([queryName, query]);
            toggleFormState(false);
        }
    });

    const { mutate: handleSubmitDelete } = useMutation(id => occupationDelete(id), {
        onSuccess: () => {
            notify('success', 'Data Deleted', 'Data successfully deleted!');
        },
        onMutate: (id) => {
            queryClient.cancelQueries([queryName, query]);
            const previousData = queryClient.getQueryData([queryName, query]);
            const updateValue = previousData?.data;

            const removeDeleted = updateValue.filter(dt => dt.id !== id);
            const newData = { ...previousData, data: removeDeleted };
            return queryClient.setQueryData([queryName, query], newData);
        },
        onError: (error) => errorResponse({ error, dispatch }),
        onSettled: () => queryClient.refetchQueries([queryName, query])
    });

    /* Requery on data update */
    React.useEffect(() => {
        if (data?.next_page_url) {
            let nextPage = { ...query, page: query.page + 1 };
            queryClient.prefetchQuery([queryName, nextPage],
                () => occupations(setQueryString(nextPage)))
        }
    }, [data, query, queryClient])


    /* handle paginate data */
    const handlePageClick = ({ selected }) => {
        const page = selected + 1;
        let nQ = { ...query, page };
        setQuery(nQ);
        history.push(`${currentPath}${setQueryString(nQ)}`);
    }

    const initAdd = () => {
        setStateData(dispatch, 'formActionType', 1);
        setModalTitle('New Occupation');
        setModalState(true);
    }

    const initEdit = (data) => {
        setStateData(dispatch, 'formActionType', 2);
        setModalTitle('Edit Occupation');
        setDefaultValues(data)
        setModalState(true);
    }

    const onSubmit = (data) => {
        toggleFormState(true, 'saving...');
        if (formActionType === 1) {
            handleSubmitAdd(data);
        } else {
            handleSubmitEdit({id: defaultValues?.id, values: data});
        }
    }

    const initDelete = (id) => {
        const conf = window.confirm('Are you sure?');
        if (!conf) return;
        handleSubmitDelete(id);
    }

    return (<>
        {isLoading && <div>loading...</div>}
        {!isLoading && error && <div>error: {error.message}...</div>}

        {data?.data && (<div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col float-right">
                                <div className="text-sm-right">
                                    <button type="button" onClick={initAdd}
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                                        <i className="mdi mdi-plus mr-1"></i> New Occupation
                                        </button>
                                </div>
                            </div>
                        </div>

                        {!isLoading && data?.data && data?.data?.length === 0 && <div className="no-data-box">No data found!</div>}

                        {data?.data?.length > 0 && (<div className="table-responsive">
                            <table className="table table-centered table-nowrap table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-right">Created At</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data?.map((row) => {
                                        return (<tr key={row.id}>
                                            <td>{row.name}</td>
                                            <td>{row.type}</td>
                                            <td className="text-center">
                                                <span className="badge badge-success font-size-12">Active</span>
                                            </td>
                                            <td className="text-right">{formatDate(row.created_at)}</td>
                                            <td width="1%" className="text-center">
                                                <div className="dropdown">
                                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><a
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => initEdit(row)}
                                                            className="dropdown-item"><i className="fas fa-pencil-alt text-success mr-1"></i> Edit</a></li>
                                                        <li><a style={{ cursor: 'pointer' }}
                                                            onClick={() => initDelete(row.id)}
                                                            className="dropdown-item">
                                                            <i className="fas fa-trash-alt text-danger mr-1"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            <Paginate data={data} onPageChange={handlePageClick} />
                        </div>)}



                    </div>
                </div>
            </div>
        </div>)}

        <Modal
            show={modalState}
            onHide={handleCloseModal}
            animation={false}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Form
                handleCloseModal={handleCloseModal}
                defaultValues={defaultValues}
                formID={formID}
                onSubmit={onSubmit}
            />
        </Modal>
    </>);
}