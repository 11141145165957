import * as React from 'react';
import AppContext from '../../../context';
import { allFacilityChangeHistory } from '../../../Services';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../../hooks/useModal';
import useFormState from '../../..//hooks/useFormState';
import { Paginate } from '../../../components/Components';
import moment from 'moment';

export default function AllFacilityChanges({ history, setQuery, query }) {
	const [currentPage, setCurrentPage] = React.useState('all-history');
	const [queryName] = React.useState('all-history');
	const [modalType, setModalType] = React.useState('details');
	const [showApprove, hideApprove] = React.useState(false);
	const [searchData, setSearchData] = React.useState({
		// start_date: moment(new Date()).format('yyyy-MM-DD'),
		// end_date: moment(new Date()).format('yyyy-MM-DD'),
		start_date: '',
		end_date: '',
		enrollee_name: '',
	});

	const queryClient = useQueryClient();

	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		React.useContext(AppContext);

	const [changeDetails, setChangeDetails] = React.useState(null);

	const handleCloseModal = () => {
		closeModal();
		setChangeDetails(null);
		setSearchData({
			start_date: '',
			end_date: '',
			enrollee_name: '',
		});
	};

	const {
		data: historyData,
		isLoading: historyLoading,
		isError: historyError,
		refetch: Refetch,
	} = useQuery(
		[queryName, query],
		() => allFacilityChangeHistory(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	React.useEffect(() => {
		if (historyData?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['all-history', nextPage], () =>
				allFacilityChangeHistory(setQueryString(nextPage))
			);
		}
	}, [historyData, query, queryClient]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		// console.log(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		setModalType('details');
		setChangeDetails(row);
		showModal();
	};

	const initFilter = () => {
		setModalType('search');
		showModal();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setQuery(searchData);
		closeModal();
	};

	return (
		<>
			{historyLoading && <div>loading...</div>}
			{!historyLoading && historyError && (
				<div>error: {historyError.message}...</div>
			)}

			<div className='card'>
				<div className='card-body'>
					<div className='row mx-2'>
						<button className='btn btn-success w-25' onClick={initFilter}>
							Search
						</button>
						<button
							className='btn btn-secondary w-25 mx-3'
							onClick={() => setQuery({ page: 1 })}
						>
							Clear Search
						</button>
					</div>
				</div>
			</div>

			{historyData && historyData?.data?.length > 0 && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-dark'>All Changes</h3>
								</div>

								{/* <h4 className='fw-bold text-primary'>
									Number of Changes: {historyData.total}
								</h4> */}

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='30%'>Enrollee</th>
												<th width='30%'>ID</th>
												<th width='15%'>From</th>
												<th width='15%'>To</th>
												<th width='15%'>Comment</th>
												<th width='14%'>Status</th>
												<th width='10%'>Date</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{historyData?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.enrollee?.insurance_id}</td>
														<td>{row?.from?.name}</td>
														<td>{row?.to?.name}</td>
														<td>{row?.comment}</td>
														<td
															className={`${
																row?.status === 'approved'
																	? 'text-success'
																	: row?.status === 'pending'
																	? 'text-warning'
																	: 'text-danger'
															}`}
														>
															{row?.status}
														</td>
														<td>{formatDate(row?.created_at)}</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={historyData} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Facility Change Request Details</Modal.Title>
					</Modal.Header>

					<Details
						handleCloseModal={handleCloseModal}
						changeDetails={changeDetails}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						history={history}
					/>
				</Modal>
			)}

			{modalType === 'search' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Search Facility Change History</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form>
							<div className='row p-3'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Enrollee Name</label>
										<input
											type='text'
											className='form-control'
											value={searchData.enrollee_name}
											onChange={(e) =>
												setSearchData({
													...searchData,
													enrollee_name: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<label>Preset Duration</label>
									<div className='row'>
										<div className='col-md-6 text-center'>
											<div className='form-group'>
												<button
													className='btn btn-primary w-100'
													onClick={(e) => {
														e.preventDefault();
														setSearchData({
															...searchData,
															start_date: moment()
																.subtract('months', 1)
																.format('yyyy-MM-DD'),
															end_date: moment(new Date()).format('yyyy-MM-DD'),
														});
													}}
												>
													One Month Ago
												</button>
											</div>
										</div>
										<div className='col-md-6 text-center'>
											<div className='form-group'>
												<button
													className='btn btn-primary w-100'
													onClick={(e) => {
														e.preventDefault();
														setSearchData({
															...searchData,
															start_date: moment()
																.subtract('weeks', 1)
																.format('yyyy-MM-DD'),
															end_date: moment(new Date()).format('yyyy-MM-DD'),
														});
													}}
												>
													One Week Ago
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Start Date</label>
										<input
											type='date'
											className='form-control'
											value={searchData.start_date}
											onChange={(e) =>
												setSearchData({
													...searchData,
													start_date: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>End Date</label>
										<input
											type='date'
											className='form-control'
											value={searchData.end_date}
											onChange={(e) =>
												setSearchData({
													...searchData,
													end_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='btn btn-dark w-50' onClick={handleSubmit}>
										{historyLoading ? 'Searching...' : 'Search'}
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
