import * as React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { store } from 'react-notifications-component';

const commonActions = {
	setPageTitle: (dispatch, title) =>
		dispatch({ type: 'SET_PAGE_TITLE', title }),
	togglePageTitle: (dispatch, showPageTitle = true) =>
		dispatch({ type: 'SHOW_PAGE_TITLE', showPageTitle }),
	setCurrentPath: (dispatch, pathname) =>
		dispatch({ type: 'SET_PATH_NAME', pathname }),
	setNA: (string) =>
		string ? string : <span className='text-muted'>N/A</span>,
	setQueryString: (query) => {
		query =
			Object.keys(query).length === 0 && query.constructor === Object
				? ''
				: '?' +
				  Object.keys(query)
						.map((key) => key + '=' + query[key])
						.join('&');
		return query;
	},
	useQueryString: () => {
		return new URLSearchParams(useLocation().search);
	},
	setStateData: (dispatch, name, value) => {
		dispatch({ type: 'SET_STATE', name, value });
	},
	emailRegex: (email) => {
		let re = /\S+@\S+\.\S+/;
		return re.test(email);
	},
	formatDate: (date, dateFormat = 'll') => moment(date).format(dateFormat),
	formatDateBr: (date) => (
		<>
			{moment(date).format('ll')}
			<br />
			{moment(date).format('LT')}
		</>
	),
	//formatDateBr: (date) => `${moment(date).format('ll')}<br/>${moment(date).format('LT')}`,
	notify: (type, title, message) => {
		store.addNotification({
			title,
			message: <div dangerouslySetInnerHTML={{ __html: message }} />,
			type,
			insert: 'top',
			container: 'top-right',
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			dismiss: {
				showIcon: true,
				duration: 4000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
	},

	pendnotify: (type, title, message) => {
		store.addNotification({
			title,
			message: <div dangerouslySetInnerHTML={{ __html: message }} />,
			type,
			insert: 'top',
			container: 'top-right',
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			dismiss: {
				showIcon: true,
				duration: 4000000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
	},
	checkObject: (obj) => obj && Object.entries(obj).length > 0,
	checkArray: (obj) => obj && Array.isArray(obj) && obj.length > 0,
	capitalize: (str) => {
		if (str) {
			str = String(str.trim());
			return str
				.split(' ')
				.map((word) => {
					let string = word?.toLowerCase();
					string = string[0]?.toUpperCase() + string.slice(1);
					return string;
				})
				.join(' ');
		}

		return str;
	},
	dataURItoBlob: (dataURI) => {
		let byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	},
	randomString: (length = 8) => {
		let result = '';
		const characters = 'abcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},
};

export default commonActions;
