import { Modal } from 'react-bootstrap';

export default function Form({
	searchValues,
	lgas,
	closeModal,
	onSearchSubmit,
	setSearchValues,
}) {
	const handleSearchChange = (e) => {
		const { name, value } = e.target;
		setSearchValues({ ...searchValues, [name]: value });
	};

	return (
		<>
			<Modal.Body>
				<div className='row'>
					<div className='form-group col-6'>
						<input
							type='text'
							value={searchValues?.name}
							onChange={handleSearchChange}
							className='form-control'
							name='name'
							placeholder='Name'
						/>
					</div>

					<div className='form-group col-md-6'>
						<select
							className='form-control'
							name='type'
							value={searchValues?.type}
							onChange={handleSearchChange}
							style={{
								color: '#74788d',
							}}
						>
							<option value=''>- Type -</option>
							<option value='primary'>Primary</option>
							<option value='secondary'>Secondary</option>
							<option value='primary and secondary'>
								Primary and Secondary
							</option>
						</select>
					</div>

					<div className='form-group col-12'>
						<input
							type='text'
							value={searchValues?.about}
							onChange={handleSearchChange}
							className='form-control'
							name='about'
							placeholder='About'
						/>
					</div>

					<div className='form-group col-6'>
						<input
							className='form-control'
							// type='date'
							type='text'
							onFocus={(e) => (e.target.type = 'date')}
							value={searchValues?.start_date}
							onChange={handleSearchChange}
							name='start_date'
							placeholder='Start Date'
						/>
					</div>

					<div className='form-group col-6'>
						<input
							className='form-control'
							// type='date'
							type='text'
							onFocus={(e) => (e.target.type = 'date')}
							value={searchValues?.end_date}
							onChange={handleSearchChange}
							name='end_date'
							placeholder='End Date'
						/>
					</div>

					<div className='form-group col-md-6'>
						<select
							className='form-control'
							name='lga'
							value={searchValues?.lga}
							onChange={handleSearchChange}
							style={{
								color: '#74788d',
							}}
						>
							<option value=''> - LGA -</option>
							{lgas &&
								lgas.length > 0 &&
								lgas.map((row, index) => {
									return (
										<option key={row.code} value={row.name}>
											{row.name}
										</option>
									);
								})}
						</select>
					</div>

					<div className='form-group col-md-6'>
						<select
							className='form-control'
							name='status'
							value={searchValues?.status}
							onChange={handleSearchChange}
							style={{
								color: '#74788d',
							}}
						>
							<option value=''>- Status -</option>
							<option value='active'>Active</option>
							<option value='inactive'>Inactive</option>
						</select>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer style={{ justifyContent: 'space-between' }}>
				<input
					type='button'
					onClick={closeModal}
					className='btn btn-default'
					value='Close'
				/>
				<button
					className='btn btn-success btn-main'
					onClick={() => onSearchSubmit(searchValues)}
					type='submit'
				>
					Submit Search
				</button>
			</Modal.Footer>
		</>
	);
}
