import * as React from 'react';
import AppContext from '../../context';
import {
	benefits,
	benefitCreate,
	benefitUpdate,
	benefitDelete,
	benefitTypesList,
	benefitCatsList,
	benefitsSchemesList,
} from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate, Currency } from './../../components/Components';

const initValues = {};

// Check if any Properties of object is empty
const checkProperties = (obj) => {
	for (let key in obj) {
		if (obj[key] !== null && obj[key] !== '') return false;
	}
	return true;
};

export default function Benefits({ history }) {
	const queryClient = useQueryClient();
	const [queryName] = React.useState('benefits');
	const [formID] = React.useState('form-benefit');

	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		currentPath,
		notify,
		formActionType,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);
	/* Modal hook */
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const {
		modalState: searchModalState,
		setModalState: setSearchModalState,
		modalTitle: searchModalTitle,
		setModalTitle: setSearchModalTitle,
		closeModal: closeSearchModal,
	} = useModal();

	const { toggleFormState } = useFormState(formID);
	const [benefitTypes, setBenefitTypes] = React.useState([]);
	const [benefitCats, setBenefitCats] = React.useState([]);
	const [benefitSchemes, setBenefitSchemes] = React.useState([]);

	const [searchData, setSearchData] = React.useState({
		name: '',
		price: '',
		insurance_scheme: '',
		benefit_type: '',
		benefit_category: '',
	});
	const [clearSearch, setClearSearch] = React.useState(false);

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	const handleCloseModal = () => {
		closeModal();
		closeSearchModal();
		setDefaultValues(initValues);
	};

	/* Benefits types */
	useQuery('select-benefit-types', benefitTypesList, {
		onError: (error) => setBenefitTypes([]),
		onSuccess: (data) => setBenefitTypes(data),
	});

	/* Benefits categories */
	useQuery('select-benefit-categories', benefitCatsList, {
		onError: (error) => setBenefitCats([]),
		onSuccess: (data) => setBenefitCats(data),
	});

	/* Benefits schemes */
	useQuery('select-benefit-schemes', benefitsSchemesList, {
		onError: (error) => setBenefitSchemes([]),
		onSuccess: (data) => setBenefitSchemes(data),
	});

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => benefits(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const { mutate: handleSubmitAdd } = useMutation(
		(values) => benefitCreate(values),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Added', 'Data successfully added!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Edit data */
	const { mutate: handleSubmitEdit } = useMutation(
		({ id, values }) => benefitUpdate({ id, values }),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Modified', 'Data successfully modified!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	const { mutate: handleSubmitDelete } = useMutation(
		(id) => benefitDelete(id),
		{
			onSuccess: () => {
				notify('success', 'Data Deleted', 'Data successfully deleted!');
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				benefits(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient, queryName, setQueryString]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setStateData(dispatch, 'formActionType', 1);
		setModalTitle('New Benefit');
		setModalState(true);
	};

	const initEdit = (data) => {
		setStateData(dispatch, 'formActionType', 2);
		setModalTitle('Edit Benefit');
		setDefaultValues(data);
		setModalState(true);
	};

	const onSubmit = (data) => {
		toggleFormState(true, 'saving...');

		if (formActionType === 1) {
			handleSubmitAdd(data);
		} else {
			handleSubmitEdit({ id: defaultValues?.id, values: data });
		}
	};

	const initDelete = (id) => {
		const conf = window.confirm('Are you sure?');
		if (!conf) return;
		handleSubmitDelete(id);
	};

	const initSearch = () => {
		setSearchModalTitle('Search Benefits');
		setSearchModalState(true);
	};

	const onSearchSubmit = (e) => {
		e.preventDefault();

		let newSearchData = {};

		for (let key in searchData) {
			if (searchData.hasOwnProperty(key)) {
				if (searchData[key] !== '') {
					newSearchData[key] = searchData[key];
				}
			}
		}

		setQuery(newSearchData);
		setClearSearch(true);
		closeSearchModal();
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2 mx-2 justify-content-between align-items-center'>
									<div className='col-12 col-md-6'>
										<div className='btn-group mr-2' role='group'>
											<button
												type='button'
												onClick={() => initSearch(1)}
												className='btn btn-success btn-rounded waves-effect waves-light'
											>
												<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
												Search
											</button>

											{!checkProperties(searchData) && (
												<button
													type='button'
													onClick={() => {
														setQuery({ page: 1 });
														setSearchData({
															name: '',
															price: '',
															insurance_scheme: '',
															benefit_type: '',
															benefit_category: '',
														});
													}}
													className='btn btn-success btn-rounded waves-effect waves-light'
												>
													Clear{' '}
													<i className='bx bx-x search-icon font-size-16 align-middle'></i>
												</button>
											)}
										</div>
									</div>

									<div className='col-12 col-md-6 mt-2 mt-md-0'>
										<button
											type='button'
											onClick={initAdd}
											className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2 float-md-right'
										>
											<i className='mdi mdi-plus mr-1'></i> New Benefit
										</button>
									</div>
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered table-striped'>
											<thead>
												<tr>
													<th width='45%'>Benefit</th>
													<th width='14%'>Benefit Class</th>
													<th width='10%'>Scheme</th>
													<th width='10%'>Price</th>
													<th width='5%' className='text-center'>
														Status
													</th>
													<th width='15%' className='text-right'>
														Created At
													</th>
													<th width='1%' className='text-center'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>{row.name}</td>
															<td>{row?.benefit_type?.name}</td>
															<td>{row?.insurance_scheme ?? '--'}</td>
															<td>
																<Currency value={row.price} />
															</td>
															<td className='text-center'>
																<span className='badge badge-success font-size-12'>
																	Active
																</span>
															</td>
															<td className='text-right'>
																{formatDate(row.created_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initEdit(row)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																				Edit
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDelete(row.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-trash-alt text-danger mr-1'></i>{' '}
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										<Paginate data={data} onPageChange={handlePageClick} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal show={modalState} onHide={handleCloseModal} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Form
					handleCloseModal={handleCloseModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					benefitTypes={benefitTypes}
					benefitCats={benefitCats}
					benefitSchemes={benefitSchemes}
					formID={formID}
				/>
			</Modal>

			<Modal
				show={searchModalState}
				onHide={handleCloseModal}
				animation={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{searchModalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div>
						<div className='row'>
							<div className='form-group col-12'>
								<input
									type='text'
									className='form-control '
									name='name'
									placeholder='Benefit Name'
									value={searchData.name}
									onChange={(e) =>
										setSearchData({
											...searchData,
											name: e.target.value,
										})
									}
								/>
							</div>
						</div>

						<div className='row'>
							<div className='form-group col-6'>
								<input
									type='text'
									className='form-control'
									name='number'
									placeholder='Price'
									value={searchData.price}
									onChange={(e) =>
										setSearchData({
											...searchData,
											price: e.target.value,
										})
									}
								/>
							</div>

							<div className='form-group col-6'>
								<select
									className='form-control'
									name='insurance_scheme'
									onChange={(e) =>
										setSearchData({
											...searchData,
											insurance_scheme: e.target.value,
										})
									}
								>
									<option value=''> - Insurance Scheme -</option>
									{benefitSchemes &&
										benefitSchemes.length > 0 &&
										benefitSchemes.map((row) => {
											return (
												<option key={row.id} value={row.insurance_scheme}>
													{row.insurance_scheme}
												</option>
											);
										})}
								</select>
							</div>
						</div>

						<div className='row'>
							<div className='form-group col-6'>
								<select
									className='form-control'
									name='benefit_type'
									onChange={(e) =>
										setSearchData({
											...searchData,
											benefit_type: e.target.value,
										})
									}
								>
									<option value=''> - Benefit Type -</option>
									{benefitTypes &&
										benefitTypes.length > 0 &&
										benefitTypes.map((row, index) => {
											return (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											);
										})}
								</select>
							</div>
							<div className='form-group col-6'>
								<select
									className='form-control'
									name='benefit_category'
									onChange={(e) =>
										setSearchData({
											...searchData,
											benefit_category: e.target.value,
										})
									}
								>
									<option value=''> - Category -</option>
									{benefitCats &&
										benefitCats.length > 0 &&
										benefitCats.map((row, index) => {
											return (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											);
										})}
								</select>
							</div>
						</div>

						<button
							className='btn btn-success btn-main float-right'
							onClick={onSearchSubmit}
							type='submit'
						>
							Submit Search
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
