/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import AppContext from '../../context';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../hooks/useModal';

import { Paginate } from '../../components/Components';
import moment from 'moment';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import '../../utils/select-search.css';

import ExportExcel from './ExportExcel';

import { claimData, claims } from '../../Services';

const initValues = {
	mda: '',
};

const initFilterData = {
	start_date: '',
	end_date: '',
	facility_name: '',
	benefit_category: '',
	cin: '',
};

export default function AllClaims({
	history,
	setQuery,
	query,
	facilities,
	benefitCats,
}) {
	const tableRef = React.useRef(null);

	const [currentPage, setCurrentPage] = React.useState('all-claims');
	const queryClient = useQueryClient();
	const [queryName] = React.useState('all-claims');
	const [modalType, setModalType] = React.useState('details');
	const [filterData, setFilterData] = React.useState(initFilterData);

	const [exportData, setExportData] = React.useState([]);
	const [exporting, setExporting] = React.useState(false);
	const [exportReady, setExportReady] = React.useState(false);

	const [showApprove, hideApprove] = React.useState(false);
	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		React.useContext(AppContext);

	const [claimDetails, setClaimDetails] = React.useState(null);

	const handleCloseModal = () => {
		closeModal();
		setClaimDetails(initValues);
	};

	const {
		data: claimsData,
		isLoading: claimLoading,
		isError: claimError,
	} = useQuery([queryName, query], () => claims(setQueryString(query)), {
		keepPreviousData: true,
		staleTime: 5000,
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	/* Export data */
	useQuery(['export-claims', query], () => claims(setQueryString(query)), {
		retry: 0,
		manual: true,
		enabled: exporting,
		refetchInterval: 1000,
		onSuccess: ({ data, next_page_url }) => {
			console.log(data, next_page_url, 'export data...');
			data.length > 0 && setExportData([...exportData, ...data]);

			if (next_page_url) {
				setQuery({
					...query,
					page: query.page + 1 || 1,
				});
			} else {
				setExporting(false);
				setExportReady(true);
				setQuery({});
			}
		},
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	React.useEffect(() => {
		if (claimsData?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['all-claims', nextPage], () =>
				claims(setQueryString(nextPage))
			);
		}
	}, [claimData, query, queryClient]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { isLoading: detailsLoading } = useQuery(
		['claim-details', claimDetails?.id],
		() => claimData(claimDetails?.id),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(claimDetails?.id),
			onSuccess: (data) => setClaimDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const facilityOptions = facilities?.map((facility) => ({
		name: facility.name,
		value: facility.name,
	}));

	const benefitOptions = benefitCats?.map((benefit) => ({
		name: benefit.name,
		value: benefit.name,
	}));

	const initDetails = (row) => {
		setModalType('details');
		setClaimDetails(row);
		showModal();
	};

	const initFilter = () => {
		setModalType('filter');
		showModal();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setQuery(filterData);
		closeModal();
	};

	return (
		<>
			{claimLoading && <div>loading...</div>}
			{!claimLoading && claimError && <div>error: {claimError.message}...</div>}

			<div className='card'>
				<div className='card-body'>
					<div className='row mx-2'>
						<button
							className='btn col-12 col-md-3 btn-success mb-2 mb-md-0 mr-md-3'
							onClick={initFilter}
						>
							Filter
						</button>

						<button
							className='btn col-12 col-md-3 btn-secondary md:mx-3'
							onClick={() => {
								setQuery({ page: 1 });
								setFilterData(initFilterData);
							}}
						>
							Reset Filters
						</button>
					</div>
				</div>
			</div>

			{claimsData && claimsData?.data?.length > 0 && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row justify-content-between'>
									<div className='card-title'>
										<h3 className='text-dark pl-3'>All Claims</h3>
									</div>

									{exportReady ? (
										<ExportExcel data={exportData} />
									) : (
										<button
											type='button'
											onClick={() => setExporting(true)}
											className='btn btn-primary mb-2 mr-2'
										>
											<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
											{exporting ? 'Exporting...' : 'Export to Excel'}
										</button>
									)}
								</div>

								<div className='table-responsive'>
									<table
										className='table table-centered table-striped table-nowrap'
										ref={tableRef}
									>
										<thead>
											<tr>
												<th width='20%'>Enrollee</th>
												<th width='10%'>CIN</th>
												<th width='39%'>Facility</th>
												<th width='10%'>Amount</th>
												<th className='text-right' width='10%'>
													Submitted On
												</th>
												<th width='10%'>Auth Code</th>
												<th width='10%'>Referral Code</th>

												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>

										<tbody>
											{claimsData?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.enrollee?.insurance_id}</td>
														<td>
															{row?.facility?.name.indexOf('(') > -1
																? row?.facility?.name.substring(
																		0,
																		row?.facility?.name.indexOf('(')
																  )
																: row?.facility?.name}
														</td>
														<td>
															<b>NGN</b> {row?.total_amount?.toLocaleString()}
														</td>
														<td className='text-right'>
															{formatDate(row.date_of_submission)}
														</td>
														<td className='text-center'>
															{row.authorization_no || '-'}
														</td>
														<td className='text-center'>
															{row.referral_code || '-'}
														</td>

														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={claimsData} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Claim Details</Modal.Title>
					</Modal.Header>

					<Details
						handleCloseModal={handleCloseModal}
						claimDetails={claimDetails}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						loading={detailsLoading}
					/>
				</Modal>
			)}

			{modalType === 'filter' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Filter Claims</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form>
							<div className='row p-3'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Start Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.start_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													start_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>End Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.end_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													end_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Facility Name</label>

										{/* <select
											className='form-control'
											value={filterData.facility_name}
											onChange={(e) =>
												setFilterData({
													...filterData,
													facility_name: e.target.value,
												})
											}
										>
											<option value=''>Select Facility</option>
											{facilities.map((facility) => (
												<option key={facility.id} value={facility.name}>
													{facility.name}
												</option>
											))}
										</select> */}
										<SelectSearch
											search
											filterOptions={fuzzySearch}
											options={facilityOptions}
											value={filterData.facility_name}
											name='facility'
											placeholder='Select Facility'
											onChange={(value) =>
												setFilterData({
													...filterData,
													facility_name: value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Benefit Category</label>

										{/* <select
											className='form-control'
											value={filterData.benefit_category}
											onChange={(e) =>
												setFilterData({
													...filterData,
													benefit_category: e.target.value,
												})
											}
										>
											<option value=''>Select Benefit</option>
											{benefits.map((benefit) => (
												<option key={benefit.id} value={benefit.name}>
													{benefit.name}
												</option>
											))}
										</select> */}
										<SelectSearch
											search
											filterOptions={fuzzySearch}
											options={benefitOptions}
											value={filterData.benefit_category}
											name='benefit'
											placeholder='Select Benefit'
											onChange={(value) =>
												setFilterData({
													...filterData,
													benefit_category: value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>CIN</label>
										<input
											type='text'
											className='form-control'
											value={filterData.cin}
											onChange={(e) =>
												setFilterData({
													...filterData,
													cin: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-3 align-self-end'>
									<div className='form-group'>
										<button
											className='btn btn-success w-100'
											onClick={(e) => {
												e.preventDefault();
												setFilterData({
													...filterData,
													start_date: moment()
														.subtract('months', 1)
														.format('yyyy-MM-DD'),
													end_date: moment(new Date()).format('yyyy-MM-DD'),
												});
											}}
										>
											One Month Ago
										</button>
									</div>
								</div>

								<div className='col-md-3 align-self-end'>
									<div className='form-group'>
										<button
											className='btn btn-success w-100'
											onClick={(e) => {
												e.preventDefault();
												setFilterData({
													...filterData,
													start_date: moment()
														.subtract('weeks', 1)
														.format('yyyy-MM-DD'),
													end_date: moment(new Date()).format('yyyy-MM-DD'),
												});
											}}
										>
											One Week Ago
										</button>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='btn btn-dark w-100' onClick={handleSubmit}>
										{claimLoading ? 'Filtering...' : 'Filter'}
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
