import axios from 'axios';
import AppContext from './context';

//axios.defaults.baseURL = 'http://localhost:8882/api/v1/';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
//axios.defaults.headers.common.accept = 'application/json';
//axios.defaults.headers.post['Content-Type'] = 'application/json';
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//'Content-Type': 'application/x-www-form-urlencoded',

axios.interceptors.request.use(
	async (config) => {
		const token =
			localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const axiosJson = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	timeout: process.env.REACT_APP_TIMEOUT,
});

export async function login(values) {
	const { data } = await axios.post(`auth/login`, values);
	return data;
}

/* FACILITY SERVICE */
export const services = async (query) => {
	const { data } = await axios.get(`services${query}`);
	return data;
};

export const serviceCreate = async (newdata) => {
	const { data } = await axios.post(`services/create`, newdata);
	return data;
};

export const serviceUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`services/${id}/update`, values);
	return data;
};

export const serviceDelete = async (id) => {
	const response = await axios.delete(`services/${id}/delete`);
	return response;
};

/* FACILITIES */
export const facilities = async (query) => {
	const { data } = await axios.get(`facilities${query}`);
	return data;
};

export const facilitiesList = async () => {
	const { data } = await axios.get(`facilities/list`);
	return data;
};

export const facilityCreate = async (newdata) => {
	const { data } = await axios.post(`facilities/create`, newdata);
	return data;
};

export const facilityUpdate = async ({ facilityId, values }) => {
	const { data } = await axios.put(`facilities/${facilityId}/update`, values);
	return data;
};

export const facilityDelete = async (facilityId) => {
	const response = await axios.delete(`facilities/${facilityId}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT GROUP */
export const faGroup = async (query) => {
	const { data } = await axios.get(`question-groups${query}`);
	return data;
};

export const faGroupList = async () => {
	const { data } = await axios.get(`question-groups/list`);
	return data;
};

export const faGroupCreate = async (newdata) => {
	const { data } = await axios.post(`question-groups/create`, newdata);
	return data;
};

export const faGroupUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`question-groups/${id}/update`, values);
	return data;
};

export const faGroupDelete = async (id) => {
	const response = await axios.delete(`question-groups/${id}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT Q&A */
export const faQuestions = async (query) => {
	const { data } = await axios.get(`questions${query}`);
	return data;
};

export const faQuestionsList = async () => {
	const { data } = await axios.get(`questions/list`);
	return data;
};

export const faQuestionCreate = async (newdata) => {
	const { data } = await axios.post(`questions/create`, newdata);
	return data;
};

export const faQuestionUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`questions/${id}/update`, values);
	return data;
};

export const faQuestionDelete = async (id) => {
	const response = await axios.delete(`questions/${id}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT ANSWERS */
export const faAnswerSubmit = async ({ id, values }) => {
	const { data } = await axios.post(`facilities/${id}/answers`, values);
	return data;
};

/* ******** / ********* */

/* FACILITY CLAIMS */

export const facilityClaims = async (id, query) => {
	const { data } = await axios.get(`/facilities/${id}/claims/${query}`);
	return data;
};

/* FACILITY SERVICE */
export const facilityTypes = async (query) => {
	const { data } = await axios.get(`facility-types${query}`);
	return data;
};

export const facilityTypesList = async () => {
	const { data } = await axios.get(`facility-types/list`);
	return data;
};

export const facilityTypeCreate = async (newdata) => {
	const { data } = await axios.post(`facility-types/create`, newdata);
	return data;
};

export const facilityTypeUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`facility-types/${id}/update`, values);
	return data;
};

export const facilityTypeDelete = async (id) => {
	const response = await axios.delete(`facility-types/${id}/delete`);
	return response;
};

/* FACILITY CONTACTS */
export const facilityContacts = async (facilityId, page, query = {}) => {
	const response = await axios.get(
		`facilities/${facilityId}/contacts?page=${page}${query}`
	);
	return response;
};

export const facilityContactView = async (facilityId, contactId) => {
	const response = await axios.get(
		`facilities/${facilityId}/contacts/${contactId}/view`
	);
	return response;
};

export const facilityContactCreate = async (facilityId, data) => {
	const response = await axios.post(
		`facilities/${facilityId}/contacts/create`,
		data
	);
	return response;
};

export const facilityContactUpdate = async (facilityId, contactId, data) => {
	const response = await axios.put(
		`facilities/${facilityId}/contacts/${contactId}/update`,
		data
	);
	return response;
};

export const facilityContactDelete = async (facilityId, contactId) => {
	const response = await axios.delete(
		`facilities/${facilityId}/contacts/${contactId}/delete`
	);
	return response;
};

/* BENEFITS */
export const benefits = async (query) => {
	const { data } = await axios.get(`benefits${query}`);
	return data;
};

export const benefitsList = async () => {
	const { data } = await axios.get(`benefits/list`);
	return data;
};

export const benefitsSchemesList = async () => {
	const { data } = await axios.get(`benefits/schemes`);
	return data;
};

export const benefitCreate = async (values) => {
	const { data } = await axios.post(`benefits/create`, values);
	return data;
};

export const benefitUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`benefits/${id}/update`, values);
	return data;
};

export const benefitDelete = async (id) => {
	const response = await axios.delete(`benefits/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* BENEFIT TYPES */
export const benefitTypes = async (query) => {
	const { data } = await axios.get(`benefit-types${query}`);
	return data;
};

export const benefitTypesList = async () => {
	const { data } = await axios.get(`benefit-types/list`);
	return data;
};

export const benefitTypeCreate = async (values) => {
	const { data } = await axios.post(`benefit-types/create`, values);
	return data;
};

export const benefitTypeUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`benefit-types/${id}/update`, values);
	return data;
};

export const benefitTypeDelete = async (id) => {
	const response = await axios.delete(`benefit-types/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* BENEFIT CATEGORIES */
export const benefitCats = async (query) => {
	const { data } = await axios.get(`benefit-categories${query}`);
	return data;
};

export const benefitCatsList = async () => {
	const { data } = await axios.get(`benefit-categories/list`);
	return data;
};

export const benefitCatCreate = async (values) => {
	const { data } = await axios.post(`benefit-categories/create`, values);
	return data;
};

export const benefitCatUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`benefit-categories/${id}/update`, values);
	return data;
};

export const benefitCatDelete = async (id) => {
	const response = await axios.delete(`benefit-categories/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* PACKAGES */
export const packages = async (query) => {
	const { data } = await axios.get(`packages${query}`);
	return data;
};

export const packagesList = async () => {
	const { data } = await axios.get(`packages/list`);

	return data;
};

export const packagesListShallow = async () => {
	const { data } = await axios.get(`packages/shallow-list`);

	return data;
};

export const packagesSubscriptions = async () => {
	const { data } = await axios.get(`packages/subscriptions`);
	return data;
};

export const packageCreate = async (values) => {
	const { data } = await axios.post(`packages/create`, values);
	return data;
};

export const packageUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`packages/${id}/update`, values);
	return data;
};

export const packageDelete = async (id) => {
	const response = await axios.delete(`packages/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* ADMIN USERS */
export const adminUserRoles = async () => {
	const { data } = await axios.get(`users/roles`);
	return data;
};

export const adminUsers = async (query) => {
	const { data } = await axios.get(`users${query}`);
	return data;
};

export const adminUserCreate = async (values) => {
	const { data } = await axios.post(`users/create`, values);
	return data;
};

export const adminUserUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`users/${id}/update`, values);
	return data;
};

export const adminUserDelete = async (id) => {
	const response = await axios.delete(`users/${id}/delete`);
	return response;
};

export const adminVerifyToken = async (token) => {
	const { data } = await axios.get(`auth/verify/token?token=${token}`);
	return data;
};

export const adminSetPassword = async (values) => {
	const { data } = await axios.post(`auth/password`, values);
	return data;
};

export const adminChangePassword = async (values) => {
	const { data } = await axios.post(`auth/change-password`, values);
	return data;
};

/* NOTIFICATIONS */
export const notificationsCount = async () => {
	const { data } = await axios.get(`notifications/counts`);
	return data;
};

export const notifications = async (query) => {
	const { data } = await axios.get(`notifications${query}`);
	return data;
};

export const updateNotification = async (id, values) => {
	const { data } = await axios.post(`notifications/${id}`, values);
	return data;
};

/* AGENTS */
export const agents = async (query) => {
	const { data } = await axios.get(`agents${query}`);
	return data;
};

export const agentCreate = async (values) => {
	const { data } = await axios.post(`agents/create`, values);
	return data;
};

export const agentUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`agents/${id}/update`, values);
	return data;
};

export const agentDelete = async (id) => {
	const response = await axios.delete(`agents/${id}/delete`);
	return response;
};

/*Authorizations */
export const authorizations = async (query) => {
	const { data } = await axios.get(`auth-codes/${query}`);
	return data;
};

export const authorizationData = async (id) => {
	const { data } = await axios.get(`auth-codes/${id}/view`);
	return data;
};

export const approvedAuthorizations = async (query) => {
	const { data } = await axios.get(`auth-codes/approved/${query}`);
	return data;
};

export const pendingAuthorizations = async (query) => {
	const { data } = await axios.get(`auth-codes/pending/${query}`);
	return data;
};

export const rejectedAuthorizations = async (query) => {
	const { data } = await axios.get(`auth-codes/rejected/${query}`);
	return data;
};

// export const approveAuthorizationsOld = async (id) => {
// 	const { data } = await axios.post(`auth-codes/generate`, id);
// 	return data;
// };

// export const declineAuthorizationsOld = async (id) => {
// 	const { data } = await axios.post(`auth-codes/${id}/decline`);
// 	return data;
// };

export const approveAuthorizations = async (values) => {
	const { data } = await axios.post(`auth-codes/generate`, values);
	return data;
};

export const declineAuthorizations = async (values) => {
	const { data } = await axios.post(`auth-codes/decline`, values);
	return data;
};

export const changeAutChargeStatus = async (id, values) => {
	const { data } = await axios.post(`auth-codes/${id}/charge-lines`, values);
	return data;
};

// export const AuthCodeData = async (id) => {
// 	const { data } = await axios.get(`auth-codes/${id}/view`);
// 	return data;
// };

export const fetchAuthCodeComments = async (id) => {
	const { data } = await axios.get(`auth-codes/${id}/comments`);
	return data;
};

export const createAuthCodeComments = async (id, comments) => {
	const { data } = await axios.post(`auth-codes/${id}/comments`, comments);
	return data;
};

export const deleteAuthCodeComment = async (id, commentId) => {
	const { data } = await axios.delete(`auth-codes/${id}/comments/${commentId}`);
	return data;
};

/* CLAIMS */
export const claims = async (query) => {
	const { data } = await axios.get(`claims${query}`);
	return data;
};

export const totalClaims = async () => {
	const { data } = await axios.get(`claims/totals`);
	return data;
};

export const claimsAmount = async (query) => {
	const { data } = await axios.get(`claims/amount${query}`);
	return data;
};

export const pendingClaims = async (query) => {
	const { data } = await axios.get(`claims/pending${query}`);
	return data;
};

export const acceptedClaims = async (query) => {
	const { data } = await axios.get(`claims/accepted/${query}`);
	return data;
};

export const rejectedClaims = async (query) => {
	const { data } = await axios.get(`claims/rejected/${query}`);
	return data;
};

export const claimData = async (id) => {
	const { data } = await axios.get(`claims/${id}/view`);
	return data;
};

export const approveClaims = async (id) => {
	const { data } = await axios.post(`claims/${id}/approve`);
	return data;
};

export const declineClaims = async (id) => {
	const { data } = await axios.post(`claims/${id}/reject`);
	return data;
};

/* REFERRALS */
export const allReferrals = async (query) => {
	const { data } = await axios.get(`referrals/${query}`);
	return data;
};

export const approvedReferrals = async (query) => {
	const { data } = await axios.get(`referrals/approved/${query}`);
	return data;
};

export const pendingReferrals = async (query) => {
	const { data } = await axios.get(`referrals/pending/${query}`);
	return data;
};

export const rejectedReferrals = async (query) => {
	const { data } = await axios.get(`referrals/rejected/${query}`);
	return data;
};

// export const getReferral = async (id) => {
// 	const { data } = await axios.get(`referrals/${id}/view`);
// 	return data;
// };

export const referralData = async (id) => {
	const { data } = await axios.get(`referrals/${id}/view`);
	return data;
};

export const approveReferral = async (values) => {
	const { data } = await axios.post(`referrals/approve`, values);
	return data;
};

export const declineReferral = async (values) => {
	const { data } = await axios.post(`referrals/decline`, values);
	return data;
};

export const fetchReferralComments = async (id) => {
	const { data } = await axios.get(`referrals/${id}/comments`);
	return data;
};

export const createReferralComments = async (id, values) => {
	const { data } = await axios.post(`referrals/${id}/comments`, values);
	return data;
};

export const deleteReferralComment = async (id, commentId) => {
	const { data } = await axios.post(`referrals/${id}/comments/${commentId}`);
	return data;
};

/* COMMON COLLECTIONS */
export const selectLgas = async () => {
	const { data } = await axios.get(`lgas`);
	return data;
};

/* ENROLLMENT */
export const enrollees = async (query) => {
	const { data } = await axios.get(`enrollees${query}`);
	return data;
};

export const inactiveEnrollees = async (query) => {
	const { data } = await axios.get(`enrollees/registered${query}`);
	return data;
};

export const notEnrolledEnrollees = async (query) => {
	const { data } = await axios.get(`enrollees/not-enrolled${query}`);
	return data;
};

export const enrolleeCreate = async (values) => {
	const { data } = await axios.post(`enrollees/create`, values);
	return data;
};

export const enrolleeUpdate = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/update`, values);
	return data;
};

export const enrolleeDelete = async (id) => {
	const { data } = await axios.delete(`enrollees/${id}/delete`);
	return data;
};

export const enrolleeActivate = async (id) => {
	const { data } = await axios.get(`enrollees/${id}/activate`);
	return data;
};

export const enrolleeDeactivate = async (id) => {
	const { data } = await axios.get(`enrollees/${id}/deactivate`);
	return data;
};

export const enrolleeAddPlan = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/add-plan`, values);
	return data;
};

export const enrolleeUpgradePlan = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/upgrade-plan`, values);
	return data;
};

export const enrolleeInvoice = async ({ id, transaction_id }) => {
	const { data } = await axios.get(
		`enrollees/${id}/invoice?transaction_id=${transaction_id}`
	);
	return data;
};

export const enrolleePay = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/pay-invoice`, values);
	return data;
};

export const enrolleePayUpgrade = async ({ id, values }) => {
	const { data } = await axios.post(
		`enrollees/${id}/pay-upgrade-invoice`,
		values
	);

	return data;
};

export const enrolleeData = async (id) => {
	const { data } = await axios.get(`enrollees/${id}/view`);
	return data;
};

export const enrolleeAddDependant = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/add-dependant`, values);
	return data;
};

/* ENROLLEE FACILITY CHANGE */
export const initiateFacilityChange = async (values) => {
	const { data } = await axios.post(`enrollees/facilities/changes`, values);
	return data;
};

export const facilityChangeHistoryCount = async () => {
	const { data } = await axios.get(`enrollees/facilities/changes/totals`);
	return data;
};

export const allFacilityChangeHistory = async (query) => {
	const { data } = await axios.get(
		`enrollees/facilities/changes/history/${query}`
	);
	return data;
};

export const pendingFacilityChangeHistory = async (query) => {
	const { data } = await axios.get(
		`enrollees/facilities/changes/pending/${query}`
	);
	return data;
};

export const approvedFacilityChangeHistory = async (query) => {
	const { data } = await axios.get(
		`enrollees/facilities/changes/approved/${query}`
	);
	return data;
};

export const rejectedFacilityChangeHistory = async (query) => {
	const { data } = await axios.get(
		`enrollees/facilities/changes/declined/${query}`
	);
	return data;
};

export const fetchFacilityChangeComments = async (id) => {
	const { data } = await axios.get(
		`enrollees/facilities/changes/${id}/comments`
	);
	return data;
};

export const createFacilityChangeComments = async (id, values) => {
	const { data } = await axios.post(
		`enrollees/facilities/changes/${id}/comments`,
		values
	);
	return data;
};

export const approveFacilityChange = async (values) => {
	const { data } = await axios.post(
		`enrollees/facilities/changes/approve`,
		values
	);
	return data;
};

export const declineFacilityChange = async (values) => {
	const { data } = await axios.post(
		`enrollees/facilities/changes/decline`,
		values
	);
	return data;
};

/* ENROLLEE EXPORT */
export const enrolleeExport = async (query) => {
	const { data } = await axios.get(`enrollees/index-deep${query}`);
	return data;
};

export const activeEnrolleeExport = async (query) => {
	const { data } = await axios.get(`enrollees/queued-export${query}`);
	return data;
};

export const inActiveEnrolleeExport = async (query) => {
	const { data } = await axios.get(`enrollees/queued-export-inactive${query}`);
	return data;
};

export const notEnrolledEnrolleeExport = async (query) => {
	const { data } = await axios.get(
		`enrollees/queued-export-not-enrolled${query}`
	);
	console.log(data);
	return data;
};

/* OCCUPATION */
export const occupations = async (query) => {
	const { data } = await axios.get(`occupations${query}`);
	return data;
};

export const occupationsList = async () => {
	const { data } = await axios.get(`occupations/list`);
	return data;
};

export const occupationCreate = async (values) => {
	const { data } = await axios.post(`occupations/create`, values);
	return data;
};

export const occupationUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`occupations/${id}/update`, values);
	return data;
};

export const occupationDelete = async (id) => {
	const response = await axios.delete(`occupations/${id}/delete`);
	return response;
};

/* Reporting */
export const enrolmentSummary = async () => {
	const { data } = await axios.get(`dashboard/weekly-enrollment`);
	return data;
};

export const totals = async (query) => {
	const { data } = await axios.get(`dashboard/totals${query}`);
	return data;
};

export const topLgas = async () => {
	const { data } = await axios.get(`dashboard/lgas`);
	return data;
};

export const topFacilities = async () => {
	const { data } = await axios.get(`dashboard/facilities`);
	return data;
};

export const topPackages = async (query) => {
	const { data } = await axios.get(`dashboard/packages${query}`);
	return data;
};

export const topRevenue = async (query) => {
	const { data } = await axios.get(`dashboard/revenue${query}`);
	return data;
};

export const mdasList = async () => {
	const { data } = await axios.get(`mdas`);
	return data;
};

/* corporates */
export const corporates = async (query) => {
	const { data } = await axios.get(`corporates${query}`);
	return data;
};

export const corporatesList = async () => {
	const { data } = await axios.get(`corporates/list`);
	return data;
};

// export const corporatesEnrollees = async (id) => {
// 	const { data } = await axios.get(`corporates/${id}/enrollees`);
// 	return data;
// };

export const corporatesEnrollees = async (id, query) => {
	const { data } = await axios.get(`corporates/${id}/enrollees${query}`);
	return data;
};

export const corporatesCreate = async (values) => {
	const { data } = await axios.post(`corporates/create`, values);
	return data;
};

export const corporatesUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`corporates/${id}/update`, values);
	return data;
};

export const corporatesDelete = async (id) => {
	const response = await axios.delete(`corporates/${id}/delete`);
	return response;
};

export const corporatesActivate = async (id) => {
	const response = await axios.post(`corporates/${id}/activate`);
	return response;
};

export const corporatesDeactivate = async (id) => {
	const response = await axios.post(`corporates/${id}/deactivate`);
	return response;
};

export const addCorporatePayment = async ({ id, values }) => {
	const { data } = await axios.post(`corporates/${id}/payment`, values);
	return data;
};
