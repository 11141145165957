import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';
import AppContext from './context';
import { initialState, AppReducer } from './context/reducer';
import {
	errorResponseActions,
	formActions,
	authActions,
	commonActions,
} from './context/actions';
import AppRoute from './components/AppRoute';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
// import { ReactQueryDevtools } from "react-query/devtools";
import Master from './layout/Master';
import './App.css';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			refetchAllOnWindowFocus: false,
			refetchOnWindowFocus: false,
		},
	},
});

const queryConfig = {
	/* retry: 0,
  refetchAllOnWindowFocus: false, */
	shared: {
		suspense: true,
	},
	queries: {
		refetchOnWindowFocus: false,
	},
};

function App() {
	const [state, dispatch] = React.useReducer(AppReducer, initialState);
	//const [isEdit, setToast] = useToast();

	const bootstrapAsync = async () => {
		let userData, userToken, userTokenExp;
		userData =
			JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_DATA)) || null;
		userToken = localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
		userTokenExp =
			localStorage.getItem(process.env.REACT_APP_USER_TOKEN_EXP) || null;
		dispatch({ type: 'APP_READY', userToken, userData, userTokenExp });
	};

	React.useEffect(() => {
		//commonActions.notify('success', 'title', 'message');
		bootstrapAsync();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<AppContext.Provider
				value={{
					dispatch,
					...state,
					...authActions,
					...errorResponseActions,
					...formActions,
					...commonActions,
					userData:
						JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_DATA)) ||
						null,
				}}
			>
				{state.appIsReady ? (
					<Master>
						<Switch>
							{routes
								.filter((dt) => dt?.route)
								.map(({ exact, path, component, auth, pageTitle }) => (
									<AppRoute
										key={path}
										path={path}
										exact={exact}
										component={component}
										auth={auth}
										pageTitle={pageTitle}
									/>
								))}
						</Switch>
					</Master>
				) : (
					<></>
				)}
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</AppContext.Provider>
		</QueryClientProvider>
	);
}

export default App;
