import React, { useState } from 'react';
import moment from 'moment';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
	activeEnrolleeExport,
	inActiveEnrolleeExport,
	notEnrolledEnrolleeExport,
} from '../../../Services';
import AppContext from '../../../context';

export default function EnrolleeExport() {
	const { notify, dispatch, useQueryString, errorResponse } =
		React.useContext(AppContext);

	let queryString = useQueryString();
	let queryClient = useQueryClient();

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [enrolleeType, setEnrolleeType] = useState('active');

	// Export Active Enrollees
	const { mutate: queueExportActive } = useMutation(
		(values) => activeEnrolleeExport(values),
		{
			onSuccess: (res) => {
				notify('success', 'Export Queued Successfully', res.msg);
			},
			onError: (error) => errorResponse({ error, dispatch }),
		}
	);

	// Export Inactive Enrollees
	const { mutate: queueExportInactive } = useMutation(
		(values) => inActiveEnrolleeExport(values),
		{
			onSuccess: (res) => {
				notify('success', 'Export Queued Successfully', res.msg);
			},
			onError: (error) => errorResponse({ error, dispatch }),
		}
	);

	// Export Not Enrolled Enrollees
	const { mutate: queueExportNotEnrolled } = useMutation(
		(values) => notEnrolledEnrolleeExport(values),
		{
			onSuccess: (res) => {
				notify('success', 'Export Queued Successfully', res.msg);
			},
			onError: (error) => errorResponse({ error, dispatch }),
		}
	);

	const handleExport = (e) => {
		e.preventDefault();

		// Active Export
		if (enrolleeType === 'active')
			queueExportActive(
				`${
					startDate && endDate
						? `?start_date=${startDate}&end_date=${endDate}`
						: ''
				}`
			);

		// Inactive Export
		if (enrolleeType === 'inactive')
			queueExportInactive(
				`${
					startDate && endDate
						? `?start_date=${startDate}&end_date=${endDate}`
						: ''
				}`
			);

		// Inactive Export
		if (enrolleeType === 'not-enrolled')
			queueExportNotEnrolled(
				`${
					startDate && endDate
						? `?start_date=${startDate}&end_date=${endDate}`
						: ''
				}`
			);
	};

	return (
		<div className='container'>
			<div className='card'>
				{/* <div className='card-header'>
					<div className='card-title'>
						<h4>Export Enrollees to email</h4>
					</div>
				</div> */}

				<div className='card-body'>
					<div className='row'>
						<div className='col-12 col-md-6'>
							<div className='form-group'>
								<label className='font-weight-bold'>
									<h5>Start Date</h5>
								</label>
								<input
									className='form-control'
									type='date'
									value={startDate}
									onChange={(e) => setStartDate(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-12 col-md-6'>
							<div className='form-group'>
								<label className='font-weight-bold'>
									<h5>End Date</h5>
								</label>
								<input
									className='form-control'
									type='date'
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className='row my-4'>
						<div className='col-12 col-md-6'>
							<label>
								<h5>Preset Duration</h5>
							</label>
							<div className='row'>
								<div className='col-6'>
									<div className='form-group'>
										<button
											className='btn btn-success w-100'
											onClick={(e) => {
												e.preventDefault();
												setStartDate(
													moment().subtract('months', 1).format('yyyy-MM-DD')
												);
												setEndDate(moment(new Date()).format('yyyy-MM-DD'));
											}}
										>
											One Month Ago
										</button>
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group'>
										<button
											className='btn btn-success w-100'
											onClick={(e) => {
												e.preventDefault();
												setStartDate(
													moment().subtract('weeks', 1).format('yyyy-MM-DD')
												);
												setEndDate(moment(new Date()).format('yyyy-MM-DD'));
											}}
										>
											One Week Ago
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-12 col-md-6'>
							<div className='form-group'>
								<label className='font-weight-bold'>
									<h5>Enrollee Type</h5>
								</label>

								<select
									className='form-control'
									value={enrolleeType}
									onChange={(e) => setEnrolleeType(e.target.value)}
								>
									{/* <option value='all'>All</option> */}
									<option value='active'>Active</option>
									<option value='inactive'>Inactive</option>
									<option value='not-enrolled'>Registrants</option>
								</select>
							</div>
						</div>
					</div>

					<div className='col-12 col-md-4 py-3'>
						<button className='btn btn-primary w-100' onClick={handleExport}>
							Export
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
