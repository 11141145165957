import * as React from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from './../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	benefitTypes,
	formID,
	benefitCats,
	benefitSchemes,
}) {
	const { dt } = React.useContext(AppContext);
	const { register, errors, handleSubmit } = useForm({
		criteriaMode: 'all',
		defaultValues,
	});

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='form-group'>
						<label>Name</label>
						<textarea
							className='form-control'
							name='name'
							ref={register({
								required: 'Name is required!',
							})}
							placeholder='Name'
						></textarea>
						<ErrorMsg errors={errors} name='name' />
					</div>

					<div className='row'>
						<div className='form-group col-sm-6'>
							<label>Type</label>
							<select
								className='form-control'
								name='benefit_type_id'
								ref={register({
									required: 'Type is required!',
								})}
							>
								<option value=''> - Select -</option>
								{benefitTypes &&
									benefitTypes.length > 0 &&
									benefitTypes.map((row, index) => {
										return (
											<option key={row.id} value={row.id}>
												{row.name}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='benefit_type_id' />
						</div>

						<div className='form-group col-sm-6'>
							<label>Category</label>
							<select
								className='form-control'
								name='benefit_category_id'
								ref={register({
									required: 'Category is required!',
								})}
							>
								<option value=''> - Select -</option>
								{benefitCats &&
									benefitCats.length > 0 &&
									benefitCats.map((row, index) => {
										return (
											<option key={row.id} value={row.id}>
												{row.name}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='benefit_category_id' />
						</div>
					</div>

					<div className='form-group'>
						<label>Insurance Scheme</label>
						<select
							className='form-control'
							name='insurance_scheme'
							ref={register({
								required: 'Scheme is required!',
							})}
						>
							<option value=''> - Select -</option>
							{benefitSchemes &&
								benefitSchemes.length > 0 &&
								benefitSchemes.map((row) => {
									return (
										<option key={row.id} value={row.insurance_scheme}>
											{row.insurance_scheme}
										</option>
									);
								})}
						</select>
						<ErrorMsg errors={errors} name='insurance_scheme' />
					</div>

					<div className='form-group'>
						<label>Price</label>
						<input
							type='number'
							className='form-control'
							name='price'
							placeholder='Price'
							ref={register({
								required: 'Price is required!',
							})}
						/>
						<ErrorMsg errors={errors} name='price' />
					</div>

					<div className='form-group'>
						<div className='custom-control custom-checkbox'>
							<input
								type='checkbox'
								className='custom-control-input'
								id={`is_capitated`}
								name='is_capitated'
								ref={register}
							/>
							<label className='custom-control-label' htmlFor={`is_capitated`}>
								Capitated
							</label>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
