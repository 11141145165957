import * as React from "react";
import AppContext from "../../context";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMsg } from "./../../components/Components";

export default function Form({
  handleCloseModal,
  onSubmit,
  defaultValues,
  userRoleData,
  formID,
}) {
  const { dt } = React.useContext(AppContext);
  const { register, errors, handleSubmit } = useForm({
    criteriaMode: "all",
    defaultValues,
  });

  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>Amount</label>
              <input
                type="text"
                className="form-control"
                name="amount"
                placeholder="Amount"
                ref={register({
                  required: "Amount is required!",
                })}
              />
              <ErrorMsg errors={errors} name="name" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-success btn-main" type="submit">
            Add Payment
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
