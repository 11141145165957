import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppContext from './../context';
import HeaderNavbar from './HeaderNavbar';
import useWindowResize from './../hooks/useWindowResize';
import SideMenu from './SideMenu';

const Master = ({ children, history }) => {
	const { dispatch, userToken, pageTitle, baseUrl, showPageTitle } =
		React.useContext(AppContext);
	const [ready, setReady] = React.useState(false);
	const { windowWidth } = useWindowResize(0);

	//useScript(`${baseUrl}assets/js/app.js`);

	React.useEffect(() => {
		const body = document.querySelector('body');
		const toggleMenu = document.getElementById('vertical-menu-btn');
		if (toggleMenu) {
			toggleMenu.addEventListener('click', () => {
				//console.log(windowWidth, 'windowWidth...');
				//body.classList.toggle("sidebar-enable");
				body.classList.contains('sidebar-enable')
					? body.classList.remove('sidebar-enable')
					: body.classList.add('sidebar-enable');
				if (windowWidth > 992) {
					//body.classList.toggle("vertical-collpsed");
					body.classList.contains('vertical-collpsed')
						? body.classList.remove('vertical-collpsed')
						: body.classList.add('vertical-collpsed');
				} else {
					console.log(windowWidth, 'remove...');
					body.classList.remove('vertical-collpsed');
				}
				/* if (992 <= windowWidth) {
                    console.log(windowWidth, currentPath, '992 <=');
                    
                    body.classList.remove("vertical-collpsed");
                } else {
                    console.log(windowWidth, 'remove...');
                    body.classList.toggle("vertical-collpsed");
                } */
			});

			if (windowWidth <= 992) {
				const links = document.querySelectorAll('a.sidebar-menu-link');
				links.forEach((elm, key) => {
					elm.addEventListener('click', () => {
						body.classList.remove('sidebar-enable');
						body.classList.remove('vertical-collpsed');
					});
				});
			}
		}
	}, [windowWidth]);

	return userToken ? (
		<>
			<div id='layout-wrapper'>
				<HeaderNavbar />

				{/* <!-- ========== Left Sidebar Start ========== --> */}
				<div className='vertical-menu'>
					<div data-simplebar className='h-100'>
						{/* <!--- Sidemenu --> */}
						<SideMenu />
						{/* <!-- Sidebar --> */}
					</div>
				</div>
				{/* <!-- Left Sidebar End --> */}

				{/* <!-- ============================================================== -->
                <!-- Start right Content here -->
                <!-- ============================================================== --> */}
				<div className='main-content'>
					<div className='page-content'>
						{/* <!-- container-fluid --> */}
						<div className='container-fluid'>
							{showPageTitle && (
								<div className='row'>
									<div className='col-12'>
										<div className='page-title-box d-flex align-items-center justify-content-between'>
											<h4 className='mb-0 font-size-18'>{pageTitle}</h4>
										</div>
									</div>
								</div>
							)}

							{children}
						</div>
						{/* <!-- end container-fluid --> */}
					</div>
					{/* <!-- End Page-content --> */}

					<footer className='footer'>
						<div className='container-fluid'>
							<div className='row'>
								<div className='col-sm-6'>
									<script>document.write(new Date().getFullYear())</script> ©
									Edo State Health Insurance Commission.
								</div>
								<div className='col-sm-6'>
									<div className='text-sm-right d-none d-sm-block'>
										Design & Develop by ...
									</div>
								</div>
							</div>
						</div>
					</footer>
				</div>
				{/* <!-- end main content--> */}
			</div>
			{/* <!-- END layout-wrapper --> */}

			{/* <!-- Right bar overlay--> */}
			<div className='rightbar-overlay'></div>
		</>
	) : (
		<>{children}</>
	);
};
export default withRouter(Master);
