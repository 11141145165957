import * as React from 'react';
import AppContext from '../../context';
import { enrollees, enrolleeData } from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import moment from 'moment';
import { Modal, Dropdown } from 'react-bootstrap';
import { Paginate, randomString } from './../../components/Components';
import useModal from './../../hooks/useModal';
import useRouter from './../../hooks/useRouter';
import Details from './details';

const initValues = {
	services: [],
	contact: {},
};

export default function Enrollees({ history }) {
	const queryClient = useQueryClient();
	const {
		dispatch,
		setQueryString,
		useQueryString,
		currentPath,
		notify,
		formActionType,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);

	const [enrolleeDetails, setEnrolleeDetails] = React.useState({});
	const searchRef = React.useRef();

	const [queryName] = React.useState('enrollees');
	const [formID] = React.useState('form-enrollee');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();

	const router = useRouter();
	const [query, setQuery] = React.useState(router.query);

	React.useEffect(() => {
		setQuery(router.query);
	}, [router.query]);

	/* Page data */
	const { isLoading, isFetching, isError, data, error } = useQuery(
		[queryName, query],
		() => enrollees(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const handleCloseModal = () => {
		setModalState(false);
		setDefaultValues(initValues);
	};

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query?.page ? query.page + 1 : 1 };
			console.log(nextPage, 'nextPage...');
			queryClient.prefetchQuery([queryName, nextPage], () =>
				enrollees(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`/enrollees${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);
		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;
		setEnrolleeDetails(row);
		setModalTitle(title);
		setModalState(true);
	};

	const initAddDependant = (data) => {
		history.push('/enrollee/add-dependant', { enrolleeData: data });
	};

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=''
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
			&#x25bc;
		</a>
	));

	const CustomMenu = React.forwardRef(
		({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
			const [value, setValue] = React.useState('');

			return (
				<div
					ref={ref}
					style={{ padding: '20px' }}
					className={className}
					aria-labelledby={labeledBy}
				>
					<select className='form-control'>
						<option value=''>- Select -</option>
						<option value='1'>step 1</option>
						<option value='2'>step 2</option>
					</select>
					<button className='btn btn-primary'>Submit</button>
				</div>
			);
		}
	);

	const [ref, setRef] = React.useState(null);

	React.useEffect(() => {
		if (ref) {
			//ref.style.backgroundColor = 'red';
			console.log(ref, 'ref.current...');
			document
				.getElementById('Dropdown')
				.addEventListener('click', function (event) {
					//alert("click outside");
					event.stopPropagation();
				});
		}

		return () => {
			if (ref) {
				// We need to clean up after this ref
				// The perfect time to unobserve it.
			}
		};
	}, [ref]);

	React.useEffect(() => {}, []);

	const initSearch = () => {
		const q = searchRef.current.value;
		if (!q) {
			searchRef.current.focus();
			notify('danger', 'Validation Error', 'Search value is required!');
		}
		let nQ = { ...query, q };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const sh = true;
	return (
		<>
			{/* {isLoading && <div>loading...</div>}
        {!isLoading && error && <div>error: {error.message}...</div>} */}

			{sh && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col-sm-8'>
										<div className='dropdown mt-4 mt-sm-0' ref={setRef}>
											<a
												href='#'
												className='btn btn-success btn-rounded dropdown-toggle'
												data-toggle='dropdown'
												aria-haspopup='true'
												aria-expanded='false'
											>
												<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
												Search
												<i className='mdi mdi-chevron-down'></i>
											</a>

											<div
												id='Dropdown'
												className='dropdown-menu dropdown-menu-content'
											>
												<div className='row'>
													<div className='form-group col-sm-6'>
														<input
															type='text'
															className='form-control'
															id='s_q'
															placeholder='Names or phone...'
														/>
													</div>

													<div className='form-group col-sm-6'>
														<input
															type='text'
															className='form-control'
															id='s_q'
															placeholder='Reg date range..'
														/>
													</div>

													<div className='form-group col-md-6'>
														<select className='form-control' id='s_religion'>
															<option value=''>- Religion -</option>
															<option value='Christian'>Christian</option>
															<option value='Muslim'>Muslim</option>
														</select>
													</div>

													<div className='form-group col-md-6'>
														<select className='form-control' id='s_gender'>
															<option value=''>- Gender -</option>
															<option value='Female'>Female</option>
															<option value='Male'>Male</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* <div className="input-group col-sm-6">
                                <input type="text" className="form-control"
                                    ref={searchRef}
                                    placeholder="Search enrollee name" />
                                <div className="input-group-append">
                                    <button onClick={initSearch} className="btn btn-success" type="button">Search</button>
                                </div>
                            </div> */}

									<div className='col-sm-4 float-right'>
										<div className='text-sm-right'>
											<button
												type='button'
												onClick={() =>
													history.push(
														'/enrollee/enrollees-design?q=' + randomString(20)
													)
												}
												className='btn btn-success btn-rounded waves-effect waves-light'
											>
												<i className='mdi mdi-plus mr-1'></i>New Enrollee
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Details
					handleCloseModal={handleCloseModal}
					enrolleeDetails={enrolleeDetails}
				/>
			</Modal>
		</>
	);
}
