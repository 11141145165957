import { useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../context';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from './../../hooks/useModal';
import { claimData, acceptedClaims } from '../../Services';

import { Paginate } from './../../components/Components';

import ExportExcel from './ExportExcel';

const initValues = {
	mda: '',
};

export default function ApprovedClaims({ history, setQuery, query }) {
	const tableRef = useRef(null);

	const [exportExcel, setExportExcel] = useState(false);
	const [currentPage, setCurrentPage] = useState('approved-claims');
	const queryClient = useQueryClient();
	const [queryName] = useState('approved-claims');
	const [showApprove, hideApprove] = useState(false);
	const { modalState, closeModal, showModal } = useModal();

	const [exportData, setExportData] = useState([]);
	const [exporting, setExporting] = useState(false);
	const [exportReady, setExportReady] = useState(false);

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		useContext(AppContext);

	const [claimDetails, setClaimDetails] = useState(null);

	const handleCloseModal = () => {
		closeModal();
		setClaimDetails(initValues);
	};

	const {
		data: claimsData,
		isLoading: claimLoading,
		isError: claimError,
	} = useQuery(
		[queryName, query],
		() => acceptedClaims(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Export data */
	useQuery(
		['export-claims', query],
		() => acceptedClaims(setQueryString(query)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				console.log(data, next_page_url, 'export data...');
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQuery({
						...query,
						page: query.page + 1 || 1,
					});
				} else {
					setExporting(false);
					setExportReady(true);
					setQuery({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useEffect(() => {
		if (claimsData?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['approved-claims', nextPage], () =>
				acceptedClaims(setQueryString(nextPage))
			);
		}
	}, [claimData, query, queryClient]);

	const handlePageClick = ({ selected }) => {
		console.log(selected);
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { isLoading: detailsLoading } = useQuery(
		['claim-details', claimDetails?.id],
		() => claimData(claimDetails?.id),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(claimDetails?.id),
			onSuccess: (data) => setClaimDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const initDetails = (row) => {
		setClaimDetails(row);
		showModal();
	};

	return (
		<>
			{claimLoading && <div>loading...</div>}
			{!claimLoading && claimError && <div>error: {claimError.message}...</div>}

			{claimsData && claimsData?.data?.length > 0 ? (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row justify-content-between'>
									<div className='card-title'>
										<h4 className='text-dark pl-2'>Approved Claims</h4>
									</div>

									{exportReady ? (
										<ExportExcel data={exportData} />
									) : (
										<button
											type='button'
											onClick={() => setExporting(true)}
											className='btn btn-primary mb-2 mr-2'
										>
											<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
											{exporting ? 'Exporting...' : 'Export to Excel'}
										</button>
									)}
								</div>

								<div className='table-responsive'>
									<table
										className='table table-centered table-striped table-nowrap'
										ref={tableRef}
									>
										<thead>
											<tr>
												<th width='20%'>Enrollee</th>
												<th width='10%'>CIN</th>
												<th width='29%'>Facility</th>
												<th width='10%'>Amount</th>
												<th className='text-right' width='10%'>
													Submitted On
												</th>
												<th width='10%'>Auth Code</th>
												<th width='10%'>Referral Code</th>
												{!exportExcel && (
													<th className='text-center' width='1%'>
														Actions
													</th>
												)}
											</tr>
										</thead>
										<tbody>
											{claimsData?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.enrollee?.insurance_id}</td>
														<td>
															{row?.facility?.name.indexOf('(') > -1
																? row?.facility?.name.substring(
																		0,
																		row?.facility?.name.indexOf('(')
																  )
																: row?.facility?.name}
														</td>
														<td>
															<b>NGN</b> {row?.total_amount?.toLocaleString()}
														</td>
														<td className='text-right'>
															{formatDate(row.date_of_submission)}
														</td>
														<td className='text-center'>
															{row.authorization_no || '-'}
														</td>
														<td className='text-center'>
															{row.referral_code || '-'}
														</td>

														{!exportExcel && (
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDetails(row)}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={claimsData} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='px-4'>
					<h4>No Approved Claims</h4>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>Claim Details</Modal.Title>
				</Modal.Header>

				<Details
					handleCloseModal={handleCloseModal}
					claimDetails={claimDetails}
					type={currentPage}
					hideApprove={hideApprove}
					setCurrentPage={setCurrentPage}
					query={query}
					queryName={queryName}
					loading={detailsLoading}
				/>
			</Modal>
		</>
	);
}
