import * as React from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMsg } from './../../components/Components';

export default function Form({ handleCloseModal, onSubmit, defaultValues, formID }) {
    const { dt } = React.useContext(AppContext);
    const { register, errors, handleSubmit } = useForm({ criteriaMode: "all", defaultValues });

    return (<>
        <form id={formID} onSubmit={handleSubmit(onSubmit)}>

            <Modal.Body>
                <div className="row">
                    <div className="form-group col-12">
                        <label>Name</label>
                        <input type="text" className="form-control"
                            name="name"
                            placeholder="Name"
                            ref={register({
                                required: "Name is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="name" />
                    </div>

                </div>
            </Modal.Body>

            <Modal.Footer>
                <input type="button" onClick={handleCloseModal} className="btn btn-default" value="Cancel" />
                <input type="submit" className="btn btn-info btn-main" value="Save" />
            </Modal.Footer>
        </form>
    </>);
}