import * as React from 'react';
import AppContext from '../../../context';
import { Modal } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { ErrorMsg } from './../../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	formID,
	faGroup,
}) {
	const { dispatch, faqFieldTypes, notify } = React.useContext(AppContext);
	const { register, errors, handleSubmit, control } = useForm({
		criteriaMode: 'all',
		defaultValues,
	});
	const [type, setType] = React.useState(defaultValues.field_type);

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
		{
			control,
			name: 'options',
		}
	);

	const onSub = (data) => {
		console.log(data, 'data...');
	};

	/* React.useEffect(() => {
      console.log(errors.options, 'errors..')
    }, [errors.options, fields]) */

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='form-group'>
						<label>Question Group</label>
						<select
							className='form-control'
							name='group_id'
							ref={register({
								required: 'Question group is required!',
							})}
						>
							<option value=''> - Select -</option>
							{faGroup &&
								faGroup.length > 0 &&
								faGroup.map((row, index) => {
									return (
										<option key={row.id} value={row.id}>
											{row.name}
										</option>
									);
								})}
						</select>
						<ErrorMsg errors={errors} name='group_id' />
					</div>

					<div className='form-group'>
						<label>Field Type</label>
						<select
							className='form-control'
							name='field_type'
							onChange={(e) => setType(e.currentTarget.value)}
							ref={register({
								required: 'Field type is required!',
							})}
						>
							<option value=''> - Select -</option>
							{faqFieldTypes &&
								faqFieldTypes.length > 0 &&
								faqFieldTypes.map((row, index) => {
									return (
										<option key={row.id} value={row.value}>
											{row.label}
										</option>
									);
								})}
						</select>
						<ErrorMsg errors={errors} name='field_type' />
					</div>

					<div className='form-group'>
						<label>Question</label>
						<input
							type='text'
							className='form-control'
							name='question'
							placeholder='Question'
							ref={register({
								required: 'Question is required!',
							})}
						/>
						<ErrorMsg errors={errors} name='question' />
					</div>

					<div className='form-group'>
						<label>Field Key</label>
						<input
							type='text'
							className='form-control'
							name='key'
							placeholder='Field Key'
							ref={register({
								required: 'Field key is required!',
							})}
						/>
						<ErrorMsg errors={errors} name='key' />
					</div>

					{(type === 'radio' || type === 'checkbox' || type === 'select') && (
						<div>
							<label>Options</label>
							{fields.map((option, index) => {
								//console.log(option.id, 'id..')
								return (
									<div key={option.id} className='form-group'>
										<div className='row'>
											<div className='col-10'>
												<input
													type='text'
													className='form-control'
													placeholder={`Option ${index + 1}`}
													ref={register({
														required: 'All options are required!',
													})}
													name={`options[${index}].name`}
													defaultValue={`${option?.name}`}
												/>
											</div>
											<div className='col-2'>
												<button
													disabled={fields.length === 1}
													type='button'
													className='btn btn-danger btn-block'
													onClick={() => remove(index)}
												>
													<i className='far fa-trash-alt'></i>
												</button>
											</div>
										</div>
									</div>
								);
							})}
							{errors?.options && (
								<p className='text-error'>All options are required!</p>
							)}
							<button
								type='button'
								className='btn btn-secondary btn-sm'
								onClick={() => append({ name: '' })}
							>
								Add Option
							</button>
						</div>
					)}
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
