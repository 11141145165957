import * as React from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from './../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	formID,
	lgas,
	facilityServices,
	facilityTypes,
}) {
	const { lengthValidate, validateEmail } = React.useContext(AppContext);

	const { register, errors, handleSubmit, getValues } = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		defaultValues,
	});

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='row'>
						<div className='form-group col-12'>
							<label>Name</label>
							<input
								type='text'
								className='form-control'
								name='name'
								placeholder='Name'
								//defaultValue={defaultValues.name}
								ref={register({
									required: 'Name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='name' />
						</div>
						<div className='form-group col-12'>
							<label>Description</label>
							<textarea
								className='form-control'
								name='about'
								ref={register({
									required: 'Description is required!',
								})}
								//defaultValue={defaultValues.about}
								placeholder='Description'
							></textarea>
							<ErrorMsg errors={errors} name='about' />
						</div>

						<div className='form-group col-12'>
							<label>Address</label>
							<textarea
								className='form-control'
								name='address'
								ref={register({
									required: 'Address is required!',
								})}
								//defaultValue={defaultValues.address}
								placeholder='Address'
							></textarea>
							<ErrorMsg errors={errors} name='address' />
						</div>

						<div className='form-group col-md-6'>
							<label>Local Govt.</label>
							<select
								className='form-control'
								name='lga'
								//defaultValue={defaultValues.lga}
								ref={register({
									required: 'Local Govt. is required!',
								})}
							>
								<option value=''> - Select -</option>
								{lgas &&
									lgas.length > 0 &&
									lgas.map((row, index) => {
										return (
											<option key={row.code} value={row.name}>
												{row.name}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='lga' />
						</div>

						<div className='form-group col-md-6'>
							<label>Facility Type</label>
							<select
								className='form-control'
								name='type'
								//defaultValue={defaultValues.type}
								ref={register({
									required: 'Facility type is required!',
								})}
							>
								<option value=''>- Select -</option>
								{facilityTypes &&
									facilityTypes.length > 0 &&
									facilityTypes.map((row, index) => {
										return (
											<option key={row} value={row}>
												{row}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='type' />
						</div>

						<div className='form-group col-md-6'>
							<label>Longitude</label>
							<input
								type='text'
								className='form-control'
								name='lng'
								placeholder='Longitude'
								ref={register({
									required: 'Longitude is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='lng' />
						</div>

						<div className='form-group col-md-6'>
							<label>Latitude</label>
							<input
								type='text'
								className='form-control'
								name='lat'
								placeholder='Latitude'
								ref={register({
									required: 'Latitude is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='lat' />
						</div>

						<h5 className='py-3 col-12'>Bank Information</h5>

						<div className='form-group col-md-6'>
							<label>Bank Name</label>
							<input
								type='text'
								className='form-control'
								name='bank'
								placeholder='Bank Name'
								ref={register({
									required: 'Bank name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='bank' />
						</div>

						{/* bank account number */}
						<div className='form-group col-md-6'>
							<label>Account Number</label>
							<input
								type='text'
								className='form-control'
								name='bank_account'
								placeholder='Account Number'
								ref={register({
									required: 'Bank account number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='bank_account' />
						</div>

						<h5 className='py-3 col-12'>Contact Person</h5>

						<div className='form-group col-md-2'>
							<label>Title</label>
							<select
								className='form-control'
								name='title'
								//defaultValue={defaultValues?.title}
								ref={register({
									required: 'Title is required!',
								})}
							>
								<option value=''>- Select -</option>
								<option value='Mr.'>Mr.</option>
								<option value='Mrs.'>Mrs.</option>
								<option value='Dr.'>Dr.</option>
								<option value='Chief'>Chief</option>
								<option value='Professor'>Professor</option>
							</select>
							<ErrorMsg errors={errors} name='title' />
						</div>

						<div className='form-group col-md-5'>
							<label>First Name</label>
							<input
								type='text'
								className='form-control'
								name='first_name'
								placeholder='First Name'
								//defaultValue={defaultValues?.first_name}
								ref={register({
									required: 'First name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='first_name' />
						</div>
						<div className='form-group col-md-5'>
							<label>Last Name</label>
							<input
								type='text'
								className='form-control'
								name='last_name'
								placeholder='Last Name'
								//defaultValue={defaultValues?.last_name}
								ref={register({
									required: 'Last name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='last_name' />
						</div>

						<div className='form-group col-md-6'>
							<label>Phone Number</label>
							<input
								type='text'
								className='form-control'
								name='phone'
								placeholder='Phone Number'
								//defaultValue={defaultValues?.phone}
								ref={register({
									required: 'Phone number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='phone' />
						</div>

						<div className='form-group col-md-6'>
							<label>Email Address</label>
							<input
								type='text'
								className='form-control'
								name='email'
								placeholder='Email Address'
								//defaultValue={defaultValues?.email}
								ref={register({
									validate: () => validateEmail(getValues('email')),
								})}
							/>
							<ErrorMsg errors={errors} name='email' />
						</div>

						<h6 className='pt-3 col-12'>Services</h6>

						<div className='form-group col-12'>
							<div className='row'>
								{facilityServices &&
									facilityServices.length > 0 &&
									facilityServices.map((row, index) => {
										return (
											<div className='col-6' key={row.id}>
												<div className='custom-control custom-checkbox mb-2'>
													<input
														type='checkbox'
														className='custom-control-input'
														id={`service-${row.id}`}
														name='services'
														value={row.id}
														ref={register({
															validate: () =>
																lengthValidate(
																	getValues,
																	'services',
																	'Select at least on service'
																),
														})}
													/>
													<label
														className='custom-control-label'
														htmlFor={`service-${row.id}`}
													>
														{row.name}
													</label>
												</div>
											</div>
										);
									})}
							</div>
							<ErrorMsg errors={errors} name='services' />
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
