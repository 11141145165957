import * as React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import AppContext from '../context';
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';
import NumberFormat from 'react-number-format';

export const ErrorMsg = ({ errors, name }) => {
	return (
		<ErrorMessage
			errors={errors}
			name={name}
			render={({ messages }) => {
				return messages
					? Object.entries(messages).map(([type, message]) => (
							<p key={type} className='text-error mt-1 ml-1'>
								{message}
							</p>
					  ))
					: null;
			}}
		/>
	);
};

export function Currency({ value }) {
	return (
		<NumberFormat
			value={value}
			displayType={'text'}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			renderText={(value) => <>&#8358;{value}</>}
		/>
	);
}

export function NumFormat({ value }) {
	return (
		<NumberFormat
			value={value}
			displayType={'text'}
			thousandSeparator={true}
			//decimalScale={2}
			//fixedDecimalScale={true}
			//renderText={value => <>&#8358;{value}</>}
		/>
	);
}

export const ToastDemo = ({ content }) => {
	const { addToast } = useToasts();

	React.useEffect(() => {
		addToast(content, {
			appearance: 'error',
			autoDismiss: false,
		});
	}, []);

	return <></>;
};

export const Paginate = ({ data, onPageChange }) => {
	return (
		<ReactPaginate
			pageCount={data?.last_page}
			initialPage={data?.current_page - 1}
			forcePage={data?.current_page - 1}
			pageRangeDisplayed={4}
			marginPagesDisplayed={2}
			containerClassName='pagination justify-content-end mb-0 mt-1'
			previousLabel='Previous'
			previousClassName='page-item'
			previousLinkClassName='page-link'
			nextLabel='Next'
			nextClassName='page-item'
			nextLinkClassName='page-link'
			activeClassName='active'
			pageClassName='page-item'
			pageLinkClassName='page-link'
			disabledClassName='disabled'
			onPageChange={onPageChange}
			disableInitialCallback={true}
		/>
	);
};

export const Btn = ({
	type = 'button',
	variant = 'primary',
	className,
	...rest
}) => {
	return (
		<button
			className={`btn btn-${variant} btn-block waves-effect waves-light`}
			type={type}
			{...rest}
		>
			Log In
		</button>
	);
};

export const disableForms = (id, status) => {
	var form = document.getElementById(id);
	var allElements = form.elements;
	for (var i = 0, l = allElements.length; i < l; ++i) {
		// allElements[i].readOnly = true;
		if (status) allElements[i].disabled = true;
		else allElements[i].disabled = false;
	}
};

export const PageTitle = ({ title = '', component: Component }) => {
	const { pageTitle } = React.useContext(AppContext);

	return (
		<div className='row'>
			<div className='col-12'>
				<div className='page-title-box d-flex align-items-center justify-content-between'>
					<h4 className='mb-0 font-size-18'>{pageTitle}</h4>

					{/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="javascript: void(0);">Ecommerce</a></li>
                        <li className="breadcrumb-item active">Customers</li>
                    </ol>
                </div> */}
				</div>
			</div>
		</div>
	);
};

export function randomString(length = 10) {
	let result = '';
	const characters = 'abcdefghijklmnopqrstuvwxyz';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function StepIndicator({ currentIndex }) {
	const [title, setTitle] = React.useState('Personal Information');
	React.useEffect(() => {
		const getTitle = (currentIndex) => {
			switch (currentIndex) {
				case 1:
					setTitle('Category');
					break;
				case 2:
					setTitle('Title & Description');
					break;
				case 3:
					setTitle('Property Location');
					break;
				case 4:
					setTitle('Property Features');
					break;
				case 5:
					setTitle('Payment Cycle');
					break;
				case 6:
					setTitle('Rent Rate');
					break;
				case 7:
					setTitle('Security Deposit');
					break;
				case 8:
					setTitle('Upload Images');
					break;
				case 9:
					setTitle('Facilities');
					break;
				case 10:
					setTitle('House Rule');
					break;
				default:
					setTitle('Category');
					break;
			}
		};
		getTitle(currentIndex);
	}, [currentIndex]);
	return (
		<>
			<h5 className='text-orange'>{title}</h5>
			<div className='steps-indicator-container clearfix'>
				{[...Array(10)].map((e, index) => {
					index = index + 1;
					const active = currentIndex === index ? 'active' : '';
					return (
						<div key={index} className='steps-indicator'>
							<div className={`step-indicator ${active}`} />
						</div>
					);
				})}
			</div>
		</>
	);
}
