import * as React from 'react';
import AppContext from '../../context';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment/moment';
import Select from 'react-select';

import { QueryBox } from '../../components/QueryBox';

import { accessRoles } from '../../utils/utils';

import {
	approveReferral,
	declineReferral,
	fetchReferralComments,
	createReferralComments,
	// deleteReferralComment,
	facilitiesList,
	referralData,
} from '../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const roles = accessRoles('facility-change');

export default function ReferralDetails({
	handleCloseModal,
	id,
	setCurrentPage,
	query,
	queryName,
	history,
}) {
	const { dt, notify, dispatch, errorResponse, userData } =
		React.useContext(AppContext);

	const [facilities, setFacilities] = React.useState([]);
	const [selectedFacility, setSelectedFacility] = React.useState({});

	const [referralDetails, setReferralDetails] = React.useState(null);

	const [showQuery, setShowQuery] = React.useState(false);
	// const [showConfirm, setShowConfirm] = React.useState(false);
	// const [reason, setReason] = React.useState('');
	// const [startRequest, setStartRequest] = React.useState('');
	const [commentQ, setCommentQ] = React.useState('');

	const queryClient = useQueryClient();

	const { isLoading: detailsLoading } = useQuery(
		['referral-details', id],
		() => referralData(id),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(id),
			onSuccess: (data) => setReferralDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Facilities. */
	const { isLoading: isFacilitiesLoading } = useQuery(
		'facilities',
		facilitiesList,
		{
			onSuccess: (data) => {
				if (data.length > 0) {
					const facilitiesSelect = data.map((dt) => ({
						value: dt.uuid,
						label: `${dt.name} (${dt.enrollees_count})`,
					}));

					setFacilities(facilitiesSelect);
				}
			},
			onError: (error) => {
				errorResponse({ error, dispatch, history });
				setFacilities([]);
			},
		}
	);

	const { mutate: handleApprove, isLoading: approveLoading } = useMutation(
		(values) => approveReferral(values),
		{
			onSuccess: () => {
				handleCloseModal(false);
				notify(
					'success',
					'Referral Approved',
					'Referral request successfully approved!'
				);
				queryClient.invalidateQueries();
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				handleCloseModal();
				setCurrentPage('approved-referrals');
			},
		}
	);

	const { mutate: handleDecline, isLoading: declineLoading } = useMutation(
		(values) => declineReferral(values),
		{
			onSuccess: () => {
				handleCloseModal(false);
				notify(
					'success',
					'Referral Declined',
					'Referral request successfully declined!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				handleCloseModal();
				setCurrentPage('rejected-referrals');
			},
		}
	);

	// const { data: comments } = useQuery(
	// 	['fetch-referral-comments', uuid],
	// 	() => fetchReferralComments(uuid),
	// 	{
	// 		keepPreviousData: true,
	// 		staleTime: 5000,
	// 		onError: (error) => errorResponse({ error, dispatch, history }),
	// 		enabled: showQuery,
	// 	}
	// );

	// const { mutate: handleSubmitQuery } = useMutation(
	// 	(
	// 		id,
	// 		data = {
	// 			comments: [
	// 				{
	// 					comment: commentQ,
	// 				},
	// 			],
	// 		}
	// 	) => createReferralComments(id, data),
	// 	{
	// 		onSuccess: () => {
	// 			notify(
	// 				'success',
	// 				'Comment Submitted',
	// 				'Comment successfully submitted!'
	// 			);
	// 		},
	// 		onError: (error) => {
	// 			// if (error.response.status === 422) {
	// 			// 	notify('danger', 'Comment not Submitted', 'Please provide a comment');
	// 			// } else {
	// 			// 	errorResponse({ error, dispatch });
	// 			// }

	// 			errorResponse({ error, dispatch });
	// 		},
	// 		onSettled: () =>
	// 			queryClient.refetchQueries(['fetch-referral-comments', uuid]),
	// 	}
	// );

	const initApprove = async (id) => {
		const data = {
			referrals: [
				{
					destination_uuid: selectedFacility.value,
					id,
				},
			],
		};

		handleApprove(data);
	};

	const initDecline = async (id) => {
		const data = {
			referrals: [
				{
					destination_uuid: selectedFacility.value,
					id,
				},
			],
		};

		handleDecline(data);
	};

	if (detailsLoading || isFacilitiesLoading || !referralDetails) {
		return <p>Loading...</p>;
	}

	const {
		enrollee,
		diagnosis,
		status,
		comment,
		date_of_submission,
		date_of_approval,
		uuid,
		facility,
		destination,
		doctor,
		medical_report,
		code,
	} = referralDetails || {};

	return (
		<>
			{showQuery ? (
				<QueryBox
					id={uuid}
					commentQ={commentQ}
					setCommentQ={setCommentQ}
					showQuery={showQuery}
					setShowQuery={setShowQuery}
					fetchFunction={fetchReferralComments}
					createFunction={createReferralComments}
					preset={false}
				/>
			) : (
				<>
					<Modal.Header closeButton>
						<Modal.Title>Referral Details {code && `- ${code}`}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className='row'>
							<div className='col-md-6'>
								<h6 className='text-muted mb-1'>Enrollee</h6>
								<b>
									{enrollee?.first_name} {enrollee?.last_name}
								</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3 mt-md-0'>Enrollee ID</h6>
								<b>{enrollee?.insurance_id}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Date of Birth</h6>
								<b>{enrollee?.dob}</b>
							</div>
							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Gender</h6>
								<b>{enrollee?.gender}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Phone</h6>
								<b>{enrollee?.phone}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Residential Address</h6>
								<b>{enrollee?.address}</b>
							</div>

							<div className='col-12'>
								<hr />
								<h5 className='text-primary mb-2'>Referral Info</h5>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Diagnosis</h6>
										<b>{diagnosis}</b>
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Doctor</h6>
										<b>{doctor || '-'}</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Referred From</h6>
										<b>{facility?.name}</b>
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Referred To</h6>

										{destination !== null || status === 'disapproved' ? (
											<b>{destination?.name || '-'}</b>
										) : (
											<Select
												onChange={(value) => setSelectedFacility(value)}
												options={facilities}
												className='basic-multi-select'
												classNamePrefix='form-control'
											/>
										)}
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Date Submitted</h6>
										<b>{moment(date_of_submission).format('DD/MM/YYYY')}</b>
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Date Approved</h6>
										<b>
											{date_of_approval
												? moment(date_of_approval).format('DD/MM/YYYY')
												: '-'}
										</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Medical Report</h6>
										{medical_report ? (
											<a
												href={medical_report}
												target='_blank'
												rel='noreferrer'
												className='text-info font-weight-bold'
											>
												Click to View
											</a>
										) : (
											<p className='text-error'>No Medical Report</p>
										)}
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Status</h6>
										<b
											className={`text-${
												status === 'approved'
													? 'success'
													: status === 'disapproved'
													? 'error'
													: 'warning'
											}`}
										>
											{status}
										</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-12'>
										<h6 className='text-muted mb-1'>
											Requested Health Services
										</h6>
										<div className='card'>
											<div className='card-body'>
												<b>{comment || 'No Comments yet.'}</b>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='w-100 d-flex flex-row justify-content-center my-3'>
								<Button
									type='button'
									onClick={() => {
										setShowQuery(true);
									}}
									className='mx-2 bg-warning'
									data-toggle='tooltip'
									data-placement='top'
									title='Use this to ask questions and get answers relating to this facility change request.'
									style={{
										// background: '#fce45d',
										color: '#fff',
										width: '20%',
										borderRadius: '15px',
										border: 'none',
									}}
								>
									Query
								</Button>
								{status === 'pending' && (
									<>
										<Button
											type='button'
											onClick={() => {
												initApprove(uuid);
											}}
											disabled={approveLoading ? true : false}
											className='mx-2'
											style={{
												background: '#389e7f',
												color: '#fff',
												width: '30%',
											}}
										>
											{approveLoading ? 'approving...' : 'Approve'}
											{/* Approve */}
										</Button>

										<Button
											type='button'
											onClick={() => {
												initDecline(uuid);
											}}
											disabled={declineLoading ? true : false}
											className='btn-danger'
											style={{
												width: '30%',
											}}
										>
											{declineLoading ? 'declining...' : 'Decline'}
										</Button>
									</>
								)}
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<input
							type='button'
							onClick={handleCloseModal}
							className='btn btn-default'
							value='Close'
						/>
					</Modal.Footer>
				</>
			)}

			{/* {showQuery ? (
				<>
					<Modal.Body>
						<div className='row'>
							<div className='col-12'>
								<div className='card'>
									<div className='h3 m-0'>Query/Comments</div>
									<div className='card-body'>
										<div className='my-3'>
											{comments &&
												comments.data.map((comment) => (
													<div className='row'>
														<div className='col-9'>
															<p
																className='m-0 my-1'
																style={{ fontWeight: 'bold' }}
															>
																<span className='fw-bold text-primary mx-2'>{`${comment.author.first_name} ${comment.author.last_name}: `}</span>{' '}
																{comment.comment}
															</p>
														</div>
														<div className='col-3'>
															<p className='text-muted m-0'>
																{moment(comment.created_at).format(
																	'DD MMM YYYY'
																)}
															</p>
														</div>
													</div>
												))}
										</div>
										{status === 'pending' && (
											<div className='form-group col-12'>
												<h6 className='text-muted mb-3'>Query</h6>

												<textarea
													className='form-control'
													name='query'
													cols='30'
													rows='5'
													placeholder='Your query here'
													onChange={(e) => setCommentQ(e.target.value)}
												></textarea>

												<div
													className='d-flex flex-row float-right'
													style={{ gap: '10px' }}
												>
													<button
														className='btn mt-3'
														onClick={() => setShowQuery(!showQuery)}
														style={{
															background: '#ccc',
															color: '#fff',
															// width: '20px',
														}}
													>
														Back
													</button>

													<button
														className='btn mt-3'
														onClick={() => handleSubmitQuery(uuid)}
														style={{
															background: '#389e7f',
															color: '#fff',
															// width: '20%',
														}}
													>
														Submit Comment
													</button>
												</div>
											</div>
										)}
										{status !== 'pending' && (
											<div
												className='d-flex flex-row float-right'
												style={{ gap: '10px' }}
											>
												<button
													className='btn mt-3'
													onClick={() => setShowQuery(!showQuery)}
													style={{
														background: '#ccc',
														color: '#fff',
														// width: '20px',
													}}
												>
													Back
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</>
			) : showConfirm ? (
				<Modal.Body>
					<div className='row'>
						<div className='col-12'>
							<h5 className='text-muted mb-3'>
								Please add a Comment with your reason of {'  '}
								{reason === 'approve' ? 'approving' : 'declining'}
								{'  '}
								this request.
							</h5>

							{status === 'pending' && (
								<div className='form-group col-12'>
									<textarea
										className='form-control'
										name='query'
										cols='30'
										rows='5'
										placeholder='Your comment here'
										onChange={(e) => setCommentQ(e.target.value)}
									></textarea>

									<div
										className='d-flex flex-row float-right'
										style={{ gap: '10px' }}
									>
										<button
											className='btn mt-3'
											onClick={() => {
												setShowConfirm(!showConfirm);
												setStartRequest('');
											}}
											style={{
												background: '#ccc',
												color: '#fff',
											}}
										>
											Back
										</button>

										{startRequest === '' && (
											<button
												className='btn mt-3'
												onClick={() => handleSubmitQuery(uuid)}
												style={{
													background: '#389e7f',
													color: '#fff',
												}}
											>
												Submit Comment
											</button>
										)}

										{startRequest !== '' && (
											<button
												className='btn mt-3'
												onClick={() =>
													startRequest === 'approve-req'
														? initApproveReq(uuid)
														: initDeclineReq(uuid)
												}
												style={{
													background: '#389e7f',
													color: '#fff',
												}}
											>
												{startRequest === 'approve-req' ? 'Approve' : 'Decline'}
											</button>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			) : (
				<>
					<Modal.Body>
						<div className='row'>
							<div className='col-md-6'>
								<h6 className='text-muted mb-1'>Enrollee</h6>
								<b>
									{enrollee?.first_name} {enrollee?.last_name}
								</b>
							</div>
							<div className='col-md-6'>
								<h6 className='text-muted mb-1'>Enrollee ID</h6>
								<b>{enrollee?.insurance_id}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Date of Birth</h6>
								<b>{enrollee?.dob}</b>
							</div>
							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Gender</h6>
								<b>{enrollee?.gender}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Office Address</h6>
								<b>{enrollee?.office_address}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Residential Address</h6>
								<b>{enrollee?.address}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>Phone</h6>
								<b>{enrollee?.phone}</b>
							</div>

							<div className='col-md-6'>
								<h6 className='text-muted mb-1 mt-3'>MDA</h6>
								<b>{enrollee?.business_name}</b>
							</div>

							<div className='col-12'>
								<hr />
								<h5 className='text-primary mb-2'>Facility Change Info</h5>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>From</h6>
										<b>{from?.name}</b>
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Created Date</h6>
										<b>{moment(created_at).format('DD/MM/YYYY')}</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>To</h6>
										<b>{to?.name}</b>
									</div>

									<div className='col-md-6'>
										<h6 className='text-muted mb-1'>Status</h6>
										<b>{status}</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-12'>
										<h6 className='text-muted mb-1'>Comment</h6>
										<div className='card'>
											<div className='card-body'>
												<b>{comment || 'No Comments yet.'}</b>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='w-100 d-flex flex-row justify-content-center my-3'>
								<Button
									type='button'
									onClick={() => {
										setShowQuery(true);
									}}
									className='mx-2 bg-warning'
									data-toggle='tooltip'
									data-placement='top'
									title='Use this to ask questions and get answers relating to this facility change request.'
									style={{
										// background: '#fce45d',
										color: '#fff',
										width: '20%',
										borderRadius: '15px',
										border: 'none',
									}}
								>
									Query
								</Button>
								{status === 'pending' && (
									<>
										{roles.includes(userData.role.name) && (
											<>
												<Button
													type='button'
													onClick={() => {
														initApprove(uuid);
													}}
													disabled={approveLoading ? true : false}
													className='mx-2'
													style={{
														background: '#389e7f',
														color: '#fff',
														width: '30%',
													}}
												>
													{approveLoading ? 'approving...' : 'Approve'}
												</Button>
												<Button
													type='button'
													onClick={() => {
														initDecline(uuid);
													}}
													disabled={declineLoading ? true : false}
													className='btn-danger'
													style={{
														width: '30%',
													}}
												>
													{declineLoading ? 'declining...' : 'Decline'}
												</Button>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<input
							type='button'
							onClick={handleCloseModal}
							className='btn btn-default'
							value='Close'
						/>
					</Modal.Footer>
				</>
			)} */}
		</>
	);
}
