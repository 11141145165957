import { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { useQueryClient } from 'react-query';
import { Accordion, Card, Modal } from 'react-bootstrap';
import useResize from '../../hooks/useResize';
import { ErrorMsg, Currency } from '../../components/Components';
import AppContext from '../../context';
import {
	enrolleeData,
	enrolleeUpgradePlan,
	packagesList,
	enrolleePayUpgrade,
} from '../../Services';
import useModal from '../../hooks/useModal';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function EnrolleeDetails({
	enrolleeDetails,
	handlePlanCloseModal,
	queryName,
	query,
}) {
	const {
		dispatch,
		checkArray,
		checkObject,
		capitalize,
		errorResponse,
		paymentMethods,
		exemptions,
		notify,
	} = useContext(AppContext);

	const queryClient = useQueryClient();

	const avatarContainerRef = useRef();
	const { width, height } = useResize(avatarContainerRef);
	const [data, setData] = useState(enrolleeDetails);
	const [benefits, setBenefits] = useState([]);

	const [index, setIndex] = useState(0);
	const [isFamilyPlan, setIsFamilyPlan] = useState(false);
	const [packageID, setPackageID] = useState(0);
	const [payData, setPayData] = useState({});
	const [paymentMethod, setPaymentMethod] = useState('');
	const [bankName, setBankName] = useState('');
	const [tellerNo, setTellerNo] = useState('');
	const [exemptionID, setExemptionID] = useState(0);
	const [employeeID, setEmployeeID] = useState(0);
	const [date, setDate] = useState(new Date());

	const {
		data: enrolleeDt,
		isLoading,
		isFetching,
	} = useQuery(
		['enrollee-details', enrolleeDetails.id],
		enrolleeData(enrolleeDetails.id)
	);

	const {
		data: packages,
		isLoading: packagesLoading,
		isFetching: packagesFetching,
	} = useQuery('packages', packagesList);

	useEffect(() => {
		if (enrolleeDt) setData(enrolleeDt);

		return () => {
			setData({});
		};
	}, [enrolleeDt]);

	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();

	const showPlan = ({ benefits, name }) => {
		const groupData = benefits.reduce(
			(h, dt) =>
				Object.assign(h, {
					[dt.type]: (h[dt.type] || []).concat({ id: dt.id, name: dt.name }),
				}),
			{}
		);
		setBenefits(groupData);
		setModalTitle(name);
		setModalState(true);
	};

	const { mutate: submitPackage } = useMutation(
		({ id, values }) => enrolleeUpgradePlan({ id, values }),
		{
			onSuccess: (data) => {
				setIndex(1);
				setPayData(data);
			},
			onError: (error) => errorResponse({ error, dispatch }),
		}
	);

	const { mutate: submitInvoice } = useMutation(
		({ id, values }) => enrolleePayUpgrade({ id, values }),
		{
			onSuccess: (data) => {
				notify('success', 'Upgrade Successful', 'Your upgrade was successful.');
				handlePlanCloseModal();
			},
			onMutate: () => {
				queryClient.cancelQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const handleUpgradePackage = (e) => {
		e.preventDefault();

		const data = {
			id: enrolleeDt.id,
			values: {
				package_id: Number(packageID),
				is_family_plan: Boolean(isFamilyPlan),
			},
		};

		submitPackage(data);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			id: enrolleeDt.id,
			values: {
				transaction_id: payData.transaction_id,
				payment_method: paymentMethod,
				amount: payData.amount,
			},
		};

		// Bank Payment
		if (paymentMethod === 'bank_payment') {
			data.values.bank_name = bankName;
			data.values.teller_ref = tellerNo;
			data.values.payment_date = moment(new Date(date)).format('DD-MM-YYYY');
			data.values.vendor = 'remita';
		}

		// Exemption
		if (paymentMethod === 'exemption') {
			data.values.exemption_category = 'govt_employee';
			data.values.employee_number = employeeID;
		}

		submitInvoice(data);
	};

	if (isLoading || packagesLoading || paymentMethods.length < 1)
		return <div>Loading...</div>;

	return (
		<>
			{index === 0 ? (
				<PlanDetails
					enrolleeDt={enrolleeDt}
					isFamilyPlan={isFamilyPlan}
					setIsFamilyPlan={setIsFamilyPlan}
					packages={packages}
					setPackageID={setPackageID}
					showPlan={showPlan}
					handleUpgradePackage={handleUpgradePackage}
				/>
			) : (
				<PlanPayment
					payData={payData}
					paymentMethods={paymentMethods}
					paymentMethod={paymentMethod}
					setPaymentMethod={setPaymentMethod}
					exemptions={exemptions}
					exemptionID={exemptionID}
					setExemptionID={setExemptionID}
					date={date}
					setDate={setDate}
					handleSubmit={handleSubmit}
					employeeID={employeeID}
					setEmployeeID={setEmployeeID}
					bankName={bankName}
					setBankName={setBankName}
					tellerNo={tellerNo}
					setTellerNo={setTellerNo}
				/>
			)}
			<Modal show={modalState} onHide={closeModal} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{checkObject(benefits) && (
						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							{Object.entries(benefits).map((value, key) => {
								return (
									<Fragment key={value[0]}>
										<thead>
											<tr>
												<th width='100%'>{capitalize(value[0])}</th>
											</tr>
										</thead>
										<tbody>
											{value[1] &&
												value[1].map((row, index) => {
													return (
														<tr key={row.id}>
															<td scope='row'>{row.name}</td>
														</tr>
													);
												})}
										</tbody>
									</Fragment>
								);
							})}
						</table>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}

function PlanDetails({
	enrolleeDt,
	isFamilyPlan,
	setIsFamilyPlan,
	packages,
	setPackageID,
	showPlan,
	handleUpgradePackage,
}) {
	return (
		<Modal.Body>
			<div className='mb-3'>
				<h3 className='mb-3'>Current Plan Details</h3>

				<div className='row'>
					<div className='col-md-6'>
						<h5>Plan Name</h5>
						<p>{enrolleeDt.package.name}</p>
					</div>
					<div className='col-md-6'>
						<h5>Plan Price</h5>
						<p>
							<Currency value={enrolleeDt.package.amount} />
						</p>
					</div>
				</div>
			</div>

			<div>
				<h3 className='mb-3'>Upgrade Plan</h3>

				<div className='row'>
					<div className='col-12 m-0 p-0'>
						<div className='form-group col-md-4'>
							<label>Plan Type</label>
							<select
								className='form-control'
								name='is_family_plan'
								onChange={(e) => setIsFamilyPlan(e.target.value)}
							>
								<option value='false'>Standard Plan</option>
								<option value='true'>Family Plan</option>
							</select>
						</div>
					</div>

					<div className='col-12 m-0 p-0'>
						<div className='row p-4'>
							{packages.length > 0
								? packages
										.filter((pack) => {
											if (enrolleeDt.package.id === pack.id) return false;

											if (enrolleeDt?.corporate_id) {
												return pack?.is_public === 1;
											} else {
												if (isFamilyPlan === 'true') {
													return pack?.is_family_allowed === 1;
												} else {
													return pack?.is_family_allowed === 0;
												}

												// return true;
											}
										})
										.map((row, index) => (
											<div
												key={row.id}
												className='col-6 custom-control custom-radio custom-control-select'
											>
												<input
													type='radio'
													id={`plan-${row.id}`}
													value={row.id}
													name='package_id'
													className='custom-control-input'
													onChange={(e) => setPackageID(e.target.value)}
												/>
												<label
													className='custom-control-label custom-control-label-select'
													htmlFor={`plan-${row.id}`}
												>
													<span>{row.name}</span>

													<span>
														<Currency value={row.amount} />
													</span>

													<span onClick={() => showPlan(row)}>View Plan</span>
												</label>
											</div>
										))
								: ''}
						</div>
					</div>
				</div>
				<div className='py-1'>
					<button className='btn btn-primary' onClick={handleUpgradePackage}>
						Upgrade Package
					</button>
				</div>
			</div>
		</Modal.Body>
	);
}

function PlanPayment({
	payData,
	paymentMethods,
	paymentMethod,
	setPaymentMethod,
	exemptions,
	exemptionID,
	setExemptionID,
	date,
	setDate,
	handleSubmit,
	employeeID,
	setEmployeeID,
	bankName,
	setBankName,
	tellerNo,
	setTellerNo,
}) {
	return (
		<Modal.Body>
			<h4>Payment Details</h4>

			{payData && Object.keys(payData).length !== 0 && (
				<div className='row'>
					<div className='col-md-6 mb-3'>
						<h5 className='m-0'>Package Name</h5>
						<p className='m-0'>{payData.package.name}</p>
					</div>

					<div className='col-md-6 mb-3'>
						<h5 className='m-0'>Package Amount</h5>
						<p className='m-0'>
							<Currency value={payData.package.amount} />
						</p>
					</div>

					<div className='col-md-6 mb-3'>
						<h5 className='m-0'>Amount to Pay</h5>
						<p className='m-0'>
							<Currency value={payData.amount} />
						</p>
					</div>

					<div className='col-md-6 mb-3'>
						<h5 className='m-0'>Transaction ID</h5>
						<p className='m-0'>{payData.transaction_id}</p>
					</div>
				</div>
			)}

			<h4>Payment Methods</h4>
			<div className='row px-3'>
				{paymentMethods &&
					paymentMethods.map((row, index) => {
						return (
							<div
								key={row.value}
								className='col-4 text-start custom-control custom-radio custom-control-select'
							>
								<input
									type='radio'
									id={`method-${row.value}`}
									disabled={row.disabled}
									name='payment_method'
									value={row.value}
									checked={paymentMethod === row.value}
									className='custom-control-input'
									onChange={(e) => setPaymentMethod(e.target.value)}
								/>
								<label
									className='custom-control-label custom-control-label-select custom-control-label-select-single'
									htmlFor={`method-${row.value}`}
								>
									<span>{row.label}</span>
								</label>
							</div>
						);
					})}
			</div>

			<div className='row'>
				{paymentMethod === 'exemption' && (
					<>
						<div className='form-group col-12'>
							<label>Exemption Category</label>

							<select
								className='form-control'
								name='exemption_category'
								onChange={(e) => setExemptionID(e.target.value)}
							>
								<option value=''> - Select -</option>
								{/* {exemptions > 0 &&
									exemptions.map((row, index) => {
										return (
											<option key={row.value} value={row.value}>
												{row.label}
											</option>
										);
									})} */}
								<option key={1} value={1}>
									Government Employee
								</option>
							</select>

							<ErrorMsg
								errors={'Exemption Category is Required'}
								name='exemption_category'
							/>
						</div>

						{exemptionID === '1' && (
							<div className='form-group col-12'>
								<label>Employee ID</label>
								<input
									type='text'
									className='form-control'
									name='employee_number'
									placeholder='Employee ID'
									value={employeeID}
									onChange={(e) => setEmployeeID(e.target.value)}
								/>
								<ErrorMsg
									errors={'Employee Number is Required'}
									name='employee_number'
								/>
							</div>
						)}
					</>
				)}

				{paymentMethod === 'bank_payment' && (
					<>
						<div className='form-group col-12'>
							<label>Bank Name</label>
							<input
								type='text'
								className='form-control'
								name='bank_name'
								placeholder='Bank Name'
								// value={bankName}
								onChange={(e) => setBankName(e.target.value)}
							/>
							<ErrorMsg errors={'Bank Name is Required'} name='bank_name' />
						</div>

						<div className='form-group col-12'>
							<label>Teller No.</label>
							<input
								type='text'
								className='form-control'
								name='teller_ref'
								placeholder='Teller No.'
								// value={tellerNo}
								onChange={(e) => setTellerNo(e.target.value)}
							/>
							<ErrorMsg errors={'Teller No. is required!'} name='teller_ref' />
						</div>

						<div className='form-group col-12 form-date-picker'>
							<label>Payment Date</label>

							<DatePicker
								selected={date}
								onChange={(date) => setDate(date)}
								className='form-control'
								placeholderText='Select payment date'
								dateFormat='MMMM d, yyyy'
							/>

							<ErrorMsg errors={'Select Date'} name='payment_date' />
						</div>
					</>
				)}

				<div className='p-3'>
					<button className='btn btn-success btn-main' onClick={handleSubmit}>
						Submit
					</button>
				</div>
			</div>
		</Modal.Body>
	);
}
