import * as React from 'react';
import AppContext from '../../context';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment/moment';
import { Currency } from '../../components/Components';
import { approveClaims, declineClaims } from '../../Services';
import { useMutation, useQueryClient } from 'react-query';

export default function ClaimDetails({
	handleCloseModal,
	claimDetails,
	type,
	setCurrentPage,
	query,
	queryName,
	loading,
}) {
	const { notify, dispatch, errorResponse } = React.useContext(AppContext);

	const queryClient = useQueryClient();

	const { claims: claim, diagnoses_name } = claimDetails;

	const { mutate: handleApprove, isLoading: approveLoading } = useMutation(
		(values) => approveClaims(values),
		{
			onSuccess: () => {
				handleCloseModal(false);
				notify(
					'success',
					'Claims Approved ',
					'Claims request successfully approved!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				setCurrentPage('pending-claims');
			},
		}
	);

	const {
		mutate: handleDecline,
		status: declineStatus,
		isLoading: declineLoading,
	} = useMutation((values) => declineClaims(values), {
		onSuccess: () => {
			handleCloseModal(false);
			notify(
				'success',
				'Claims Declined ',
				'Claims request successfully declined!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			setCurrentPage('pending-claims');
		},
	});

	const initApprove = async (id) => {
		handleApprove(id);
	};

	const initDecline = async (id) => {
		handleDecline(id);
	};

	if (loading || claim === null || claim === undefined) {
		return <p>Loading...</p>;
	}

	return (
		<>
			<Modal.Body>
				<div className='row'>
					<div className='col-md-6'>
						<h6 className='text-muted mb-1'>Facility</h6>
						<b>{claim?.facility ? claim?.facility?.name : 'Undefined'}</b>
					</div>

					<div className='col-md-3'>
						<h6 className='text-muted mb-1'>Enrollee</h6>
						<b>
							{claim?.enrollee?.first_name} {claim?.enrollee?.last_name}
						</b>
					</div>

					<div className='col-md-3 text right'>
						<h6 className='text-muted mb-1'>Enrollee ID</h6>
						<b>{claim?.enrollee?.insurance_id}</b>
					</div>

					<div className='col-md-6'></div>
					<div className='col-md-3 text right'>
						<h6 className='text-muted mb-1 mt-3'>Date of Birth</h6>
						<b>{claim?.enrollee?.dob}</b>
					</div>

					<div className='col-md-3 text right'>
						<h6 className='text-muted mb-1 mt-3'>Gender</h6>
						<b>{claim?.enrollee?.gender}</b>
					</div>

					<div className='col-12'>
						<hr />
						<h5 className='text-primary mb-2'>Claim Info</h5>
						<div className='row mb-3'>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Authorization No</h6>
								<b>{claim?.authorization_no}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Created Date</h6>
								<b>{moment(claim?.created_at).format('DD/MM/YYYY')}</b>
							</div>
						</div>

						<div className='row mb-3'>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Diagnosis</h6>
								<b>{diagnoses_name}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Doctor</h6>
								<b>{claim?.doctor || 'No Doctor'}</b>
							</div>
						</div>

						<div className='row mb-3'>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Total Amount</h6>
								<b>
									<Currency value={claim?.total_amount} />
								</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Approved Amount</h6>
								<b>
									<Currency value={claim?.total_amount_approved || 0} />
								</b>
							</div>
						</div>

						<div className='row mb-3'>
							<div className='col-md-6 mb-3'>
								<h6 className='text-muted mb-1'>Benefits</h6>
								{claim?.charge_lines?.map((charge) => (
									<li className='font-weight-bold' key={charge?.id}>
										{charge?.benefit?.name} ({charge?.unit}) -{' '}
										<Currency value={charge?.total_charged} />
									</li>
								))}
							</div>
						</div>

						<div className='row mb-3'>
							<div className='col-12'>
								<h6 className='text-muted mb-1'>Comment</h6>
								<div className='card'>
									<div className='card-body'>
										<b>{claim?.comment || 'No Comments yet.'}</b>
									</div>
								</div>
							</div>
						</div>
					</div>
					{loading && 'please wait, fetching data...'}

					{(type === 'pending-claims' || claim?.status === 'pending') && (
						<>
							<div className='w-100 d-flex flex-row justify-content-center my-3'>
								<Button
									type='button'
									onClick={() => {
										initApprove(claim.id);
									}}
									disabled={approveLoading ? true : false}
									className='mx-2'
									style={{
										background: '#389e7f',
										color: '#fff',
										width: '30%',
									}}
								>
									{approveLoading ? 'approving...' : 'Approve'}
								</Button>
								<Button
									type='button'
									onClick={() => {
										initDecline(claim.id);
									}}
									disabled={declineLoading ? true : false}
									className='btn-danger'
									style={{
										width: '30%',
									}}
								>
									{declineLoading ? 'declining...' : 'Decline'}
								</Button>
							</div>
						</>
					)}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<input
					type='button'
					onClick={handleCloseModal}
					className='btn btn-default'
					value='Close'
				/>
			</Modal.Footer>
		</>
	);
}
