import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { Currency } from '../../components/Components';
import {
	approveAuthorizations,
	declineAuthorizations,
	changeAutChargeStatus,
	fetchAuthCodeComments,
	createAuthCodeComments,
} from '../../Services';

import { QueryBox } from '../../components/QueryBox';

export default function AuthDetails({
	handleCloseModal,
	loading,
	authDetails,
	type,
	mode,
	setCurrentPage,
	query,
	queryName,
}) {
	const queryClient = useQueryClient();

	const {
		facility,
		enrollee,
		charge_lines,
		diagnoses,
		comment,
		date_of_submission,
		authorization_code,
		referral_code,
		uuid,
		doctor,
		weight,
		status,
	} = authDetails;

	const { notify, dispatch, errorResponse, formatDate } =
		useContext(AppContext);

	const [isAccept, setisAccept] = useState(false);
	const [message, setMessage] = useState('');
	const [commentQ, setCommentQ] = useState('');
	const [showQuery, setShowQuery] = useState(false);

	const [chargeUUIDs, setChargeUUIDs] = useState([]);

	useEffect(() => {
		mode === 'queries' && uuid.length > 0 && charge_lines && setShowQuery(true);
	}, [charge_lines, mode, uuid]);

	const { mutate: handleApprove, isLoading: approveLoading } = useMutation(
		(values) => approveAuthorizations(values),
		{
			onSuccess: () => {
				handleCloseModal(false);
				notify(
					'success',
					'Authentication Code Generated ',
					'Authentication request successfully approved!'
				);
				queryClient.invalidateQueries();
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				setCurrentPage('approved-requests');
			},
		}
	);

	const { mutate: handleDecline, isLoading: declineLoading } = useMutation(
		(values) => declineAuthorizations(values),
		{
			onSuccess: () => {
				handleCloseModal(false);
				notify(
					'success',
					'Authentication Code Rejected ',
					'Authentication request successfully declined!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				handleCloseModal(false);
				setCurrentPage('rejected-requests');
			},
		}
	);

	// Charge Lines
	const { mutate: handleChangeChargeStatus, isLoading: chargeStatusLoading } =
		useMutation(([id, values]) => changeAutChargeStatus(id, values), {
			onSuccess: () => {
				notify('success', 'Successfully Updated Charge Line(s)');
				queryClient.invalidateQueries();
			},
			onError: (error) => errorResponse({ error, dispatch }),
		});

	const handleClose = () => setisAccept(false);

	// Auth
	const initApprove = async (id) => {
		const data = {
			requestIds: [id],
		};

		handleApprove(data);
	};

	const initDecline = async (id) => {
		const data = {
			requestIds: [id],
		};

		handleDecline(data);
	};

	// Charge Lines
	const initChangeChargeStatus = async (id, status) => {
		const data = {
			lineIds: { [id]: status },
		};

		const confirm = window.confirm(
			`Are you sure you want to "${status.slice(0, -1)}" this charge?`
		);

		if (confirm) {
			handleChangeChargeStatus([uuid, data]);
		}
	};

	// Multiple Charge Lines
	const initChangeMultipleChargeStatus = async (status) => {
		// FORMATTED OBJECT FOR MULTIPLE LINE IDS
		let formatted = {};

		chargeUUIDs
			.map((charge) => {
				return charge;
			})
			.forEach((element) => {
				formatted[element] = status;
			});

		const data = {
			lineIds: formatted,
		};

		handleChangeChargeStatus([uuid, data]);
	};

	// Checkbox for Charge Lines
	const onCheckCharge = (e) => {
		const isChecked = e.target.checked;

		if (isChecked) {
			setChargeUUIDs([...chargeUUIDs, e.target.value]);
		} else {
			const index = chargeUUIDs.indexOf(e.target.value);
			chargeUUIDs.splice(index, 1);
			setChargeUUIDs(chargeUUIDs);
		}
	};

	// Helpers to check if charge lines contain respective status
	const checkChargeStatusPending = (obj) => obj.status === 'pending';
	const checkChargeStatusApproved = (obj) => obj.status === 'approved';
	const checkChargeStatusDisapproved = (obj) => obj.status === 'disapproved';

	if (mode === 'queries' && !charge_lines) {
		return (
			<div className='d-flex justify-content-center align-items-center m-5'>
				<div className='spinner-border' role='status'>
					<span className='sr-only'>Loading...</span>
				</div>
			</div>
		);
	} else
		return (
			<>
				{showQuery ? (
					<QueryBox
						// auth_uuid={auth_uuid}
						// uuid={uuid}
						id={uuid}
						commentQ={commentQ}
						setCommentQ={setCommentQ}
						showQuery={showQuery}
						setShowQuery={setShowQuery}
						fetchFunction={fetchAuthCodeComments}
						createFunction={createAuthCodeComments}
						preset
					/>
				) : (
					<>
						<Modal.Header closeButton>
							<Modal.Title>
								Authorization Request Details{' '}
								{referral_code && (
									<span>
										- Referral Code:{' '}
										<span className='text-info'>{referral_code}</span>
									</span>
								)}
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<div className='row'>
								<div className='col-md-6'>
									<h6 className='text-muted mb-1'>Facility</h6>
									<b>{facility?.name}</b>
								</div>

								<div className='col-md-6 text right '>
									<h6 className='text-muted mb-1'>Date Submitted</h6>
									<b> {formatDate(date_of_submission)} </b>{' '}
								</div>

								<div className='col-md-6 my-3'>
									<h6 className='text-muted mb-1'>Name</h6>
									<b>
										{enrollee?.first_name} {enrollee?.last_name}
									</b>
								</div>

								<div className='col-md-6 my-3'>
									<h6 className='text-muted mb-1'>Enrollee ID</h6>
									<b>{enrollee?.insurance_id}</b>
								</div>

								<div className='col-md-6'>
									<h6 className='text-muted mb-1'>Gender</h6>
									<b> {enrollee?.gender} </b>
								</div>

								<div className='col-md-6'>
									<h6 className='text-muted mb-1'>Date of Birth</h6>
									<b> {enrollee?.dob} </b>
								</div>

								<div className='col-md-6 my-3'>
									<h6 className='text-muted mb-1'>Enrollee Phone Number</h6>
									<b>
										<b>{enrollee?.phone}</b>
									</b>
								</div>

								<div className='col-md-6 my-3'>
									<h6 className='text-muted mb-1'>Weight</h6>
									<b> {weight || '-'} kg </b>
								</div>

								<div className='col-md-6 mb-3'>
									<h6 className='text-muted mb-1'>Doctor</h6>
									<b>
										<b>{doctor || 'No Doctor'}</b>
									</b>
								</div>

								<div className='col-md-6 mb-3'>
									<h6 className='text-muted mb-1'>Authorization Code</h6>
									<b>
										<b>{authorization_code || '-'}</b>
									</b>
								</div>

								<div className='col-md-6 mb-3'>
									<h6 className='text-muted mb-1'>Start Date</h6>
									<b>
										<b>{enrollee?.start_date || '-'}</b>
									</b>
								</div>

								<div className='col-md-6 mb-3'>
									<h6 className='text-muted mb-1'>End Date</h6>
									<b>
										<b>{enrollee?.expiration_date || '-'}</b>
									</b>
								</div>

								<div className='col-12'>
									<h6 className='text-muted mb-1'>Comment/Prescription</h6>
									<b> {comment || '-'} </b>
								</div>

								<div className='col-12'>
									<hr />
									<h5 className='text-primary mb-2'>Diagnoses</h5>
									{loading && (
										<div className='d-flex justify-content-center'>
											<div className='spinner-border' role='status'>
												<span className='sr-only'>Loading...</span>
											</div>
										</div>
									)}

									<div className='row'>
										{diagnoses &&
											diagnoses.map((item) => {
												return (
													<div key={item.id} className='row mx-2'>
														<div className='col-12'>
															<p>
																Diagnosis Name: <b>{item.diagnosis_name}</b>
															</p>
														</div>
														<div className='col-6'>
															<p className='m-0'>
																Diagnosis ID: <b>{item.id}</b>
															</p>
														</div>
														<div className='col-6'>
															<p className='m-0'>
																Diagnosis Code: <b>{item.diagnosis_code}</b>
															</p>
														</div>
													</div>
												);
											})}
									</div>
								</div>

								<div className='col-12'>
									<hr />
									<div className='row px-3 justify-content-between align-items-center'>
										<h5 className='text-primary mb-3'>Charge Lines</h5>

										{chargeUUIDs && chargeUUIDs.length > 0 && (
											<div className='row mb-3'>
												<button
													className='btn text-success mr-2'
													onClick={() =>
														initChangeMultipleChargeStatus('approved')
													}
												>
													Approve Selected
												</button>
												<button
													className='btn text-danger'
													onClick={() =>
														initChangeMultipleChargeStatus('disapproved')
													}
												>
													Decline Selected
												</button>
											</div>
										)}
									</div>
								</div>

								{loading && (
									<div className='d-flex justify-content-center w-100'>
										<div className='spinner-border' role='status'>
											<span className='sr-only'>Loading...</span>
										</div>
									</div>
								)}

								{charge_lines?.length === 0 && (
									<p className='px-3'>No Charge Lines Available</p>
								)}

								{charge_lines &&
									charge_lines.length > 0 &&
									charge_lines.map((row) => {
										return (
											<div key={row.id} className='col-12'>
												<div className='card p-3 border'>
													<div className='summary-inline'>
														<label>Benefit</label>
														<span> {row.benefit?.name}</span>
													</div>

													<div className='summary-inline'>
														<label>ID</label>
														<span>{row?.id}</span>
													</div>

													<div className='summary-inline'>
														<label>Date Created</label>
														<span>{formatDate(row?.created_at)}</span>
													</div>

													<div className='summary-inline'>
														<label>Amount Charged</label>
														<span>
															<Currency value={row?.amount_charged || 0} />
														</span>
													</div>

													<div className='summary-inline'>
														<label>Units</label>
														<span>{row?.unit || 0}</span>
													</div>

													<div className='summary-inline'>
														<label>Total Charged</label>
														<span>
															<Currency value={row?.total_charged || 0} />
														</span>
													</div>

													{row?.status === 'pending' ? (
														<div className='summary-inline'>
															<div className='row p-3 gx-5 align-items-center'>
																<input
																	key={row.uuid}
																	className='form-check mr-2'
																	type='checkbox'
																	value={row.uuid}
																	onClick={onCheckCharge}
																></input>
																<button
																	className='btn btn-success btn-rounded mr-2'
																	onClick={() =>
																		initChangeChargeStatus(row.uuid, 'approved')
																	}
																>
																	{chargeStatusLoading
																		? 'Updating...'
																		: 'Approve'}
																</button>
																<button
																	className='btn btn-danger btn-rounded'
																	onClick={() =>
																		initChangeChargeStatus(
																			row.uuid,
																			'disapproved'
																		)
																	}
																>
																	{chargeStatusLoading
																		? 'Updating...'
																		: 'Decline'}
																</button>
															</div>
														</div>
													) : row?.status === 'approved' ? (
														<p
															className='text-success font-weight-bold'
															style={{ width: '100px' }}
														>
															Approved <i className='fa fa-check ml-1'></i>
														</p>
													) : (
														<p
															className='text-danger font-weight-bold'
															style={{ width: '100px' }}
														>
															Declined <i className='fa fa-times ml-1'></i>
														</p>
													)}
												</div>
											</div>
										);
									})}
							</div>

							{charge_lines && (
								<div className='mt-3 d-flex'>
									<button
										type='button'
										className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
										onClick={() => {
											setShowQuery(true);
										}}
									>
										<i className='mdi mdi-exclamation mr-1'></i>Query
									</button>

									{charge_lines?.length === 0 && (
										<div>
											<button
												type='button'
												className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
												onClick={() => {
													initApprove(uuid);
												}}
											>
												{approveLoading ? 'Approving...' : 'Approve Auth'}
											</button>

											<button
												type='button'
												className='btn btn-danger btn-rounded waves-effect waves-light mb-2 mr-2'
												disabled={declineLoading ? true : false}
												onClick={() => {
													initDecline(uuid);
												}}
											>
												<i className='mdi mdi-cancel mr-1'></i>{' '}
												{declineLoading ? 'Declining...' : 'Decline Auth'}
											</button>
										</div>
									)}

									{status === 'pending' && (
										<div>
											{!charge_lines.some(checkChargeStatusPending) && (
												<div>
													{/* {If any charge line is approved show approve button} */}
													{charge_lines.some(checkChargeStatusApproved) && (
														<button
															type='button'
															className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
															onClick={() => {
																initApprove(uuid);
															}}
														>
															{approveLoading ? 'Approving...' : 'Approve Auth'}
														</button>
													)}

													{/* {If any charge line is disapproved (and none is approved) show decline button } */}
													{charge_lines.some(checkChargeStatusDisapproved) &&
														!charge_lines.some(checkChargeStatusApproved) && (
															<button
																type='button'
																className='btn btn-danger btn-rounded waves-effect waves-light mb-2 mr-2'
																disabled={declineLoading ? true : false}
																onClick={() => {
																	initDecline(uuid);
																}}
															>
																<i className='mdi mdi-cancel mr-1'></i>{' '}
																{declineLoading
																	? 'Declining...'
																	: 'Decline Auth'}
															</button>
														)}
												</div>
											)}
										</div>
									)}
								</div>
							)}
						</Modal.Body>

						<Modal.Footer>
							<input
								type='button'
								onClick={handleCloseModal}
								className='btn btn-default'
								value='Close'
							/>
						</Modal.Footer>
						<Modal
							show={isAccept}
							dismiss
							className='modalcontent'
							style={{ position: 'relative' }}
							onHide={handleClose}
						>
							<Modal.Header closeButton>
								<Modal.Body>
									Your code <b>{message}</b> has been Generated successfully
								</Modal.Body>
							</Modal.Header>
						</Modal>
					</>
				)}
			</>
		);
}
