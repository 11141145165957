import { useEffect, useContext, useState } from 'react';
import { useQuery } from 'react-query';

import { authorizationData, claimData, referralData } from '../Services';

import AppContext from '../context';

const useNotification = ({ history, initDetails, type }) => {
	const { dispatch, errorResponse } = useContext(AppContext);

	const [notifAuth, setNotifAuth] = useState(null);
	const [notifClaim, setNotifClaim] = useState(null);
	const [notifReferral, setNotifReferral] = useState(null);

	const [notificationLoad, setNotificationLoad] = useState('');

	const path = window.location.search.substring(6, 18);
	const id = window.location.search.substring(22);

	const { loading: notifAuthLoading } = useQuery(
		['auth-detail-single', id],
		() => authorizationData(id),
		{
			retry: 2,
			manual: true,
			enabled:
				path === 'notification' && type === 'authorizations' && Boolean(id),
			onSuccess: (data) => setNotifAuth(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { loading: notifClaimLoading } = useQuery(
		['claim-detail-single', id],
		() => claimData(id),
		{
			retry: 2,
			manual: true,
			enabled: path === 'notification' && type === 'claims' && Boolean(id),
			onSuccess: (data) => setNotifClaim(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { loading: notifReferralLoading } = useQuery(
		['referral-detail-single', id],
		() => referralData(id),
		{
			retry: 2,
			manual: true,
			enabled: path === 'notification' && type === 'referrals' && Boolean(id),
			onSuccess: (data) => setNotifReferral(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useEffect(() => {
		if (
			path === 'notification' &&
			type === 'authorizations' &&
			!notifAuthLoading
		) {
			setNotificationLoad(true);

			if (notifAuth && Object.keys(notifAuth).length !== 1) {
				initDetails(notifAuth);
				setNotificationLoad(false);
			}
		}

		if (path === 'notification' && type === 'claims' && !notifClaimLoading) {
			setNotificationLoad(true);

			if (notifClaim && Object.keys(notifClaim).length !== 1) {
				initDetails(notifClaim);
				setNotificationLoad(false);
			}
		}

		if (
			path === 'notification' &&
			type === 'referrals' &&
			!notifReferralLoading
		) {
			setNotificationLoad(true);

			if (notifReferral && Object.keys(notifReferral).length !== 1) {
				initDetails(notifReferral);
				setNotificationLoad(false);
			}
		}
	}, [
		initDetails,
		notifAuth,
		notifAuthLoading,
		notifClaim,
		notifClaimLoading,
		notifReferral,
		notifReferralLoading,
		path,
		type,
	]);

	return [notificationLoad];
};

export default useNotification;
