import * as React from 'react';
import AppContext from '../../context';
import { NumFormat } from './../../components/Components';
import ReactMapGL, { Marker } from 'react-map-gl';
import Chart from 'react-apexcharts';
import eso from './enrollment-summary-options';

import {
	enrolmentSummary as enrollSummary,
	topLgas,
	totals,
	topFacilities,
	facilitiesList,
	topPackages as topPackagesList,
	topRevenue,
} from '../../Services';
import { useQuery } from 'react-query';
import moment from 'moment';

//const maa = 'pk.eyJ1Ijoicmhvbm5pcXVlIiwiYSI6ImNrbHY3cWhtYTBidWIyb213eGlmNTN1aDcifQ.nwMl-feQmu7kLo5raxO4Qg'

export default function Dashboard({ history }) {
	const {
		dispatch,
		togglePageTitle,
		mapboxApiAccessToken,
		checkArray,
		checkObject,
		setQueryString,
		errorResponse,
	} = React.useContext(AppContext);

	const [facilitiesData, setFacilitiesData] = React.useState([]);
	const [facilities, setFacilities] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);
	const [query, setQuery] = React.useState({});

	const [enrolmentSummary, setEnrolmentSummary] = React.useState(null);
	const [totalEnrollees, setTotalEnrollees] = React.useState(0);
	const [totalActiveEnrollees, setTotalActiveEnrollees] = React.useState(0);
	const [totalInactiveEnrollees, setTotalInactiveEnrollees] = React.useState(0);
	const [totalNotEnrolled, setTotalNotEnrolled] = React.useState(0);
	const [revenue, setRevenue] = React.useState(0);
	const [totalFacilities, setTotalFacilities] = React.useState(0);

	const [topPackages, setTopPackages] = React.useState(null);
	const [eSizePackages, setESizePackages] = React.useState(null);

	const [revenueByPackage, setRevenueByPackage] = React.useState(null);

	const [viewport] = React.useState({
		longitude: 6.38,
		latitude: 5.62349,
		zoom: 12,
		pitch: 60, // pitch in degrees
		bearing: -60,
		mapboxApiAccessToken,

		/* scrollZoom: false,
        touchZoom: false,
        doubleClickZoom: false,

        dragRotate: false,
        touchRotate: false,
        keyboard: false, */
		/* scrollZoom: true,
        touchZoom: true,
        doubleClickZoom: true,

        dragRotate: true,
        touchRotate: true,
        keyboard: true, */
	});

	React.useEffect(() => {
		togglePageTitle(dispatch, false);
		//refetchTotals();
		return () => {
			togglePageTitle(dispatch, true);
		};
	}, []);

	/*  React.useEffect(() => {
        console.log(facilities.filter(dt => validateLatLng(dt.lat, dt.lng))
            .map(dt => `${dt.lga} -- lng: ${dt.lng} --- lat: ${dt.lat}`), 'lat...')
    }, [facilities]); */

	/* Facilities. */
	useQuery('facilities', facilitiesList, {
		onError: (error) => setFacilities([]),
		onSuccess: (data) => setFacilities(data),
	});

	useQuery('select-facilities', topFacilities, {
		onError: (error) => setFacilitiesData([]),
		onSuccess: (data) => {
			const facdt = (data && data.length > 0 && data.splice(0, 5)) || 0;
			setFacilitiesData(facdt);
		},
	});

	/* Local Govt. */
	useQuery('select-lgas', topLgas, {
		onError: (error) => setLgas([]),
		onSuccess: (data) => {
			const lgaDt = (data && data.length > 0 && data.splice(0, 5)) || 0;
			setLgas(lgaDt);
		},
	});

	useQuery(
		['enrolment-summary', query],
		() => enrollSummary(setQueryString(query)),
		{
			retry: 0,
			onSuccess: async (data) => {
				if (checkArray(data)) {
					const summary = data.splice(0, 7);
					//let categories = summary.map(dt => moment(dt.date).format('MMM d'));
					let categories = [];
					let seriesData = [];
					await Promise.all(
						summary.map((dt) => {
							categories.push(moment(dt.date).format('MMM DD'));
							seriesData.push(dt.total);
							//console.log(moment(dt.date).format('MMM DD'), dt.total, 'enrolment-summary dates...');
						})
					);

					let options = { ...eso, xaxis: { categories } };
					let series = [
						{
							name: 'Total Enrollment',
							data: seriesData,
						},
					];

					setEnrolmentSummary({ options, series });

					//console.log(options, series, 'enrolment-summary...');
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useQuery(['enrolment-totals', query], () => totals(setQueryString(query)), {
		retry: 0,
		onSuccess: ({
			revenue,
			sub_totals,
			active_enrollees,
			inactive_enrollees,
			not_enrolled,
			facilities,
		}) => {
			console.log(sub_totals);
			setTotalEnrollees(sub_totals);
			setTotalActiveEnrollees(active_enrollees);
			setTotalInactiveEnrollees(inactive_enrollees);
			setTotalNotEnrolled(not_enrolled);
			setRevenue(revenue);
			setTotalFacilities(facilities);
		},
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	useQuery(
		['top-packages', query],
		() => topPackagesList(setQueryString(query)),
		{
			retry: 0,
			onSuccess: async (data) => {
				if (checkArray(data)) {
					//const summary = (data).splice(0, 3);
					const summary = data
						.sort((a, b) => (a.count > b.count ? -1 : 1))
						.splice(0, 3);
					//console.log(summary, 'top-packages...');
					let labels = [];
					let series = [];
					await Promise.all(
						summary.map((dt) => {
							labels.push(dt?.plan?.name);
							series.push(dt.count);
						})
					);

					let options = {
						series,
						options: {
							chart: {
								type: 'donut',
							},
							stroke: {
								show: false,
							},
							dataLabels: {
								enabled: false,
							},
							legend: {
								show: true,
								horizontalAlign: 'right',
								floating: true,
								markers: {
									width: 8,
									height: 8,
								},
							},
							plotOptions: {
								pie: {
									donut: {
										size: '85%',
										labels: {
											show: false,
										},
									},
								},
							},
							labels,
							colors: ['#4C389E', '#389E7F', '#9E3857'],
							responsive: [
								{
									breakpoint: 480,
									options: {
										chart: {
											width: 200,
										},
										legend: {
											position: 'bottom',
										},
									},
								},
							],
						},
					};

					setTopPackages(options);

					let options2 = {
						series,
						options: {
							chart: {
								type: 'donut',
							},
							stroke: {
								show: false,
							},
							dataLabels: {
								enabled: false,
							},
							legend: {
								show: true,
								horizontalAlign: 'right',
								floating: true,
								formatter: (val, opt) => {
									return `${val} - ${
										opt?.w?.globals?.series[opt?.seriesIndex]
									}`;
								},
								markers: {
									width: 8,
									height: 8,
								},
							},
							plotOptions: {
								pie: {
									donut: {
										size: '85%',
										labels: {
											show: true,
											value: {
												fontFamily: 'Poppins,sans-serif',
												fontWeight: 700,
												offsetY: 0,
											},
											total: {
												showAlways: true,
												show: true,
												label: 'TOTAL',
												color: '#000',
												fontSize: '10px',
												fontFamily: 'Poppins,sans-serif',
												fontWeight: 600,
												formatter: function (w) {
													return w.globals.seriesTotals.reduce((a, b) => {
														return a + b;
													}, 0);
												},
											},
										},
									},
								},
							},
							labels,
							colors: ['#4C389E', '#389E7F', '#9E3857'],
							responsive: [
								{
									breakpoint: 480,
									options: {
										chart: {
											width: 200,
										},
										legend: {
											position: 'bottom',
										},
									},
								},
							],
						},
					};

					setESizePackages(options2);
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useQuery(['revenue', query], () => topRevenue(setQueryString(query)), {
		retry: 0,
		onSuccess: async (data) => {
			if (checkObject(data)) {
				let summary = [];
				let labels = [];
				let series = [];

				Object.entries(data).forEach(async ([key, value]) => {
					await new Promise((resolve) => summary.push(value));
				});

				summary = summary.sort((a, b) => (a.total > b.total ? -1 : 1));

				await Promise.all(
					summary.map((dt) => {
						labels.push(dt?.plan?.name);
						series.push(dt.total);
					})
				);

				//console.log(summary, 'revenue...');

				let options = {
					series,
					options: {
						chart: {
							type: 'donut',
						},
						stroke: {
							show: false,
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							horizontalAlign: 'center',
							markers: {
								width: 8,
								height: 8,
							},
							position: 'bottom',
						},
						plotOptions: {
							pie: {
								donut: {
									size: '85%',
									labels: {
										show: true,
										value: {
											fontFamily: 'Poppins,sans-serif',
											fontWeight: 700,
											offsetY: 10,
											fontSize: '25px',
											offsetY: -1,
										},
										total: {
											showAlways: true,
											show: true,
											label: 'TOTAL REVENUE',
											color: '#000',
											fontSize: '10px',
											fontFamily: 'Poppins,sans-serif',
											fontWeight: 600,
											formatter: function (w) {
												let tt = w.globals.seriesTotals.reduce((a, b) => {
													return a + b;
												}, 0);
												tt =
													Math.abs(tt) > 999
														? Math.sign(tt) * (Math.abs(tt) / 1000).toFixed(1) +
														  'k'
														: Math.sign(tt) * Math.abs(tt);
												//tt = tt.toLocaleString('en-IN');
												return tt;
											},
										},
									},
								},
							},
						},
						labels,
						colors: ['#4C389E', '#389E7F', '#9E3857', '#8A9E38'],
						responsive: [
							{
								breakpoint: 480,
								options: {
									chart: {
										width: 200,
									},
									legend: {
										position: 'bottom',
									},
								},
							},
						],
					},
				};

				setRevenueByPackage(options);
			}
		},
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	const updateQuery = (e) => {
		//console.log(e, 'e.....')
		const { name, value } = e.target;
		//console.log(name, value, 'e.....')
		if (name === 'facility') {
			setQuery((query) => {
				if (value) {
					return { ...query, [name]: value };
				} else {
					const { facility, ...rest } = query;
					return rest;
				}
			});
		} else if (name === 'date') {
			if (value) {
				if (value === 'select') {
					console.log('select.....');
				} else {
					setDateSearch(parseInt(value));
				}
			} else {
				setQuery((query) => {
					const { start_date, end_date, ...restDate } = query;
					return restDate;
				});
			}
		}
	};

	const setDateSearch = (value) => {
		const end_date = moment().format('YYYY-MM-DD');
		let start_date;
		switch (value) {
			case 0:
			case 1:
				start_date = moment().subtract(value, 'days').format('YYYY-MM-DD');
				break;
			case 7:
				start_date = moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
				break;
			case 30:
				start_date = moment().clone().startOf('month').format('YYYY-MM-DD');
				break;
			case 90:
				start_date = moment().subtract(3, 'months').format('YYYY-MM-DD');
				break;
			case 180:
				start_date = moment().subtract(6, 'months').format('YYYY-MM-DD');
				break;
			case 12:
				start_date = moment().clone().startOf('year').format('YYYY-MM-DD');
				break;
			case 365:
				start_date = moment().subtract(12, 'months').format('YYYY-MM-DD');
				break;
			default:
				start_date = moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
		}
		//const cc = moment().subtract(3, 'months').format('YYYY-MM-DD');
		//console.log(start_date, end_date, 'setDateSearch.....');
		setQuery({ ...query, start_date, end_date });
	};

	const validateLatLng = (lat, lng) => {
		let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
		return pattern.test(lat) && pattern.test(lng);
	};

	const markers = React.useMemo(
		() =>
			facilities
				.filter((dt) => validateLatLng(dt.lat, dt.lng))
				.map((row) => (
					<Marker key={row.id} longitude={row.lng} latitude={row.lat}>
						<div className='marker-box'>
							<div>{row.enrollees_count}</div>
							<div>{row.lga}</div>
						</div>
					</Marker>
				)),
		[facilities]
	);

	return (
		<>
			<div className='pb-10 btn-tabs'>
				<button type='submit' className='btn btn-tabbed btn-sm'>
					General Dashboard
				</button>
				<button type='submit' className='btn btn-sm'>
					Drugs Dashboard
				</button>
				<button type='submit' className='btn btn-sm'>
					Facility Assessment Dashboard
				</button>
			</div>

			<div className='page-title-box d-flex align-items-center justify-content-between'>
				<h4 className='mb-0 font-size-18'>General Dashboard</h4>
				<div className='select-group-box'>
					<div className='btn-group'>
						<select
							className='custom-select custom-select-sm'
							onChange={updateQuery}
							name='date'
						>
							<option value='' selected>
								By Date
							</option>
							<option value={0}>Today</option>
							<option value={1}>Yesterday</option>
							<option value={7}>This Week</option>
							<option value={30}>This Month</option>
							<option value={12}>This Year</option>
							<option value={90}>Last 3 Months</option>
							<option value={180}>Last 6 Months</option>
							<option value={365}>Last 12 Months</option>
							{/* <option value="select">Select date</option> */}
						</select>
					</div>

					<div className='btn-group'>
						<select
							className='custom-select custom-select-sm custom-select-facilities'
							name='facility'
							onChange={updateQuery}
						>
							<option value=''>By Facility</option>
							{checkArray(facilities) &&
								facilities.map((row, index) => {
									return (
										<option key={index} value={row.id}>
											{row.name}, {row.lga}
										</option>
									);
								})}
						</select>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-sm-3'>
					<div className='dash-card dash-card-info dash-icon-5'>
						<div className='dci-title'>Total Enrollees</div>
						<div className='dci-content'>
							{<NumFormat value={totalEnrollees} />}
						</div>
					</div>
				</div>

				<div className='col-sm-3'>
					<div className='dash-card dash-card-info dash-icon-1'>
						<div className='dci-title'>Active Enrollees</div>
						<div className='dci-content'>
							{<NumFormat value={totalActiveEnrollees} />}
						</div>
					</div>
				</div>

				<div className='col-sm-3'>
					<div className='dash-card dash-card-info dash-icon-2'>
						<div className='dci-title'>Registrants</div>
						<div className='dci-content'>
							{<NumFormat value={totalNotEnrolled} />}
						</div>
					</div>
				</div>

				{/* <div className='col-sm-6'>
					<div className='dash-card dash-card-info dash-icon-3'>
						<div className='dci-title'>Total Revenue</div>
						<div className='dci-content'>
							<Currency value={revenue} />
						</div>
					</div>
				</div> */}

				<div className='col-sm-3'>
					<div className='dash-card dash-card-info dash-icon-4'>
						<div className='dci-title'>Total No. of Facility</div>
						<div className='dci-content'>
							<NumFormat value={totalFacilities} />
						</div>
					</div>
				</div>

				<div className='col-sm-8'>
					<div className='dash-card dash-card-map'>
						<h6>Facilities Map</h6>
						<ReactMapGL
							scrollZoom={false}
							touchZoom={false}
							doubleClickZoom={false}
							touchZoomRotate={false}
							{...viewport}
							width='100%'
							height='100%'
						>
							{markers}
						</ReactMapGL>
					</div>
				</div>

				<div className='col-sm-4 dash-card-row'>
					<div className='dash-card chart-donut-right'>
						<h6>Top Packages </h6>
						{topPackages && (
							<Chart
								options={topPackages.options}
								series={topPackages.series}
								type='donut'
								//width={`100%`}
								height={150}
							/>
						)}
					</div>
					<div className='dash-card chart-donut-right'>
						<h6>Enrollee size by Packages</h6>
						{eSizePackages && (
							<Chart
								options={eSizePackages.options}
								series={eSizePackages.series}
								type='donut'
								height={150}
							/>
						)}
					</div>
				</div>

				<div className='col-sm-8'>
					<div className='dash-card dash-card-bar'>
						<div className='flex-space'>
							<h6>Average Weekly Enrollment </h6>
						</div>

						{enrolmentSummary ? (
							<Chart
								options={enrolmentSummary.options}
								series={enrolmentSummary.series}
								type='bar'
								width='100%'
								height={330}
							/>
						) : (
							<div className='no-data-flex'>loading...</div>
						)}
					</div>
				</div>

				{/* <div className='col-sm-4'>
					<div className='dash-card dash-card-revenue'>
						<div className='flex-space mb-3'>
							<h6 className='mb-0'>Revenue</h6>
							<div className='btn-group'>
								<button
									className='btn btn-sm btn-default dropdown-toggle'
									type='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									This Month <i className='mdi mdi-chevron-down'></i>
								</button>
								<div className='dropdown-menu dropdown-menu-right'>
									<a className='dropdown-item' href='#'>
										Per Week
									</a>
								</div>
							</div>
						</div>
						{revenueByPackage && (
							<Chart
								options={revenueByPackage.options}
								series={revenueByPackage.series}
								type='donut'
								height={280}
							/>
						)}
					</div>
				</div> */}

				<div className='col-sm-6'>
					<div className='dash-card'>
						<h6>Top Facilities</h6>

						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							<thead>
								<tr>
									<th width='60%'>FACILITY NAME</th>
									<th width='40%' className='text-right'>
										ENROLLEES COUNT
									</th>
								</tr>
							</thead>
							<tbody>
								{facilitiesData &&
									facilitiesData.length > 0 &&
									facilitiesData.map((row, index) => {
										return (
											<tr key={row.id}>
												<td scope='row'>
													{row.name}, {row.lga}
												</td>
												<td scope='row' className='text-right'>
													<NumFormat value={row.enrollees_count} />
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>

				<div className='col-sm-6'>
					<div className='dash-card'>
						<h6>Top LGA’s</h6>

						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							<thead>
								<tr>
									<th width='20%'>S/N</th>
									<th width='70%'>LGA</th>
									<th width='10%'>Enrollees</th>
								</tr>
							</thead>
							<tbody>
								{lgas &&
									lgas.length > 0 &&
									lgas.map((row, index) => {
										return (
											<tr key={index}>
												<td scope='row'>{index + 1}</td>
												<td scope='row'>{row.lga}</td>
												<td scope='row'>{row.total}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
