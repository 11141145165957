import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import AppContext from '../context';

// import { fetchAuthCodeComments, createAuthCodeComments } from '../Services';

export const QueryBox = ({
	// auth_uuid,
	// uuid,
	id,
	commentQ,
	setCommentQ,
	showQuery,
	setShowQuery,
	fetchFunction,
	createFunction,
	preset,
}) => {
	const history = useHistory();

	const { notify, dispatch, errorResponse } = useContext(AppContext);

	const queryClient = useQueryClient();

	const { data: comments, isLoading } = useQuery(
		['fetchComments', id],
		() => fetchFunction(id),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const { mutate: handleSubmitQuery, isLoading: submitQueryLoading } =
		useMutation(
			(
				id,
				data = {
					comments: [
						{
							comment: commentQ,
						},
					],
				}
			) => createFunction(id, data),
			{
				onSuccess: () => {
					notify('success', 'Query Submitted', 'Query successfully submitted!');
					setCommentQ('');
				},
				onError: (error) => errorResponse({ error, dispatch }),
				onSettled: () => queryClient.refetchQueries(['fetchComments', id]),
			}
		);

	const presetMessages = [
		'Declined based on Diagnoses',
		'Declined based on wrong Prescription',
		'Check the Dosage',
		'It is Capitated',
	];

	if (isLoading) return <h4 className='text-center p-5'>Loading...</h4>;

	return (
		<Modal.Body>
			<div className='row'>
				<div className='col-12 text right'>
					<div className='card'>
						<div className='h3 m-0'>Query/Comments</div>
						<div className='card-body'>
							<div className='my-3'>
								{comments?.data.map((comment) => (
									<div key={comment.id} className='row'>
										<div className='col-9'>
											<p className='m-0 my-1' style={{ fontWeight: 'bold' }}>
												{comment.author !== null ? (
													<span className='fw-bold text-success'>{`${comment.author?.first_name} ${comment.author?.last_name}: `}</span>
												) : comment.hospital_name !== null ? (
													<span className='fw-bold text-warning'>{`${comment.hospital_name}: `}</span>
												) : (
													<span className='fw-bold text-primary'>Unknown</span>
												)}
												<span>{comment.comment}</span>
											</p>
										</div>
										<div className='col-3'>
											<p className='text-muted m-0'>
												{moment(comment.created_at).format('DD MMM YYYY')}
											</p>
										</div>
									</div>
								))}
							</div>

							<div className='form-group col-12'>
								<h6 className='text-muted mb-3'>Query</h6>

								<textarea
									className='form-control'
									name='query'
									cols='30'
									rows='5'
									placeholder='Your query here'
									value={commentQ}
									onChange={(e) => setCommentQ(e.target.value)}
								></textarea>

								{preset && (
									<div className='py-3'>
										<h5>Preset Responses</h5>

										<div className='row px-2'>
											{presetMessages.map((message) => (
												<button
													key={message}
													className='btn btn-outline-info m-1'
													onClick={() => setCommentQ(message)}
												>
													{message}
												</button>
											))}
										</div>
									</div>
								)}

								<div
									className='d-flex flex-row float-right'
									style={{ gap: '10px' }}
								>
									<button
										className='btn mt-3'
										onClick={() => setShowQuery(!showQuery)}
										style={{
											background: '#ccc',
											color: '#fff',
										}}
									>
										Back
									</button>

									<button
										className='btn mt-3'
										onClick={() => handleSubmitQuery(id)}
										style={{
											background: '#389e7f',
											color: '#fff',
										}}
									>
										{submitQueryLoading ? (
											<span
												className='spinner-border spinner-border-sm text-white'
												role='status'
											></span>
										) : (
											'Submit Query'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal.Body>
	);
};
