import * as React from 'react';
import AppContext from '../../../context';
import {
	notEnrolledEnrollees,
	enrolleeData,
	enrolleeDeactivate,
	packagesList as selectPackages,
	selectLgas,
	mdasList,
	enrolleeDelete,
	facilitiesList,
	initiateFacilityChange,
	totals,
} from '../../../Services';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Paginate, Currency } from './../../../components/Components';
import useModal from './../../../hooks/useModal';
import Details from '../details';
import PlanDetails from '../planDetails';
import ModalSearch from '../search';
import useRouter from './../../../hooks/useRouter';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import 'react-datepicker/dist/react-datepicker.css';

const initValues = {
	services: [],
	contact: {},
};

export default function NotEnrolledEnrollees({ history }) {
	const queryClient = useQueryClient();
	const {
		dispatch,
		setQueryString,
		useQueryString,
		currentPath,
		formatDateBr,
		notify,
		errorResponse,
	} = React.useContext(AppContext);

	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);

	const [enrolleeDetails, setEnrolleeDetails] = React.useState({});
	const searchRef = React.useRef();

	const [queryName] = React.useState('not-enrolled-enrollees');
	const [formID] = React.useState('form-enrollee');
	const { modalState, setModalState, modalTitle, setModalTitle } = useModal();
	const {
		modalState: planModalState,
		setModalState: setPlanModalState,
		modalTitle: planModalTitle,
		setModalTitle: setPlanModalTitle,
	} = useModal();
	const {
		modalState: facilityChangeModalState,
		setModalState: setFacilityChangeModalState,
		modalTitle: facilityChangeModalTitle,
		setModalTitle: setFacilityChangeModalTitle,
	} = useModal();

	const {
		modalState: msState,
		modalTitle: msTitle,
		setModalTitle: setMsTitle,
		closeModal,
		showModal,
	} = useModal();

	const router = useRouter();
	const [query, setQuery] = React.useState(router.query);
	const [searchValues, setSearchValues] = React.useState({
		start_date: new Date(),
	});
	const [clearSearch, setClearSearch] = React.useState(false);
	const [meta, setMeta] = React.useState({});
	const [searchType, setSearchType] = React.useState(1);

	const [packages, setPackages] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);
	const [mdas, setMdas] = React.useState([]);
	const [facilities, setFacilities] = React.useState([]);
	const [newFacility, setNewFacility] = React.useState('');
	const [comment, setComment] = React.useState('');

	const { q, gender, religion, start_date, end_date, page } = router.query;

	/* export query */
	const [queryExport, setQueryExport] = React.useState({});
	const [exportData, setExportData] = React.useState([]);
	const [exporting, setExporting] = React.useState(false);
	const [exportReady, setExportReady] = React.useState(false);
	const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);

	React.useEffect(() => {
		const rQquery = router.query;
		setQuery(rQquery);
		let sss = rQquery;
		sss = {
			...sss,
			start_date: start_date ? moment(start_date).toDate() : '',
			end_date: end_date ? moment(end_date).toDate() : '',
		};
		setSearchValues(sss);

		const clearSearchState = Object.keys(rQquery).length > 0 ? true : false;
		setClearSearch(clearSearchState);

		/* Clean up */
		return () => setQuery({});
	}, [end_date, router.query, start_date]);

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => notEnrolledEnrollees(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	// Totals
	const { data: totalsData, isLoading: totalsLoading } = useQuery(
		'total-count',
		() => totals(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Export data */
	useQuery(
		['export-not-enrolled-enrollees', queryExport],
		() => notEnrolledEnrollees(setQueryString(queryExport)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQueryExport({ ...queryExport, page: queryExport.page + 1 });
				} else {
					setExporting(false);
					setExportReady(true);
					setQueryExport({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Packages */
	useQuery('select-packages', selectPackages, {
		onError: (error) => setPackages([]),
		onSuccess: (data) => setPackages(data),
	});

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas([]),
		onSuccess: (data) => setLgas(data),
	});

	/* MDAS. */
	useQuery('select-mdas', mdasList, {
		onError: (error) => setMdas([]),
		onSuccess: (data) => setMdas(data),
	});

	/* Facilities. */
	useQuery('select-facilities', facilitiesList, {
		onError: (error) => setFacilities([]),
		onSuccess: (data) => setFacilities(data),
	});

	const handleCloseModal = () => {
		setModalState(false);
		setDefaultValues(initValues);
	};

	const handlePlanCloseModal = () => {
		setPlanModalState(false);
		setDefaultValues(initValues);
	};

	const handleFacilityChangeCloseModal = () => {
		setFacilityChangeModalState(false);
		setDefaultValues(initValues);
	};

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data && data.total > 0) {
			const newMeta = { from: data?.from, to: data?.to, total: data?.total };
			setMeta(newMeta);
		}

		if (data?.next_page_url) {
			let nextPage = {
				...query,
				page: query?.page ? Number(query.page) + 1 : 2,
			};

			queryClient.prefetchQuery([queryName, nextPage], () =>
				notEnrolledEnrollees(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { mutate: handleSubmitDelete } = useMutation(
		(id) => enrolleeDelete(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Enrollee Deleted',
					'Enrollee data successfully deleted!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const { mutate: handleDeactivate } = useMutation(
		(id) => enrolleeDeactivate(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Enrollee Deactivated',
					'Enrollee successfully deactivated!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const { mutate: handleFacilityChange } = useMutation(
		(values) => initiateFacilityChange(values),
		{
			onSuccess: () => {
				notify(
					'success',
					'Facility Change Requested',
					'Facility Change successfully Requested!'
				);

				setNewFacility('');
				setComment('');
				setFacilityChangeModalState(false);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const initDelete = (id) => {
		const conf = window.confirm(
			'This will delete all enrollee data. Are you sure?'
		);
		if (!conf) return;
		handleSubmitDelete(id);
	};

	const initDetails = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);

		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;

		setEnrolleeDetails(row);
		setModalTitle(title);
		setModalState(true);
	};

	const initEdit = (data) => {
		history.push('/enrollee/create', { continueData: data, editMode: false });
	};

	const initEditPlan = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);

		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;

		setEnrolleeDetails(row);
		setPlanModalTitle(title);
		setPlanModalState(true);
		//setPageTitle(dispatch, 'Edit Enrollee');
	};

	const initFacilityChange = (row) => {
		const title = `Change Facility - ${row.insurance_id}`;

		setEnrolleeDetails(row);
		setFacilityChangeModalTitle(title);
		setFacilityChangeModalState(true);
	};

	const initAddDependant = async (data) => {
		const { facilities } = await enrolleeData(data?.id);
		history.push('/enrollee/add-dependant', {
			enrolleeData: {
				...data,
				facilities:
					(facilities &&
						facilities.length > 0 &&
						facilities.map((dt) => ({ label: dt.name, value: dt.id }))) ||
					[],
			},
		});
	};

	const handleStatusDeactivate = (id) => {
		handleDeactivate(id);
	};

	const handleChangeFacility = (newFacility) => {
		const data = {
			comment: comment,
			enrollee_uuid: enrolleeDetails.uuid,
			from_uuid:
				enrolleeDetails.facilities && enrolleeDetails.facilities[0].uuid,
			to_uuid: newFacility,
		};

		handleFacilityChange(data);
	};

	const initSearch = (type) => {
		setSearchType(type);
		showModal();
		const title = type === 2 ? 'Export to Excel' : 'Search..';
		setMsTitle(title);
	};

	const onSearchSubmit = (values) => {
		history.push(`${currentPath}${setQueryString(values)}`);
		closeModal();
	};

	const onExportSubmit = (values) => {
		setToggleCloseBtn(false);
		setExportData([]);
		setQueryExport({ ...values, page: 1 });
		setExporting(true);
	};

	const resetExport = () => {
		closeModal();
		setToggleCloseBtn(true);
		setExportData([]);
		setQueryExport({});
		setExporting(false);
		setExportReady(false);
		history.push(currentPath);
	};

	const facilityOptions = facilities?.map((facility) => ({
		name: facility.name,
		value: facility.uuid,
	}));

	if (isLoading) {
		return <div>loading...</div>;
	}

	return (
		<>
			{!isLoading && !totalsLoading && error && (
				<div>error: {error.message}...</div>
			)}

			{data && data?.data && (
				<div className='row'>
					<div className='col-12'>
						{totalsData && (
							<div className='pb-10 btn-tabs' style={{ display: 'flex' }}>
								<div className='col-sm-4'>
									<div
										className='dash-card dash-card-info dash-icon-4'
										style={{ alignItems: 'baseline' }}
									>
										<div className='dci-title'>
											Total Enrollees
											<div className='dci-content'>
												{totalsData?.sub_totals.toLocaleString()}
											</div>
										</div>
									</div>
								</div>

								<div className='col-sm-4'>
									<div
										className='dash-card dash-card-info dash-icon-2'
										style={{ alignItems: 'baseline' }}
									>
										<div className='dci-title'>
											Registrants
											<div className='dci-content'>
												{totalsData?.not_enrolled.toLocaleString()}
											</div>
										</div>
									</div>
								</div>

								{meta &&
									Object.keys(meta).length !== 0 &&
									meta.total !== totalsData.not_enrolled && (
										<div className='col-sm-4'>
											<div
												className='dash-card dash-card-info dash-icon-5'
												style={{ alignItems: 'baseline' }}
											>
												<div className='dci-title'>
													Search Results
													<div className='dci-content'>
														{meta && meta.total.toLocaleString()}
													</div>
												</div>
											</div>
										</div>
									)}
							</div>
						)}

						<div className='card'>
							<div className='card-body'>
								<div className='page-action-bar'>
									<div>
										<div
											className='btn-group mr-2'
											role='group'
											aria-label='Basic example'
										>
											<button
												type='button'
												onClick={() => initSearch(1)}
												className='btn btn-success btn-rounded waves-effect waves-light'
											>
												<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
												Search
											</button>
											{clearSearch && (
												/* (page && Object.keys(router.query).length !== 1) && */ <button
													type='button'
													onClick={() => history.push(currentPath)}
													className='btn btn-success btn-rounded waves-effect waves-light'
												>
													Clear{' '}
													<i className='bx bx-x search-icon font-size-16 align-middle'></i>
												</button>
											)}
										</div>

										{/* <button
											type='button'
											onClick={() => initSearch(2)}
											className='btn btn-secondary btn-rounded waves-effect waves-light'
										>
											<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
											Export to Excel
										</button> */}
									</div>
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered table-nowrap  table-striped'>
											<thead>
												<tr>
													<th width='35%'>Info</th>
													<th width='19%'>Contact</th>
													<th width='25%'>Plan</th>
													<th width='15%' className='text-right'>
														Created At
													</th>
													<th width='1%' className='text-center'>
														<i className='mdi mdi-dots-horizontal' />
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>
																<div className=' font-weight-bold'>
																	{row.first_name} {row.middle_name}{' '}
																	{row.last_name}
																</div>
																{row.gender} | {row.occupation}
																{row?.source === 'equity' && (
																	<>
																		<br />
																		<span className='badge badge-info'>
																			Equity
																		</span>
																	</>
																)}
															</td>
															<td>
																{row.lga}
																<br />
																{row.phone}
																{row.email && (
																	<>
																		<br />
																		{row.email}
																	</>
																)}
															</td>
															<td>
																{row?.benefactor_id != null ? (
																	<strong className='font-size-12 text-muted'>
																		Dependant
																	</strong>
																) : row?.package ? (
																	<>
																		<div className='font-size-13 font-weight-medium'>
																			{row?.package?.name}
																		</div>
																		<Currency
																			value={
																				row?.package?.is_family_allowed === 1
																					? row?.package?.family_amount
																					: row?.package?.amount
																			}
																		/>
																	</>
																) : (
																	<strong className='font-size-12 text-muted'>
																		None
																	</strong>
																)}
															</td>
															<td className='text-right'>
																{formatDateBr(row.created_at)}
															</td>

															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDetails(row)}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					initEdit(row);
																				}}
																				className='dropdown-item'
																			>
																				Continue Enrollment
																			</a>
																		</li>

																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDelete(row.id)}
																				className='dropdown-item'
																			>
																				Delete Enrollee
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					handleStatusDeactivate(row.id)
																				}
																				className='dropdown-item'
																			>
																				Deactivate Enrollee
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										<div className='row'>
											<div className='col-sm-12 col-md-5'>
												Showing {meta?.from} - {meta?.to} of {meta?.total}{' '}
												Results
											</div>
											<div className='col-sm-12 col-md-7'>
												<Paginate data={data} onPageChange={handlePageClick} />
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Details
					handleCloseModal={handleCloseModal}
					enrolleeDetails={enrolleeDetails}
				/>
			</Modal>

			<Modal
				show={planModalState}
				onHide={handlePlanCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{planModalTitle}</Modal.Title>
				</Modal.Header>

				<PlanDetails
					handlePlanCloseModal={handlePlanCloseModal}
					enrolleeDetails={enrolleeDetails}
					queryName={queryName}
					query={query}
				/>
			</Modal>

			<Modal
				show={facilityChangeModalState}
				onHide={handleFacilityChangeCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{facilityChangeModalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className='col-12'>
						<h3>Current Facility</h3>
						<p>
							<b>
								{enrolleeDetails.facilities &&
									enrolleeDetails.facilities[0].name}
							</b>
						</p>
					</div>
					<div className='col-md-6 my-4'>
						<div className='form-group'>
							<label>New Facility</label>
							<SelectSearch
								search
								filterOptions={fuzzySearch}
								options={facilityOptions}
								value={newFacility}
								name='facility'
								placeholder='Select Facility'
								onChange={(value) => setNewFacility(value)}
							/>
						</div>
					</div>
					<div className='col-12'>
						<label>Comment</label>
						<textarea
							className='form-control my-3'
							name='comment'
							cols='30'
							rows='5'
							placeholder='Your comment here'
							onChange={(e) => setComment(e.target.value)}
						></textarea>
					</div>
					<div className='col-md-6'>
						<button
							className='btn btn-primary w-75'
							onClick={() => handleChangeFacility(newFacility)}
						>
							Submit
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={msState}
				onHide={closeModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				//size="sm"
			>
				<Modal.Header closeButton={toggleCloseBtn}>
					<Modal.Title>{msTitle}</Modal.Title>
				</Modal.Header>

				<ModalSearch
					searchValues={searchValues}
					setSearchValues={setSearchValues}
					closeModal={closeModal}
					onSearchSubmit={onSearchSubmit}
					onExportSubmit={onExportSubmit}
					exportData={exportData}
					exporting={exporting}
					exportReady={exportReady}
					resetExport={resetExport}
					lgas={lgas}
					packages={packages}
					searchType={searchType}
					mdas={mdas}
					facilities={facilities}
				/>
			</Modal>
		</>
	);
}
