import * as React from 'react';
import AppContext from '../../../context';
import { approvedFacilityChangeHistory } from '../../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../../hooks/useModal';
import useFormState from '../../..//hooks/useFormState';
import { Paginate } from '../../../components/Components';
import moment from 'moment';

export default function ApprovedFacilityChanges({ history, setQuery, query }) {
	const [currentPage, setCurrentPage] = React.useState('approved-history');
	const queryClient = useQueryClient();
	const [queryName] = React.useState('approved-history');
	const [formID] = React.useState('form-agents');
	const [modalType, setModalType] = React.useState('details');
	const [filterData, setFilterData] = React.useState({
		start_date: moment(new Date()).format('yyyy-MM-DD'),
		end_date: moment(new Date()).format('yyyy-MM-DD'),
		facility_name: '',
		benefit_category: '',
	});
	const [showApprove, hideApprove] = React.useState(false);
	const { modalState, closeModal, showModal } = useModal();
	const { toggleFormState } = useFormState(formID);

	const {
		dispatch,
		setQueryString,
		useQueryString,
		currentPath,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);

	const [changeDetails, setChangeDetails] = React.useState(null);

	const handleCloseModal = () => {
		closeModal();
		setChangeDetails(null);
	};

	const {
		data: historyData,
		isLoading: historyLoading,
		isError: historyError,
		refetch: Refetch,
	} = useQuery(
		[queryName, query],
		() => approvedFacilityChangeHistory(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	React.useEffect(() => {
		if (historyData?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['approved-history', nextPage], () =>
				approvedFacilityChangeHistory(setQueryString(nextPage))
			);
		}
	}, [historyData, query, queryClient]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		setModalType('details');
		setChangeDetails(row);
		showModal();
	};

	return (
		<>
			{historyLoading && <div>loading...</div>}
			{!historyLoading && historyError && (
				<div>error: {historyError.message}...</div>
			)}

			{historyData && historyData?.data?.length > 0 && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-dark'>Approved Changes</h3>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='30%'>Enrollee</th>
												<th width='30%'>ID</th>
												<th width='15%'>From</th>
												<th width='15%'>To</th>
												<th width='15%'>Comment</th>
												<th width='14%'>Status</th>
												<th width='10%'>Date</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{historyData?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.enrollee?.insurance_id}</td>
														<td>{row?.from?.name}</td>
														<td>{row?.to?.name}</td>
														<td>{row?.comment}</td>
														<td className='text-success'>{row?.status}</td>
														<td>{formatDate(row?.created_at)}</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={historyData} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Facility Change Request Details</Modal.Title>
					</Modal.Header>

					<Details
						handleCloseModal={handleCloseModal}
						changeDetails={changeDetails}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						history={history}
					/>
				</Modal>
			)}
		</>
	);
}
