import * as React from 'react';
import AppContext from '../../../context';
import { Modal } from 'react-bootstrap';
import useModal from './../../../hooks/useModal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMsg } from './../../../components/Components';
import Select from 'react-select';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Webcam from 'react-webcam';
import PhotoSelector from '../../../components/PhotoSelector';

const initValues = {
	state: 'Edo State',
	facilities: [],
	dob: '',
	business_name: '',
};

export default function RegisterData({
	currentIndex,
	submitEnrollement,
	occupations,
	facilitiesData,
	values,
	mdas,
	lgas,
	enrolleeImage,
	setEnrolleeImage,
	processImage,
	blobPhoto,
	setBlobPhoto,
	photoMode,
	setPhotoMode,
	editMode,
	corporates,
}) {
	const {
		dataURItoBlob,
		validateEmail,
		religions,
		marital_status,
		educational_status,
		special_needs,
	} = React.useContext(AppContext);

	const [webCamError, setWebCamError] = React.useState(null);
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const [facilities, setFacilities] = React.useState([]);
	const [selectedLga, setSelectedLga] = React.useState(values?.lga);
	const [mdaNotFound, setMdaNotFound] = React.useState(false);

	// Check for Invalid Date object and replace value if necessary
	const newValues =
		values && values?.dob instanceof Date && !isNaN(values?.dob) === false
			? { ...values, dob: '' }
			: { ...values };

	const customResolver = async (values) => {
		const emojiRegex =
			/(?:[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1F004}-\u{1F0CF}\u{1F18E}-\u{1F251}])+/gu;

		const errors = {};

		for (const inputName in values) {
			if (emojiRegex.test(values[inputName])) {
				errors[inputName] = {
					type: 'manual',
					message: 'Your input contains invalid characters.',
					ref: {},
					types: {
						manual: 'Your input contains invalid characters.',
					},
				};
			}
		}

		return {
			values,
			errors,
		};
	};

	const {
		register,
		errors,
		handleSubmit,
		getValues,
		control,
		watch,
		setValue,
	} = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		defaultValues: values ? newValues : initValues,
		resolver: customResolver,
	});

	if (getValues('occupation') === '') {
		setValue('occupation', newValues.occupation);
	}

	const webcamRef = React.useRef(null);
	const photoInputRef = React.useRef(null);

	React.useEffect(() => {
		if (selectedLga && facilitiesData) {
			const filterFacility = facilitiesData.filter(
				(dt) => dt.lga === selectedLga
			);
			setFacilities(filterFacility);
		}
	}, [selectedLga, facilitiesData, values]);

	const capture = React.useCallback(() => {
		try {
			const imageSrc = webcamRef.current.getScreenshot();
			setEnrolleeImage(imageSrc);
			const blob = dataURItoBlob(imageSrc);
			setBlobPhoto(blob);
			handleCloseModal();
		} catch (error) {
			//console.log(error, 'capture error...')
		}
	}, [webcamRef]);

	const selectImage = () => {
		setPhotoMode(2);
		setTimeout(() => document.getElementById('image-input').click(), 100);
	};

	const initCaptureImage = () => {
		setModalTitle('Capture Enrollee Image');
		setPhotoMode(1);
		setModalState(true);
	};

	const handleCloseModal = () => {
		setModalState(false);
	};

	const allMDAs =
		mdas && mdas.length
			? [{ name: '-- MDA --', value: '' }].concat(
					mdas.map((row, index) => {
						return { name: row, value: row };
					})
			  )
			: [];

	// function handleMDAChange(value) {
	// 	if (values) {
	// 		setValue('business_name', value);
	// 		// values.business_name = value;
	// 	} else {
	// 		initValues.business_name = value;
	// 	}
	// }

	const validateFacility = () =>
		getValues('facilities').length ? true : 'Select at least one facility';

	return (
		<>
			{currentIndex === 1 && (
				<>
					<h3 className='text-dark mb-3'>Enrollee Registration</h3>
					<h5 className='text-muted mb-2'>Personal Information</h5>

					<form
						id='form-register-data'
						onSubmit={handleSubmit(submitEnrollement)}
					>
						<PhotoSelector
							photoMode={photoMode}
							blobPhoto={blobPhoto}
							register={register}
							ErrorMsg={ErrorMsg}
							errors={errors}
							processImage={processImage}
							photoInputRef={photoInputRef}
							enrolleeImage={enrolleeImage}
							initCaptureImage={initCaptureImage}
							selectImage={selectImage}
							picture={values?.picture || ''}
						/>

						<div className='row register-data'>
							<div className='form-group col-12'>
								<label>Is this a corporate enrollee?</label>
								<select
									name='corporate_id'
									className='form-control'
									ref={register}
								>
									<option value=''>Select</option>
									{corporates && corporates?.length > 0
										? corporates.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
										  ))
										: ''}
								</select>
							</div>

							<div className='form-group col-md-4'>
								<label>
									First Name <span className='text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='first_name'
									placeholder='First Name'
									ref={register({
										required: 'First name is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='first_name' />
							</div>
							<div className='form-group col-md-4'>
								<label>Middle Name</label>
								<input
									type='text'
									className='form-control'
									name='middle_name'
									placeholder='Middle Name'
									ref={register}
								/>
							</div>
							<div className='form-group col-md-4'>
								<label>
									Surname <span className='text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='last_name'
									placeholder='Surname'
									ref={register({
										required: 'Surname is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='last_name' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									Gender <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='gender'
									ref={register({
										required: 'Gender is required!',
									})}
								>
									<option value=''>- Select -</option>
									<option value='Female'>Female</option>
									<option value='Male'>Male</option>
								</select>
								<ErrorMsg errors={errors} name='gender' />
							</div>
							<div className='form-group col-md-6 form-date-picker'>
								<label>
									Date of Birth <span className='text-danger'>*</span>
								</label>
								<Controller
									name='dob'
									control={control}
									rules={{ required: 'Date of birth is required!' }}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											control={control}
											onChange={onChange}
											className='form-control'
											name='dob'
											placeholderText='Select birth date'
											dateFormat='MM/dd/yyyy'
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='dob' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									Religion <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='religion'
									ref={register({
										required: 'Religion is required!',
									})}
								>
									<option value=''>- Select -</option>
									{religions &&
										religions.length > 0 &&
										religions.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='religion' />
							</div>
							<div className='form-group col-md-6'>
								<label>
									Occupation <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='occupation'
									ref={register({
										required: 'Occupation is required!',
									})}
								>
									<option value=''>- Select -</option>
									{occupations &&
										occupations.length > 0 &&
										occupations.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='occupation' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									Marital Status <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='marital_status'
									ref={register({
										required: 'Marital Status is required!',
									})}
								>
									<option value=''>- Select -</option>
									{marital_status &&
										marital_status.length > 0 &&
										marital_status.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='marital_status' />
							</div>

							<div className='form-group col-md-6'>
								<label>Educational Status</label>
								<select
									className='form-control'
									name='educational_status'
									ref={register}
								>
									<option value=''>- Select -</option>
									{educational_status &&
										educational_status.length > 0 &&
										educational_status.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<label>Special Needs</label>
								<select
									className='form-control'
									name='special_needs'
									ref={register}
								>
									<option value=''>- Select -</option>
									{special_needs &&
										special_needs.length > 0 &&
										special_needs.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<label>National ID Number (NIN)</label>
								<input
									type='number'
									className='form-control'
									name='nin'
									placeholder='National ID Number'
									ref={register}
								/>
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Contact Information
							</h5>

							<div className='form-group col-md-6'>
								<label>
									Residential Address <span className='text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='address'
									placeholder='Residential Address'
									ref={register({
										required: 'Residential address is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='address' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									LGA of residence <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='lga'
									onChange={(e) => setSelectedLga(e.currentTarget.value)}
									value={selectedLga}
									ref={register({
										required: 'Local Govt. is required!',
									})}
								>
									<option value=''> - Select -</option>
									{lgas &&
										lgas.length > 0 &&
										lgas.map((row, index) => {
											return (
												<option key={row.code} value={row.name}>
													{row.name}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='lga' />
							</div>

							{/* <div className="form-group col-md-2">
                        <label>State</label>
                        <select className="form-control"
                            name="state"
                            ref={register({
                                required: "State is required!"
                            })}>
                            <option value="Edo State">Edo State</option>
                        </select>
                        <ErrorMsg errors={errors} name="state" />
                    </div> */}

							<div className='form-group col-md-6'>
								<label>Ward</label>
								<input
									type='text'
									className='form-control'
									name='ward'
									placeholder='Ward'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>Community</label>
								<input
									type='text'
									className='form-control'
									name='community'
									placeholder='Community'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>
									Phone Number <span className='text-danger'>*</span>
								</label>
								<input
									type='number'
									className='form-control'
									name='phone'
									placeholder='Phone Number'
									ref={register({
										required: 'Phone number is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='phone' />
							</div>

							<div className='form-group col-md-6'>
								<label>Email Address</label>
								<input
									type='text'
									className='form-control'
									name='email'
									placeholder='Email Address'
									ref={register({
										validate: () => validateEmail(getValues('email'), true),
									})}
								/>
								<ErrorMsg errors={errors} name='email' />
							</div>

							<>
								<h5 className='text-muted py-2 pt-3 col-12'>
									Office Information
								</h5>

								<div className='form-group col-12'>
									<div className='form-check form-check-inline'>
										<label
											className='form-check-label text-info'
											htmlFor='inlineCheckbox1'
										>
											If MDA is not listed in the drop-down, check this box to
											enter it manually
										</label>

										<input
											className='form-check-input ml-2'
											style={{
												width: '20px',
												height: '20px',
											}}
											type='checkbox'
											name='mdaNotFound'
											checked={mdaNotFound}
											onChange={() => setMdaNotFound(!mdaNotFound)}
										/>
									</div>
								</div>

								{!mdaNotFound && (
									<div className='form-group col-12'>
										<label>MDA/Business Name</label>

										<Controller
											name='business_name'
											control={control}
											render={({ onChange, value }) => (
												<SelectSearch
													search
													filterOptions={fuzzySearch}
													options={allMDAs}
													value={value}
													onChange={onChange}
													placeholder='Business Name'
												/>
											)}
										/>

										<ErrorMsg errors={errors} name='business_name' />
									</div>
								)}

								{mdaNotFound && (
									<div className='form-group col-12'>
										<label>MDA/Business Name</label>

										<input
											type='text'
											className='form-control'
											name='business_name_custom'
											placeholder='MDA/Business Name'
											ref={register}
										/>
										<ErrorMsg errors={errors} name='business_name' />
									</div>
								)}

								<div className='form-group col-md-8'>
									<label>
										Office Address <span className='text-danger'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='office_address'
										placeholder='Office Address'
										ref={register({
											required: 'Office Address is Required',
										})}
									/>
									<ErrorMsg errors={errors} name='office_address' />
								</div>

								<div className='form-group col-md-4'>
									<label>
										Office LGA <span className='text-danger'>*</span>
									</label>
									<select
										className='form-control'
										name='office_lga'
										ref={register({
											validate: () =>
												getValues('office_lga').length
													? true
													: 'Office LGA is Required',
										})}
									>
										<option value=''> - Select -</option>
										{lgas &&
											lgas.length > 0 &&
											lgas.map((row, index) => {
												return (
													<option key={row.code} value={row.name}>
														{row.name}
													</option>
												);
											})}
									</select>
									<ErrorMsg errors={errors} name='office_lga' />
								</div>
							</>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Medical Information
							</h5>

							<div className='form-group col-md-4'>
								<label>Genotype</label>
								<select className='form-control' ref={register} name='genotype'>
									<option value=''>- Select -</option>
									<option value='AA'>AA</option>
									<option value='AS'>AS</option>
									<option value='SS'>SS</option>
									<option value='AC'>AC</option>
									<option value='SC'>SC</option>
								</select>
							</div>
							<div className='form-group col-md-4'>
								<label>Blood Group</label>
								<select
									className='form-control'
									ref={register}
									name='blood_group'
								>
									<option value=''>- Select -</option>
									<option value='A+'>A+</option>
									<option value='A-'>A-</option>
									<option value='B+'>B+</option>
									<option value='B-'>B-</option>
									<option value='AB+'>AB+</option>
									<option value='AB-'>AB-</option>
									<option value='O+'>O+</option>
									<option value='O-'>O-</option>
								</select>
							</div>
							<div className='form-group col-md-4'>
								<label>Allergies</label>
								<input
									type='text'
									className='form-control'
									name='allergies'
									ref={register}
									placeholder='Allergies'
								/>
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Insurance Information
							</h5>

							<div className='form-group col-md-12'>
								<label>
									Primary Facility <span className='text-danger'>*</span>
									<br />
									<span className='text-muted font-size-12'>
										You can select more than one facility.
									</span>
								</label>

								<Controller
									name='facilities'
									control={control}
									rules={{ validate: validateFacility }}
									render={({ onChange, value }) => (
										<Select
											defaultValue={value}
											onChange={onChange}
											isMulti
											options={facilities}
											className='basic-multi-select'
											classNamePrefix='form-control'
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='facilities' />
							</div>

							<hr />
						</div>
						<div className='text-right'>
							<button
								type='submit'
								disabled={
									facilitiesData.length === 0 ||
									lgas.length === 0 ||
									occupations.length === 0 ||
									(editMode && !values)
								}
								className='btn btn-success btn-main'
							>
								{editMode ? 'Update Enrollee Info' : 'Save & Continue'}
							</button>
						</div>
					</form>

					<Modal
						show={modalState}
						onHide={handleCloseModal}
						animation={false}
						backdrop='static'
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>{modalTitle}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{webCamError && <div className='text-error'>{webCamError}</div>}
							{!webCamError && (
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat='image/jpeg'
									width={`100%`}
									onUserMediaError={() =>
										setWebCamError(
											'No camera found on device/Pemission denied!'
										)
									}
								/>
							)}
						</Modal.Body>
						<Modal.Footer>
							<input
								type='button'
								onClick={handleCloseModal}
								className='btn btn-default'
								value='Cancel'
							/>
							<button
								className='btn btn-success btn-main'
								onClick={capture}
								disabled={webCamError}
								type='button'
							>
								Capture Photo
							</button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</>
	);
}
