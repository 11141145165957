import * as React from 'react';
import AppContext from '../../context';

import { isNotification } from '../../utils/utils';

import AllReferrals from './allReferrals';
import PendingReferrals from './pendingReferrals';
import RejectedReferrals from './rejectedReferrals';
import ApprovedReferrals from './approvedReferrals';

import {
	allReferrals,
	approvedReferrals,
	pendingReferrals,
	rejectedReferrals,
} from '../../Services';

export default function Referrals({ history }) {
	const [currentPage, setCurrentPage] = React.useState(
		isNotification() ? 'pending-referrals' : 'all-referrals'
	);

	// Referral Counts
	const [all, setAll] = React.useState(0);
	const [approved, setApproved] = React.useState(0);
	const [pending, setPending] = React.useState(0);
	const [rejected, setRejected] = React.useState(0);

	const { useQueryString, setQueryString } = React.useContext(AppContext);

	let queryString = useQueryString();

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	React.useEffect(() => {
		allReferrals(setQueryString(query)).then((data) => {
			setAll(data.total);
		});

		pendingReferrals(setQueryString(query)).then((data) => {
			setPending(data.total);
		});

		approvedReferrals(setQueryString(query)).then((data) => {
			setApproved(data.total);
		});

		rejectedReferrals(setQueryString(query)).then((data) => {
			setRejected(data.total);
		});
	}, [query, setQueryString]);

	return (
		<>
			<div className='btn-tabs row col-12'>
				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-4 ${
							currentPage === 'all-referrals' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('all-referrals');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>
							All Referals
							<div className='dci-content'>{all}</div>
						</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-1 ${
							currentPage === 'approved-referrals' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('approved-referrals');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>
							Approved Referrals
							<div className='dci-content'>{approved}</div>
						</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-5 ${
							currentPage === 'pending-referrals' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('pending-referrals');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Pending Referrals</div>
						<div className='dci-content'>{pending}</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-2 ${
							currentPage === 'rejected-referrals' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('rejected-referrals');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Rejected Referrals</div>
						<div className='dci-content'>{rejected}</div>
					</div>
				</div>
			</div>

			{currentPage === 'all-referrals' && (
				<AllReferrals
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'approved-referrals' && (
				<ApprovedReferrals
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'pending-referrals' && (
				<PendingReferrals
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'rejected-referrals' && (
				<RejectedReferrals
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}
		</>
	);
}
