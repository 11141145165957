import * as React from 'react';
import AppContext from '../../context';
import {
	facilities,
	facilityCreate,
	facilityUpdate,
	facilityDelete,
	selectLgas,
	services,
	facilityTypesList,
	facilityContacts,
	facilityContactDelete,
} from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';

import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate } from './../../components/Components';
import FacilityClaims from '../facility-claims';
import ModalSearch from './search';
import useRouter from './../../hooks/useRouter';

import ExportExcel from './exportExcel';

const initValues = {
	services: [],
	contact: {},
};

export default function Facilities({ history }) {
	const queryClient = useQueryClient();
	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		notify,
		currentPath,
		formActionType,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);
	const [searchValues, setSearchValues] = React.useState({});
	const [clearSearch, setClearSearch] = React.useState(false);

	const [lgas, setLgas] = React.useState(null);
	const [facilityServices, setFacilityServices] = React.useState(null);
	const [facilityTypes, setFacilityTypes] = React.useState(null);

	const [queryName] = React.useState('facilities');
	const [formID] = React.useState('form-facility');
	const { modalState, setModalState, modalTitle, setModalTitle } = useModal();

	const {
		modalState: msDoc,
		setModalState: smsDoc,
		modalTitle: mtDoc,
		setModalTitle: smtDoc,
		closeModal: cmDoc,
	} = useModal();

	const {
		modalState: msState,
		modalTitle: msTitle,
		setModalTitle: setMsTitle,
		closeModal,
		showModal,
	} = useModal();

	const [currentScreen, setCurrentScreen] = React.useState('facilities');
	const [selected, setSelected] = React.useState('');
	const [selectedName, setSelectedName] = React.useState('');
	const { toggleFormState } = useFormState(formID);

	const router = useRouter();
	const [query, setQuery] = React.useState(router.query);

	// const [query, setQuery] = React.useState({
	// 	page:
	// 		queryString.get('page') && !isNaN(queryString.get('page'))
	// 			? Number(queryString.get('page'))
	// 			: 1,
	// });

	const [queryExport, setQueryExport] = React.useState({});
	const [exportData, setExportData] = React.useState([]);
	const [exporting, setExporting] = React.useState(false);
	const [exportReady, setExportReady] = React.useState(false);

	/* Export data */
	useQuery(
		['export-facilities', queryExport],
		() => facilities(setQueryString(queryExport)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				// console.log(data, next_page_url, 'export data...');
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQueryExport({ ...queryExport, page: queryExport.page + 1 || 1 });
				} else {
					setExporting(false);
					setExportReady(true);
					setQueryExport({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const handleCloseModal = () => {
		setModalState(false);
		setDefaultValues(initValues);
	};

	const handleCloseDocModal = () => {
		cmDoc();
		setDefaultValues(initValues);
	};

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas(null),
		onSuccess: (data) => setLgas(data),
	});

	/* Facility services */
	useQuery('select-services', () => services(''), {
		onError: (error) => setLgas(null),
		onSuccess: ({ data }) => setFacilityServices(data),
	});

	/* Facility types */
	useQuery('select-facility-types', facilityTypesList, {
		onError: (error) => setFacilityTypes(null),
		onSuccess: (data) => setFacilityTypes(data),
	});

	React.useEffect(() => {
		const rQquery = router.query;

		setQuery({ ...rQquery, sort: 'asc' });
		setSearchValues(rQquery);

		const clearSearchState = Object.keys(rQquery).length > 0 ? true : false;
		setClearSearch(clearSearchState);

		/* Clean up */
		return () => setQuery({});
	}, [router.query]);

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => facilities(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const { mutate: handleSubmitAdd } = useMutation(
		(values) => facilityCreate(values),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Added', 'Data successfully added!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Edit data */
	const { mutate: handleSubmitEdit } = useMutation(
		({ facilityId, values }) => facilityUpdate({ facilityId, values }),
		{
			onSuccess: () => {
				handleCloseModal();
				notify('success', 'Data Modified', 'Data successfully modified!');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Delete data */
	const { mutate: handleSubmitDelete } = useMutation(
		(id) => facilityDelete(id),
		{
			onSuccess: () => {
				notify('success', 'Data Deleted', 'Data successfully deleted!');
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };

			queryClient.prefetchQuery([queryName, nextPage], () =>
				facilities(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setModalTitle('Add Facility');
		setStateData(dispatch, 'formActionType', 1);
		setModalState(true);
	};

	const initEdit = (data) => {
		setModalTitle('Edit Facility');
		setStateData(dispatch, 'formActionType', 2);
		//setDefaultValues(data);
		setDefaultValues({
			...data,
			services:
				data?.services?.length > 0 && data?.services.map((dt) => `${dt.id}`),
			title: data?.contacts[0]?.title,
			first_name: data?.contacts[0]?.first_name,
			last_name: data?.contacts[0]?.last_name,
			phone: data?.contacts[0]?.phone,
			email: data?.contacts[0]?.email,
		});
		setModalState(true);
	};

	const initSearch = (type) => {
		showModal();
		const title = 'Search..';
		setMsTitle(title);
	};

	const onSearchSubmit = (values) => {
		history.push(`${currentPath}${setQueryString(values)}`);
		setQueryExport(values);
		closeModal();
	};

	const onSubmit = (data) => {
		toggleFormState(true, 'saving...');
		if (formActionType === 1) {
			handleSubmitAdd({
				...data,
				contact: {
					title: data.title,
					first_name: data.first_name,
					last_name: data.last_name,
					phone: data.phone,
					email: data.email,
				},
			});
		} else {
			handleSubmitEdit({
				facilityId: defaultValues.id,
				values: {
					...data,
					contact: {
						title: data.title,
						first_name: data.first_name,
						last_name: data.last_name,
						phone: data.phone,
						email: data.email,
					},
				},
			});
		}
	};

	const initClaim = (id, name) => {
		setSelected(id);
		setSelectedName(name);
		setCurrentScreen('claims');
	};

	const initDelete = async (id) => {
		const conf = window.confirm('Are you sure?');
		if (!conf) return;

		await initDeleteContacts(id).then(() => {
			handleSubmitDelete(id);
			console.log('deleted');
		});
	};

	const initDeleteContacts = async (id) => {
		const contactsID = await (
			await facilityContacts(id)
		).data.data.map((dt) => dt.id);

		for (let i = 0; i < contactsID.length; i++) {
			await facilityContactDelete(id, contactsID[i]);
		}
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{currentScreen === 'facilities' && data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col-md-4 float-left'>
										<div>
											<h4 className='m-2 text-success'>
												No. of Facilities: {data?.total}
											</h4>
										</div>
									</div>
									<div className='col-md-8 float-right'>
										<div className='row justify-content-end'>
											{/* <div className='row'> */}
											<div className='col-md-4'>
												{exportReady ? (
													<ExportExcel data={exportData} />
												) : (
													<button
														type='button'
														onClick={() => setExporting(true)}
														className='btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2 w-100'
													>
														<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
														{exporting ? 'Exporting...' : 'Export to Excel'}
													</button>
												)}
											</div>

											<div className='col-6 col-md-4'>
												<button
													type='button'
													onClick={() => initSearch()}
													className='btn btn-info btn-rounded waves-effect waves-light mb-2 mr-2 w-100'
												>
													<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
													Search
												</button>
											</div>

											<div className='col-6 col-md-4'>
												{clearSearch ? (
													<button
														type='button'
														onClick={() => {
															history.push(currentPath);
															setQueryExport({});
															setExportReady(false);
															setExportData([]);
														}}
														className='btn btn-danger btn-rounded waves-effect waves-light mb-2 mr-2 w-100'
													>
														Clear{' '}
														<i className='bx bx-x search-icon font-size-16 align-middle'></i>
													</button>
												) : (
													<button
														type='button'
														onClick={initAdd}
														className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2 w-100'
													>
														<i className='mdi mdi-plus mr-1'></i> New Facility
													</button>
												)}
											</div>
											{/* </div> */}
										</div>
									</div>
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered table-nowrap table-striped'>
											<thead>
												<tr>
													<th width='40%'>Facility Name</th>
													<th width='10%'>Local Govt.</th>
													<th width='5%'>Enrollees</th>
													<th width='10%'>Total Claims</th>
													<th width='10%'>Facility Type</th>
													<th width='10%' className='text-center'>
														Status
													</th>
													<th width='14%' className='text-right'>
														Created At
													</th>
													<th width='1%' className='text-center'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>{row.name}</td>
															<td>{row.lga}</td>
															<td>{row.enrollees_count}</td>
															<td>{row.claims_sum_total_amount}</td>
															<td>{row.type}</td>
															<td className='text-center'>
																{row.status === 'active' && (
																	<span className='badge badge-success font-size-12'>
																		Active
																	</span>
																)}
																{row.status === 'inactive' && (
																	<span className='badge badge-danger font-size-12'>
																		Inactive
																	</span>
																)}
															</td>
															<td className='text-right'>
																{formatDate(row.created_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					initClaim(row.id, row.name)
																				}
																				className='dropdown-item'
																			>
																				<i className='fas fa-user-alt text-primary mr-1'></i>{' '}
																				View Claims
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initEdit(row)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																				Edit
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDelete(row.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-trash-alt text-danger mr-1'></i>{' '}
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										<Paginate data={data} onPageChange={handlePageClick} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			{currentScreen === 'claims' && (
				<div>
					<button
						className='btn btn-success btn-rounded my-2'
						onClick={() => setCurrentScreen('facilities')}
					>
						Back
					</button>

					<FacilityClaims id={selected} name={selectedName} />
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Form
					handleCloseModal={handleCloseModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					formID={formID}
					lgas={lgas}
					facilityServices={facilityServices}
					facilityTypes={facilityTypes}
				/>
			</Modal>

			<Modal
				show={msDoc}
				onHide={handleCloseDocModal}
				animation={false}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{mtDoc}</Modal.Title>
				</Modal.Header>
				<Form
					handleCloseModal={handleCloseDocModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					formID={formID}
					lgas={lgas}
					facilityServices={facilityServices}
					facilityTypes={facilityTypes}
				/>
			</Modal>

			<Modal
				show={msState}
				onHide={closeModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				//size="sm"
			>
				<Modal.Header closeButton>
					<Modal.Title>{msTitle}</Modal.Title>
				</Modal.Header>

				<ModalSearch
					searchValues={searchValues}
					setSearchValues={setSearchValues}
					closeModal={closeModal}
					onSearchSubmit={onSearchSubmit}
					lgas={lgas}
				/>
			</Modal>
		</>
	);
}
