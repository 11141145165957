import * as React from 'react';
import AppContext from '../../../context';
import { Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMsg } from './../../../components/Components';

export default function Form({ handleCloseModal, onSubmit, defaultValues, formID }) {
    const { register, errors, handleSubmit } = useForm({ criteriaMode: "all" });

    return (<>
        <form id={formID} onSubmit={handleSubmit(onSubmit)}>

            <Modal.Body>

                <div className="form-group">
                    <label>Service Name</label>
                    <input type="text" className="form-control"
                        name="name"
                        placeholder="Service Name"
                        defaultValue={defaultValues.name}
                        ref={register({
                            required: "Service name is required!"
                        })}
                    />
                    <ErrorMsg errors={errors} name="name" />
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <input type="button" onClick={handleCloseModal} className="btn btn-default" value="Cancel" />
                <button className="btn btn-success btn-main" type="submit">Save</button>
            </Modal.Footer>
        </form>
    </>);
}