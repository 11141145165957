/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import {
	agentCreate,
	agentUpdate,
	agentDelete,
	agents,
	mdasList,
} from '../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate } from './../../components/Components';

const initValues = {
	mda: '',
};

export default function Agents({ history }) {
	const queryClient = useQueryClient();
	const [queryName] = useState('agents');
	const [formID] = useState('form-agents');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const { toggleFormState } = useFormState(formID);

	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		currentPath,
		formActionType,
		errorResponse,
		formatDate,
		notify,
	} = useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = useState(initValues);
	const [mdas, setMdas] = useState([]);

	const [query, setQuery] = useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	const handleCloseModal = () => {
		closeModal(false);
		setDefaultValues(initValues);
	};

	/* MDAS. */
	useQuery('select-mdas', mdasList, {
		onError: (error) => setMdas([]),
		onSuccess: (data) => setMdas(data),
	});

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => agents(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const { mutate: handleSubmitAdd } = useMutation(
		(values) => agentCreate(values),
		{
			onSuccess: () => {
				handleCloseModal();
				notify(
					'success',
					'User Account Added',
					'New admin user successfully added!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Edit data */
	const { mutate: handleSubmitEdit } = useMutation(
		({ id, values }) => agentUpdate({ id, values }),
		{
			onSuccess: () => {
				handleCloseModal();
				notify(
					'success',
					'User Account Modified',
					'Admin user successfully modified!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Delete data */
	const { mutate: handleSubmitDelete } = useMutation((id) => agentDelete(id), {
		onSuccess: () => {
			notify(
				'success',
				'User Account Deleted',
				'Admin user successfully deleted!'
			);
		},
		onMutate: (id) => {
			queryClient.cancelQueries([queryName, query]);
			const previousData = queryClient.getQueryData([queryName, query]);
			const updateValue = previousData?.data;

			const removeDeleted = updateValue.filter((dt) => dt.id !== id);
			const newData = { ...previousData, data: removeDeleted };
			return queryClient.setQueryData([queryName, query], newData);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
		},
	});

	/* Requery on data, query change */
	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				agents(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setStateData(dispatch, 'formActionType', 1);
		setModalTitle('New Agent');
		setModalState(true);
	};

	const initEdit = (data) => {
		setStateData(dispatch, 'formActionType', 2);
		setModalTitle('Edit Agent');
		setDefaultValues(data);
		setModalState(true);
	};

	const onSubmit = (data) => {
		toggleFormState(true, 'saving...');
		console.log(formActionType);

		if (formActionType === 1) {
			handleSubmitAdd(data);
		} else {
			handleSubmitEdit({ id: defaultValues.id, values: data });
		}
	};

	const initDelete = (id) => {
		const conf = window.confirm('Are you sure?');
		if (!conf) return;
		handleSubmitDelete(id);
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{/* {data && data?.data?.length > 0 && (<div className="row"> */}
			{
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col float-right'>
										<div className='text-sm-right'>
											<button
												type='button'
												onClick={initAdd}
												className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
											>
												<i className='mdi mdi-plus mr-1'></i> New Agent
											</button>
										</div>
									</div>
								</div>

								{data && data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered table-striped table-nowrap'>
											<thead>
												<tr>
													<th width='45%'>Details</th>
													<th width='25%'>Bank</th>
													<th className='text-center' width='10%'>
														Commission
													</th>
													<th className='text-center' width='9%'>
														Status
													</th>
													<th className='text-right' width='10%'>
														Created At
													</th>
													<th className='text-center' width='1%'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>
																<b>
																	{row.first_name} {row.last_name}
																</b>
																<p className='mb-0'>{row.phone}</p>
																<p className='mb-0'>{row.email}</p>
															</td>
															<td>
																<b>{row.bank}</b>
																<p className='mb-0'>{row.account_number}</p>
															</td>
															<td className='text-center'>
																{row.commission_percent}%
															</td>
															<td className='text-center'>
																<span className='badge badge-success font-size-12'>
																	Active
																</span>
															</td>
															<td className='text-right'>
																{formatDate(row.created_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initEdit(row)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																				Edit
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDelete(row.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-trash-alt text-danger mr-1'></i>{' '}
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								)}
								{data && data?.data?.length > 0 && (
									<Paginate data={data} onPageChange={handlePageClick} />
								)}
							</div>
						</div>
					</div>
				</div>
			}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Form
					handleCloseModal={handleCloseModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					formID={formID}
					mdas={mdas}
				/>
			</Modal>
		</>
	);
}
