/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../hooks/useModal';
import { Paginate } from '../../components/Components';

import useNotification from '../../hooks/useNotification';

import { pendingReferrals, referralData } from '../../Services';

export default function PendingReferrals({ history, setQuery, query }) {
	const queryClient = useQueryClient();

	const [currentPage, setCurrentPage] = useState('pending-referrals');
	const [queryName] = useState('pending-referrals');
	const [modalType, setModalType] = useState('details');

	const [showApprove, hideApprove] = useState(false);
	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		useContext(AppContext);

	const [referralId, setReferralId] = useState(null);

	const handleCloseModal = () => {
		closeModal();
		setReferralId(null);
		history.replace({
			search: '',
		});
	};

	const { data, isLoading, isError } = useQuery(
		[queryName, query],
		() => pendingReferrals(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['pending-referrals', nextPage], () =>
				pendingReferrals(setQueryString(query))
			);
		}
	}, [data, query, queryClient, setQueryString]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		setModalType('details');
		setReferralId(row.uuid);
		showModal();
	};

	const [notificationLoad] = useNotification({
		history,
		initDetails,
		type: 'referrals',
	});

	// const initFilter = () => {
	// 	setModalType('filter');
	// 	showModal();
	// };

	// const handleSubmit = (e) => {
	// 	e.preventDefault();

	// 	setQuery(filterData);
	// 	closeModal();
	// };

	if (isLoading) {
		return <div>loading...</div>;
	}

	if (notificationLoad) return <h5>Loading...</h5>;

	return (
		<>
			{!isLoading && isError && <div>error: {isError.message}...</div>}

			{data && data?.data?.length > 0 ? (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-dark'>Pending Referrals</h3>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='30%'>Enrollee</th>
												<th width='10%'>Code</th>
												<th width='25%'>Service Requested</th>
												<th width='10%'>Facility</th>
												<th width='14%'>Status</th>
												<th width='10%'>Date</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{data?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.code}</td>
														<td>
															{row?.comment.length > 20
																? `${row?.comment.substring(0, 20)}...`
																: row?.comment}
														</td>
														<td>
															{row?.facility?.name.indexOf('(') > -1
																? row?.facility?.name.substring(
																		0,
																		row?.facility?.name.indexOf('(')
																  )
																: row?.facility?.name}
														</td>
														<td className='text-warning'>{row?.status}</td>
														<td className='text-right'>
															{formatDate(row?.created_at, 'll')}
															<span className='text-muted ml-2'>
																{formatDate(row?.created_at, 'hh:mm A')}
															</span>
														</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={data} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='px-4'>
					<h4>No Pending Referrals</h4>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Details
						handleCloseModal={handleCloseModal}
						id={referralId}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						history={history}
					/>
				</Modal>
			)}
		</>
	);
}
