/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import { authorizationData, authorizations } from '../../Services';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../hooks/useModal';
import { Paginate } from '../../components/Components';

import SelectSearch, { fuzzySearch } from 'react-select-search';
import '../../utils/select-search.css';

const initValues = {
	mda: '',
};

const initFilterData = {
	start_date: '',
	end_date: '',
	facility_name: '',
	benefit_category: '',
	cin: '',
};

export default function AllAuths({ history, setQuery, query, facilities }) {
	const queryClient = useQueryClient();

	const [currentPage, setCurrentPage] = useState('all-authorizations');
	const [queryName] = useState('all-authorizations');
	const [modalType, setModalType] = useState('details');
	const [filterData, setFilterData] = useState(initFilterData);
	const [showApprove, hideApprove] = useState(false);
	const [authDetails, setAuthDetails] = useState(null);

	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		useContext(AppContext);

	const handleCloseModal = () => {
		closeModal();
		setAuthDetails(initValues);
	};

	const { data, isLoading, isError } = useQuery(
		[queryName, query],
		() => authorizations(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['all-authorizations', nextPage], () =>
				authorizations(setQueryString(nextPage))
			);
		}
	}, [data?.next_page_url, query, queryClient, setQueryString]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { isLoading: detailsLoading } = useQuery(
		['auth-details', authDetails?.uuid],
		() => authorizationData(authDetails?.uuid),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(authDetails?.uuid),
			onSuccess: (data) => setAuthDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const facilityOptions = facilities?.map((facility) => ({
		name: facility.name,
		value: facility.name,
	}));

	const initDetails = (row) => {
		setModalType('details');
		setAuthDetails(row);
		showModal();
	};

	const initFilter = () => {
		setModalType('filter');
		showModal();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setQuery(filterData);
		closeModal();
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && isError && <div>error: {isError.message}...</div>}

			<div className='card'>
				<div className='card-body'>
					<div className='d-flex flex-column flex-md-row'>
						<div className='col-12 col-md-3 mb-2 mb-md-0'>
							<button className='btn btn-success w-100' onClick={initFilter}>
								Filter
							</button>
						</div>
						<div className='col-12 col-md-3'>
							<button
								className='btn btn-secondary w-100'
								onClick={() => {
									setQuery({ page: 1 });
									setFilterData(initFilterData);
								}}
							>
								Reset Filters
							</button>
						</div>
					</div>
				</div>
			</div>

			{data && data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-dark'>All Authorizations</h3>
								</div>
								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='25%'>Enrollee</th>
												<th width='15%'>CIN</th>
												<th width='39%'>Facility</th>
												<th width='10%'>Submitted On</th>
												<th width='10%'>Auth Code</th>
												<th width='10%'>Referral Code</th>

												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>

										<tbody>
											{data?.data.length > 0 ? (
												data?.data.map((row) => {
													return (
														<tr key={row.id}>
															<td>
																<b>
																	{row?.enrollee?.first_name}{' '}
																	{row?.enrollee?.last_name}
																</b>
															</td>
															<td>{row?.enrollee?.insurance_id}</td>
															<td>
																{row?.facility?.name.indexOf('(') > -1
																	? row?.facility?.name.substring(
																			0,
																			row?.facility?.name.indexOf('(')
																	  )
																	: row?.facility?.name}
															</td>
															<td style={{ whiteSpace: 'pre' }}>
																{formatDate(row.created_at, 'll')}
																<span className='text-muted ml-2'>
																	{formatDate(row.created_at, 'hh:mm A')}
																</span>
															</td>
															<td className='text-center'>
																{row.authorization_code || '-'}
															</td>
															<td className='text-center'>
																{row.referral_code || '-'}
															</td>
															<td
																className={`text-capitalize text-${
																	row.status === 'approved'
																		? 'success'
																		: row.status === 'disapproved'
																		? 'error'
																		: 'warning'
																}`}
															>
																{row.status}
															</td>

															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDetails(row)}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})
											) : (
												<tr
												// style={{
												// 	width: '100%',
												// 	textAlign: 'center',
												// 	padding: '45px',
												// 	display: 'flex',
												// 	alignItems: 'center',
												// }}
												>
													<th colSpan={6}>No Authorizations</th>
												</tr>
											)}
										</tbody>
									</table>
									<Paginate data={data} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalType === 'details' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Details
						handleCloseModal={handleCloseModal}
						authDetails={authDetails}
						type={currentPage}
						hideApprove={hideApprove}
						setCurrentPage={setCurrentPage}
						query={query}
						queryName={queryName}
						loading={detailsLoading}
					/>
				</Modal>
			)}

			{modalType === 'filter' && (
				<Modal
					show={modalState}
					onHide={handleCloseModal}
					animation={false}
					keyboard={false}
					backdrop='static'
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Filter Authorizations</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form>
							<div className='row p-3'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Start Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.start_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													start_date: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>End Date</label>
										<input
											type='date'
											className='form-control'
											value={filterData.end_date}
											onChange={(e) =>
												setFilterData({
													...filterData,
													end_date: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>Facility Name</label>

										<SelectSearch
											search
											filterOptions={fuzzySearch}
											options={facilityOptions}
											value={filterData.facility_name}
											name='facility'
											placeholder='Select Facility'
											onChange={(value) =>
												setFilterData({
													...filterData,
													facility_name: value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label>CIN</label>
										<input
											type='text'
											className='form-control'
											value={filterData.cin}
											onChange={(e) =>
												setFilterData({
													...filterData,
													cin: e.target.value,
												})
											}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='btn btn-dark w-100' onClick={handleSubmit}>
										{isLoading ? 'Filtering...' : 'Filter'}
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
