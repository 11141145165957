import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import AppContext from '../context';

import { adminChangePassword } from '../Services';

export default function ChangePassword() {
	let history = useHistory();

	const { dispatch, disableForms, errorResponse, notify, logoutUser } =
		useContext(AppContext);

	const { register, handleSubmit } = useForm({
		criteriaMode: 'all',
	});

	const onSubmit = (values) => {
		disableForms('form-change-password', true);
		adminChangePassword(values)
			.then((res) => {
				console.log(res);
				notify(
					'success',
					'Password Changed!',
					'Password has been changed successfully. Please login again!'
				);

				logoutUser(dispatch);
				history.replace('/');
			})
			.catch((error) => {
				disableForms('form-change-password', false);
				errorResponse({ error, exclude: [401] });
			});
	};

	return (
		<div className='container col-md-8'>
			<form
				className='form-horizontal'
				id='form-change-password'
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className='form-group'>
					<label htmlFor='password'>New Password</label>
					<input
						type='password'
						className='form-control'
						name='password'
						ref={register({
							required: 'Password is required!',
						})}
						placeholder='Enter New Password'
					/>
				</div>

				<div className='form-group'>
					<label htmlFor='password'>Confirm Password</label>
					<input
						type='password'
						className='form-control'
						name='password_confirmation'
						ref={register({
							required: 'Please confirm password!',
						})}
						placeholder='Confirm New Password'
					/>
				</div>

				<button
					className='btn btn-success btn-block waves-effect waves-light bg-theme-success btn-main'
					type='submit'
				>
					Change Password
				</button>
			</form>
		</div>
	);
}
