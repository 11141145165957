import * as React from "react";
import AppContext from "../../context";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from "./../../components/Components";

export default function Form({
  handleCloseModal,
  onSubmit,
  defaultValues,
  userRoleData,
  formID,
}) {
  const { dt } = React.useContext(AppContext);
  const { register, errors, handleSubmit, control } = useForm({
    criteriaMode: "all",
    defaultValues,
  });

  function Select({ onChange, value }) {
    const [selectState, setSelectState] = React.useState(value);

    return (
      <select
        className="form-control"
        name="allow_dependants"
        onChange={(e) => {
          setSelectState(e.target.value);
          onChange(e.target.value === "true");
        }}
        value={selectState}
      >
        <option value="">- Select -</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    );
  }

  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>Company Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Company Name"
                ref={register({
                  required: "Company name is required!",
                })}
              />
              <ErrorMsg errors={errors} name="name" />
            </div>

            <div className="form-group col-md-6">
              <label>No. of Employees</label>
              <input
                type="number"
                className="form-control"
                min={1}
                name="no_of_employees"
                placeholder="No. of Employees"
                ref={register({
                  required: "Number of employee is required!",
                })}
              />
              <ErrorMsg errors={errors} name="no_of_employees" />
            </div>
            <div className="form-group col-md-6">
              <label>Dependancies Allowed</label>
              <Controller
                name="allow_dependants"
                control={control}
                render={({ ref, ...props }) => {
                  return <Select {...props} />;
                }}
                defaultValue={false}
              />
              <ErrorMsg errors={errors} name="allow_dependants" />
            </div>

            <div className="form-group col-md-6">
              <label>Duration</label>
              <select
                className="form-control"
                name="duration"
                ref={register({
                  required: "Duration is required!",
                })}
              >
                <option value="12" selected>
                  12 Months
                </option>
                <option value="18">18 Months</option>
                <option value="24">2 Years</option>
                <option value="30">2½ Years</option>
                <option value="36">3 Years</option>
                <option value="48">4 Years</option>
                <option value="60">5 Years</option>
                <option value="72">6 Years</option>
              </select>
              <ErrorMsg errors={errors} name="duration" />
            </div>

            <div className="form-group col-md-6">
              <label>Waiting Period</label>
              <input
                type="number"
                className="form-control"
                min={0}
                name="waiting_period"
                placeholder="Waiting Period"
                ref={register({
                  required: "Waiting period is required!",
                })}
              />
              <ErrorMsg errors={errors} name="waiting_period" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-success btn-main" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
