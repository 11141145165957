export const isNotification = () => {
	const path = window.location.search.substring(6, 18);

	if (path === 'notification') {
		return true;
	} else {
		return false;
	}
};

export const accessRoles = (type) => {
	switch (type) {
		case 'facility-change':
			return ['superadmin', 'health admin', 'insurance admin'];

		case 'enrollment':
			return ['superadmin', 'insurance admin', 'enrolment officer'];

		default:
			return ['superadmin'];
	}
};
