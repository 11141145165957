import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import AllAuths from './allAuthRequest';
import RejectedAuths from './rejectedAuthRequest';
import ApprovedAuths from './approvedAuthRequests';
import PendingAuths from './pendingAuthRequest';

import './style.css';
import { useQuery } from 'react-query';
import { isNotification } from '../../utils/utils';

import {
	authorizations,
	pendingAuthorizations,
	rejectedAuthorizations,
	approvedAuthorizations,
	facilitiesList,
} from '../../Services';

export default function Authorization({ history }) {
	const [currentPage, setCurrentPage] = useState(
		isNotification() ? 'pending-requests' : 'all-requests'
	);

	// Auth Counts
	const [all, setAll] = useState(0);
	const [approved, setApproved] = useState(0);
	const [pending, setPending] = useState(0);
	const [rejected, setRejected] = useState(0);

	const { setQueryString, useQueryString } = useContext(AppContext);

	let queryString = useQueryString();

	const [query, setQuery] = useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	useEffect(() => {
		authorizations(setQueryString(query)).then((data) => {
			setAll(data.total);
		});

		pendingAuthorizations(setQueryString(query)).then((data) => {
			setPending(data.total);
		});

		approvedAuthorizations(setQueryString(query)).then((data) => {
			setApproved(data.total);
		});

		rejectedAuthorizations(setQueryString(query)).then((data) => {
			setRejected(data.total);
		});
	}, [query, setQueryString]);

	const { data: facilities } = useQuery(['listFacilities'], () =>
		facilitiesList()
	);

	return (
		<>
			<div className='row pb-10 btn-tabs justify-content-center'>
				<div className='col-sm-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-4 ${
							currentPage === 'approved-requests' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ page: 1 });
							setCurrentPage('all-requests');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>All Authorizations</div>
						<div className='dci-content'>{all}</div>
					</div>
				</div>

				<div className='col-sm-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-1 ${
							currentPage === 'approved-requests' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('approved-requests');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Approved Authorizations</div>
						<div className='dci-content'>{approved}</div>
					</div>
				</div>

				<div className='col-sm-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-5 ${
							currentPage === 'pending-requests' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('pending-requests');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Pending Authorizations</div>
						<div className='dci-content'>{pending}</div>
					</div>
				</div>

				<div className='col-sm-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-2 ${
							currentPage === 'rejected-requests' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('rejected-requests');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Rejected Authorizations</div>
						<div className='dci-content'>{rejected}</div>
					</div>
				</div>

				{/* <div className='col-sm-4'>
					<div
						className={`btn dash-card dash-card-info dash-icon-6 ${
							currentPage === 'awaiting-requests' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ page: 1 });
							setCurrentPage('awaiting-requests');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Overdue Authorizations</div>
						<div className='dci-content'>{pending}</div>
					</div>
				</div> */}
			</div>

			{currentPage === 'all-requests' && (
				<AllAuths
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
					facilities={facilities}
				/>
			)}

			{currentPage === 'approved-requests' && (
				<ApprovedAuths
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
					facilities={facilities}
				/>
			)}

			{currentPage === 'pending-requests' && (
				<PendingAuths
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'rejected-requests' && (
				<RejectedAuths
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{/* {currentPage === 'awaiting-requests' && (
				<AwaitingAuths
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)} */}
		</>
	);
}
