import * as React from 'react';
import AppContext from '../../context';
import { useQuery } from 'react-query';

import PendingClaims from './pendingClaimsRequest';
import RejectedClaims from './rejectedClaimsRequest';
import ApprovedClaims from './approvedClaimsRequests';
import AllClaims from './allClaimsRequests';

import { isNotification } from '../../utils/utils';

import {
	claims,
	acceptedClaims,
	pendingClaims,
	rejectedClaims,
	facilitiesList,
	benefitCatsList,
	claimsAmount,
} from '../../Services';

export default function Claims({ history }) {
	const [currentPage, setCurrentPage] = React.useState(
		isNotification() ? 'pending-claims' : 'all-claims'
	);

	// Claims Counts
	const [all, setAll] = React.useState(0);
	const [accepted, setAccepted] = React.useState(0);
	const [pending, setPending] = React.useState(0);
	const [rejected, setRejected] = React.useState(0);
	const [amount, setAmount] = React.useState({});

	const { useQueryString, setQueryString } = React.useContext(AppContext);

	let queryString = useQueryString();

	const { data: facilities } = useQuery(['listFacilities'], () =>
		facilitiesList()
	);

	const { data: benefitCats } = useQuery(['listBenefitCats'], () =>
		benefitCatsList()
	);

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	React.useEffect(() => {
		claims(setQueryString(query)).then((data) => {
			setAll(data.total);
		});

		pendingClaims(setQueryString(query)).then((data) => {
			setPending(data.total);
		});

		acceptedClaims(setQueryString(query)).then((data) => {
			setAccepted(data.total);
		});

		rejectedClaims(setQueryString(query)).then((data) => {
			setRejected(data.total);
		});

		claimsAmount(setQueryString(query)).then((data) => {
			setAmount(data.claims);
		});
	}, [query, setQueryString]);

	return (
		<>
			<div className='pb-10 btn-tabs row'>
				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-4 ${
							currentPage === 'all-claims' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('all-claims');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>All Claims</div>

						<div className='dci-content'>{all}</div>
						<div className='dci-content text-info'>
							NGN {amount?.total_amount?.toLocaleString()}
						</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-1 ${
							currentPage === 'approved-claims' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('approved-claims');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Approved Claims</div>

						<div className='dci-content'>{accepted}</div>
						<div className='dci-content text-success'>
							NGN {amount?.accepted_amount?.toLocaleString()}
						</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-5 ${
							currentPage === 'pending-claims' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('pending-claims');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Pending Claims</div>

						<div className='dci-content'>{pending}</div>
						<div className='dci-content text-warning'>
							NGN {amount?.pending_amount?.toLocaleString()}
						</div>
					</div>
				</div>

				<div className='col-6 col-md-3'>
					<div
						className={`btn dash-card dash-card-info dash-icon-2 ${
							currentPage === 'rejected-claims' ? 'btn-tabbed' : ''
						} btn-sm`}
						onClick={() => {
							setQuery({ ...query, page: 1 });
							setCurrentPage('rejected-claims');
						}}
						style={{ alignItems: 'baseline' }}
					>
						<div className='dci-title'>Rejected Claims</div>

						<div className='dci-content'>{rejected}</div>
						<div className='dci-content text-danger'>
							NGN {amount?.rejected_amount?.toLocaleString()}
						</div>
					</div>
				</div>
			</div>

			{currentPage === 'all-claims' && (
				<AllClaims
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
					facilities={facilities}
					benefitCats={benefitCats}
				/>
			)}

			{currentPage === 'approved-claims' && (
				<ApprovedClaims
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'pending-claims' && (
				<PendingClaims
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'rejected-claims' && (
				<RejectedClaims
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}
		</>
	);
}
