import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data }) => {
	return (
		<ExcelFile
			filename='Referrals'
			element={
				<button
					className='btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2'
					type='button'
				>
					<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
					Download file
				</button>
			}
		>
			<ExcelSheet data={data} name='Referrals'>
				<ExcelColumn
					label='Enrollee'
					value={(col) => `${col.enrollee.full_name}`}
				/>
				<ExcelColumn label='Referral Code' value='code' />
				<ExcelColumn label='Comment' value='comment' />
				<ExcelColumn label='Status' value='status' />
				<ExcelColumn label='Date Submitted' value='date_of_submission' />
				{/* <ExcelColumn label='Status' value='status' />
				<ExcelColumn label='Type' value='type' /> */}

				{/* <ExcelColumn
        label='Birth Date'
        value={(col) =>
          col.dob
            ? moment(col.dob).format('ll')
            : col.dob
        }
      /> */}

				{/* {
					<ExcelColumn
						label='Contacts'
						value={(col) =>
							col?.contacts.length > 0
								? `${col?.contacts[0]?.first_name}, ${col?.contacts[0]?.last_name} - ${col?.contacts[0]?.phone}`
								: ''
						}
					/>
				} */}
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExportExcel;
