/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import { authorizationData, pendingAuthorizations } from '../../Services';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from './details';
import useModal from '../../hooks/useModal';
import { Paginate } from '../../components/Components';

import useNotification from '../../hooks/useNotification';

const initValues = {
	mda: '',
};

export default function PendingAuths({ history, query, setQuery }) {
	const queryClient = useQueryClient();

	const [currentPage, setCurrentPage] = useState('pending-authorizations');
	const [queryName] = useState('pending-authorizations');
	const [showApprove, hideApprove] = useState(false);
	const [mode, setMode] = useState('');

	const { modalState, closeModal, showModal } = useModal();

	const { dispatch, setQueryString, currentPath, errorResponse, formatDate } =
		useContext(AppContext);

	const [authDetails, setAuthDetails] = useState(null);

	const initDetails = (row) => {
		setAuthDetails(row);
		showModal();
	};

	const [notificationLoad] = useNotification({
		history,
		initDetails,
		type: 'authorizations',
	});

	const handleCloseModal = () => {
		closeModal();
		setMode('');
		setAuthDetails(initValues);
		history.replace({
			search: '',
		});
	};

	const { data, isLoading, isError } = useQuery(
		[queryName, query],
		() => pendingAuthorizations(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['pending-authorizations', nextPage], () =>
				pendingAuthorizations(setQueryString(nextPage))
			);
		}
	}, [data?.next_page_url, query, queryClient, setQueryString]);

	const handlePageClick = ({ selected }) => {
		console.log(selected);
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { isLoading: detailsLoading } = useQuery(
		['auth-details', authDetails?.uuid],
		() => authorizationData(authDetails?.uuid),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(authDetails?.uuid),
			onSuccess: (data) => setAuthDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const initQueries = (row) => {
		setAuthDetails(row);
		setMode('queries');
		showModal();
	};

	if (notificationLoad) return <h5>Loading...</h5>;

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && isError && <div>error: {isError.message}...</div>}

			{data && data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-warning'>Pending Authorizations</h3>
								</div>
								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='25%'>Enrollee</th>
												<th width='34%'>Facility</th>
												<th width='10%'>Submitted On</th>
												<th className='text-right' width='10%'>
													Auth Code
												</th>
												<th className='text-right' width='10%'>
													Referral Code
												</th>
												<th width='10%'>Status</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{data?.data?.length > 0 ? (
												data?.data.map((row) => {
													return (
														<tr key={row.id}>
															<td>
																<b>
																	{row?.enrollee?.first_name}{' '}
																	{row?.enrollee?.last_name}
																</b>
																<br />
															</td>
															<td>
																{row?.facility?.name.indexOf('(') > -1
																	? row?.facility?.name.substring(
																			0,
																			row?.facility?.name.indexOf('(')
																	  )
																	: row?.facility?.name}
															</td>
															<td style={{ whiteSpace: 'pre' }}>
																{formatDate(row.created_at, 'll')}
																<span className='text-muted ml-2'>
																	{formatDate(row.created_at, 'hh:mm A')}
																</span>
															</td>
															<td className='text-center'>
																{row.authorization_code || '-'}
															</td>
															<td className='text-center'>
																{row.referral_code || '-'}
															</td>
															<td className='text-warning'>Pending</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDetails(row)}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initQueries(row)}
																				className='dropdown-item'
																			>
																				View Queries
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})
											) : (
												<tr
													style={{
														width: '100%',
														textAlign: 'center',
														padding: '45px',
														display: 'flex',
														alignItems: 'center',
														background: 'none',
													}}
												>
													No Pending Authorizations
												</tr>
											)}
										</tbody>
									</table>
									<Paginate data={data} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
				size='lg'
			>
				<Details
					handleCloseModal={handleCloseModal}
					authDetails={authDetails}
					type='pending-authorizations'
					hideApprove={hideApprove}
					setCurrentPage={setCurrentPage}
					query={query}
					queryName={queryName}
					loading={detailsLoading}
					mode={mode || ''}
				/>
			</Modal>
		</>
	);
}
