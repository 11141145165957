import * as React from 'react';
import AppContext from '../../context';
import { claimData, facilityClaims } from '../../Services';
import { useQuery, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Details from '../claims/details';
import useModal from '../../hooks/useModal';

import { Paginate } from '../../components/Components';

const initValues = {
	mda: '',
};

export default function FacilityClaims({ id, name, history }) {
	const [currentPage, setCurrentPage] = React.useState('fClaims');
	const queryClient = useQueryClient();
	const [queryName] = React.useState('fClaims');
	const [formID] = React.useState('form-agents');

	const [showApprove, hideApprove] = React.useState(false);
	const { modalState, closeModal, showModal } = useModal();

	const {
		dispatch,
		setQueryString,
		useQueryString,
		currentPath,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
		id,
	});

	const [claimDetails, setClaimDetails] = React.useState(null);

	const handleCloseModal = () => {
		closeModal();
		setClaimDetails(initValues);
	};

	const {
		data: claimsData,
		isLoading: claimLoading,
		isError: claimError,
		refetch: Refetch,
	} = useQuery(
		[queryName, query],
		() => facilityClaims(id, setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	React.useEffect(() => {
		if (claimsData?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery(['fClaims', nextPage], () =>
				facilityClaims(id, setQueryString(nextPage))
			);
		}
	}, [claimData, query, queryClient]);

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };

		setQuery(nQ);

		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	useQuery(
		['claim-details', claimDetails?.id],
		() => claimData(claimDetails?.id),
		{
			retry: 2,
			manual: true,
			enabled: Boolean(claimDetails?.id),
			onSuccess: (data) => setClaimDetails(data),
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	const initDetails = (row) => {
		setClaimDetails(row);
		showModal();
	};

	return (
		<>
			{claimLoading && <div>loading...</div>}
			{!claimLoading && claimError && <div>error: {claimError.message}...</div>}

			{claimsData && claimsData?.data?.length > 0 ? (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='card-title'>
									<h3 className='text-info'>{name} - Claims</h3>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th width='35%'>Enrollee</th>
												<th width='25%'>Status</th>
												<th className='text-right' width='15%'>
													Submitted On
												</th>
												<th className='text-right' width='15%'>
													Code
												</th>
												<th className='text-center' width='1%'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{claimsData?.data.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row?.enrollee?.first_name}{' '}
																{row?.enrollee?.last_name}
															</b>
															<br />
														</td>
														<td>{row?.status}</td>
														<td className='text-right'>
															{formatDate(row.date_of_submission)}
														</td>
														<td className='text-right'>
															{row.authorization_code}
														</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDetails(row)}
																			className='dropdown-item'
																		>
																			View Details
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<Paginate data={claimsData} onPageChange={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='d-flex justify-content-center'>
					<h4 className='text-danger'>No Claims Available for {name}</h4>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>Claim Details</Modal.Title>
				</Modal.Header>

				<Details
					handleCloseModal={handleCloseModal}
					claimDetails={claimDetails}
					type={currentPage}
					hideApprove={hideApprove}
					setCurrentPage={setCurrentPage}
					pendingRefetch={Refetch}
				/>
			</Modal>
		</>
	);
}
