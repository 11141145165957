import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from './../../components/Components';
import AppContext from '../../context';
import Select from 'react-select';

export default function Form({ handleCloseModal, onSubmit,
    defaultValues, benefits, formID, benefitDefaultValues, setBenefitDefaultValues }) {
    const { lengthValidate, checkObject, capitalize } = React.useContext(AppContext);
    const { register, errors, handleSubmit, getValues, control } = useForm({
        criteriaMode: "all",
        //mode: "onChange",
        defaultValues
    });

    React.useEffect(() => {
        //console.log(benefitDefaultValues, 'benefitDefaultValues...')
        return () => {
            setBenefitDefaultValues([]);
        };
    }, [])

    return (<>
        <form id={formID} onSubmit={handleSubmit(onSubmit)}>

            <Modal.Body>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label>Name</label>
                        <input type="text" className="form-control"
                            name="name"
                            placeholder="Name"
                            ref={register({
                                required: "Name is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="name" />
                    </div>

                    <div className="form-group col-sm-6">
                        <label>Code</label>
                        <input type="text" className="form-control"
                            name="code"
                            placeholder="Code"
                            ref={register({
                                required: "Code is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="code" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group  col-sm-6">
                        <label>Amount</label>
                        <input type="number" className="form-control"
                            name="amount"
                            placeholder="Amount"
                            ref={register({
                                required: "Amount is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="amount" />
                    </div>

                    <div className="form-group  col-sm-6">
                        <label>Family Amount</label>
                        <input type="number" className="form-control"
                            name="family_amount"
                            placeholder="Family Amount"
                            ref={register({
                                required: "Family amount is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="family_amount" />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-sm-6">
                        <label>Type</label>
                        <select className="form-control"
                            name="type"
                            ref={register({
                                required: "Type is required!"
                            })}>
                            <option value=""> - Select -</option>
                            <option value="recurrent">Recurrent</option>
                            <option value="one-off">Non-Recurrent</option>
                        </select>
                        <ErrorMsg errors={errors} name="type" />
                    </div>


                    <div className="form-group col-sm-6">
                        <label>Enrolle Class</label>
                        <input type="text" className="form-control"
                            name="enrolle_class"
                            placeholder="Enrolle Class"
                            ref={register}
                        />
                    </div>
                </div>


                {checkObject(benefits) && Object.entries(benefits).map((value, key) => {
                    //console.log(value[1], 'value[1]..');
                    const opt = value[1];
                    const selectedGroup = benefitDefaultValues && benefitDefaultValues.filter(dt => dt.type === value[0]);
                    console.log(value[0], selectedGroup, benefitDefaultValues, 'value..');
                    return (<div className="form-group" key={key}>
                        <label>{capitalize(value[0])} Benefit</label>
                        {value[1] && (<>
                            <Controller
                                name={`${value[0]}-benefit`}
                                control={control}
                                //rules={{ required: 'Select at least one benefit!' }}
                                render={({ onChange, value }) => (
                                    <Select
                                        defaultValue={selectedGroup}
                                        onChange={onChange}
                                        isMulti
                                        options={opt}
                                        className="basic-multi-select"
                                        classNamePrefix="form-control"
                                    />
                                )}
                            />
                            
                        </>)}
                    </div>)
                })}
                {/* <ErrorMsg errors={errors} name="benefits" /> */}


                {/* <div className="form-group" >
                    <label className="m-0"><b>Benefits</b></label>
                    <Controller
                        name="benefits"
                        control={control}
                        rules={{ required: 'Select at least one benefit!' }}
                        render={({ onChange, value }) => (
                            <Select
                                defaultValue={benefitDefaultValues}
                                onChange={onChange}
                                isMulti
                                options={benefits}
                                className="basic-multi-select"
                                classNamePrefix="form-control"
                            />
                        )}
                    />
                    <ErrorMsg errors={errors} name="benefits" />
                </div> */}

            </Modal.Body>

            <Modal.Footer>
                <input type="button" onClick={handleCloseModal} className="btn btn-default" value="Cancel" />
                <button className="btn btn-success btn-main" type="submit">Save</button>
            </Modal.Footer>
        </form>
    </>);
}