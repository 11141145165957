import * as React from 'react';
import AppContext from '../../context';
import {
	corporatesEnrollees,
	enrolleeData,
	packagesList as selectPackages,
	selectLgas,
	mdasList,
	enrolleeDelete,
	facilitiesList,
} from '../../Services';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Modal, Dropdown } from 'react-bootstrap';
import { Paginate, Currency } from './../../components/Components';
import useModal from './../../hooks/useModal';
import Details from '../enrollment/details';
import ModalSearch from '../enrollment/search';
import useRouter from './../../hooks/useRouter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initValues = {
	services: [],
	contact: {},
};

export default function Enrollees({ history, id, setCurrentPage, name }) {
	const queryClient = useQueryClient();
	const {
		dispatch,
		setQueryString,
		useQueryString,
		currentPath,
		formatDateBr,
		notify,
		errorResponse,
		formatDate,
	} = React.useContext(AppContext);

	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);

	const [enrolleeDetails, setEnrolleeDetails] = React.useState({});
	const searchRef = React.useRef();

	const [queryName] = React.useState('enrollees');
	const [formID] = React.useState('form-enrollee');
	const { modalState, setModalState, modalTitle, setModalTitle } = useModal();
	const {
		modalState: msState,
		modalTitle: msTitle,
		setModalTitle: setMsTitle,
		closeModal,
		showModal,
	} = useModal();

	const router = useRouter();

	const [query, setQuery] = React.useState(router.query);
	const [searchValues, setSearchValues] = React.useState({
		start_date: new Date(),
	});
	const [clearSearch, setClearSearch] = React.useState(false);
	const [meta, setMeta] = React.useState({});
	const [searchType, setSearchType] = React.useState(1);

	const [packages, setPackages] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);
	const [mdas, setMdas] = React.useState([]);
	const [facilities, setFacilities] = React.useState([]);

	const { q, gender, religion, start_date, end_date, page } = router.query;

	/* export query */
	const [queryExport, setQueryExport] = React.useState({});
	const [exportData, setExportData] = React.useState([]);
	const [exporting, setExporting] = React.useState(false);
	const [exportReady, setExportReady] = React.useState(false);
	const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);
	const [exportExcel, setExportExcel] = React.useState(false);

	React.useEffect(() => {
		const rQquery = router.query;

		setQuery(rQquery);
		let sss = rQquery;
		sss = {
			...sss,
			start_date: start_date ? moment(start_date).toDate() : '',
			end_date: end_date ? moment(end_date).toDate() : '',
		};
		setSearchValues(sss);

		const clearSearchState = Object.keys(rQquery).length > 0 ? true : false;
		setClearSearch(clearSearchState);

		/* Clean up */
		return () => setQuery({});
	}, [router.query]);

	/* Page data */
	const { isLoading, isFetching, isError, data, error } = useQuery(
		[queryName, query],
		() => corporatesEnrollees(id, setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Export data */
	useQuery(
		['export-active-enrollees', queryExport],
		() => corporatesEnrollees(id, setQueryString(queryExport)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				//console.log(data, 'export data...');
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQueryExport({ ...queryExport, page: queryExport.page + 1 });
				} else {
					setExporting(false);
					setExportReady(true);
					setQueryExport({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Packages */
	useQuery('select-packages', selectPackages, {
		onError: (error) => setPackages([]),
		onSuccess: (data) => setPackages(data),
	});

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas([]),
		onSuccess: (data) => setLgas(data),
	});

	/* MDAS. */
	useQuery('select-mdas', mdasList, {
		onError: (error) => setMdas([]),
		onSuccess: (data) => setMdas(data),
	});

	/* MDAS. */
	useQuery('select-facilities', facilitiesList, {
		onError: (error) => setFacilities([]),
		onSuccess: (data) => setFacilities(data),
	});

	const handleCloseModal = () => {
		setModalState(false);
		setDefaultValues(initValues);
	};

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data && data.total > 0) {
			const newMeta = { from: data?.from, to: data?.to, total: data?.total };
			setMeta(newMeta);
		}

		if (data?.next_page_url) {
			let nextPage = {
				...query,
				page: query?.page ? Number(query.page) + 1 : 2,
			};

			queryClient.prefetchQuery([queryName, nextPage], () =>
				corporatesEnrollees(id, setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;

		let nQ = { ...query, page };
		setQuery(nQ);

		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { mutate: handleSubmitDelete } = useMutation(
		(id) => enrolleeDelete(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Enrollee Deleted',
					'Enrollee data successfully deleted!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const initDelete = (id) => {
		const conf = window.confirm(
			'This will delete all enrollee data. Are you sure?'
		);
		if (!conf) return;
		handleSubmitDelete(id);
	};

	const initDetails = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);
		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;
		setEnrolleeDetails(row);
		setModalTitle(title);
		setModalState(true);
	};

	const initEdit = (data) => {
		//setPageTitle(dispatch, 'Edit Enrollee');
		history.push('/enrollee/create', { continueData: data, editMode: true });
	};

	const initAddDependant = async (data) => {
		const { facilities } = await enrolleeData(data?.id);
		//console.log(facilities, 'enrolleeDt...')
		history.push('/enrollee/add-dependant', {
			enrolleeData: {
				...data,
				facilities:
					(facilities &&
						facilities.length > 0 &&
						facilities.map((dt) => ({ label: dt.name, value: dt.id }))) ||
					[],
			},
		});
	};

	const initSearch = (type) => {
		setSearchType(type);
		showModal();
		const title = type === 2 ? 'Export to Excel' : 'Search..';
		setMsTitle(title);
	};

	const onSearchSubmit = (values) => {
		console.log(values, 'values...');
		history.push(`${currentPath}${setQueryString(values)}`);
		closeModal();
	};

	const onExportSubmit = (values) => {
		setToggleCloseBtn(false);
		setExportData([]);
		setQueryExport({ ...values, page: 1 });
		setExporting(true);
	};

	const resetExport = () => {
		closeModal();
		setToggleCloseBtn(true);
		setExportData([]);
		setQueryExport({});
		setExporting(false);
		setExportReady(false);
		history.push(currentPath);
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			<div className='w-100 d-flex justify-content-start'>
				<button
					onClick={() => setCurrentPage('corporates')}
					className='btn btn-secondary btn-rounded waves-effect waves-light my-3'
				>
					back to Corporates
				</button>
			</div>

			{data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='page-action-bar'>
									<div>
										<div
											className='btn-group mr-2'
											role='group'
											aria-label='Basic example'
										>
											<button
												type='button'
												onClick={() => initSearch(1)}
												className='btn btn-success btn-rounded waves-effect waves-light'
											>
												<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
												Search
											</button>
											{clearSearch && (
												/* (page && Object.keys(router.query).length !== 1) && */ <button
													type='button'
													onClick={() => history.push(currentPath)}
													className='btn btn-success btn-rounded waves-effect waves-light'
												>
													Clear{' '}
													<i className='bx bx-x search-icon font-size-16 align-middle'></i>
												</button>
											)}
										</div>
										<div
											className='btn-group mr-2'
											role='group'
											aria-label='Basic example'
										></div>
										{/* <button
											type='button'
											onClick={() => initSearch(2)}
											className='btn btn-secondary btn-rounded waves-effect waves-light'
										>
											<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
											Export to Excel
										</button> */}
										{!exportExcel ? (
											<button
												className='btn btn-secondary btn-rounded waves-effect waves-light my-3 ml-3'
												onClick={() => {
													setExportExcel(true);

													setTimeout(() => {
														setExportExcel(false);
													}, 3000);
												}}
											>
												Click to export
											</button>
										) : (
											<>
												<ReactHTMLTableToExcel
													id='export-button'
													className='btn btn-secondary btn-rounded waves-effect waves-light my-3 ml-3'
													table='table-to-xls'
													filename={`Enrollees-${name}`}
													sheet='enrollees'
													buttonText='Export All'
												/>
											</>
										)}
									</div>
									<div>
										<button
											type='button'
											onClick={() => history.push('/enrollee/create')}
											className='btn btn-success btn-rounded waves-effect waves-light'
										>
											<i className='mdi mdi-plus mr-1'></i>New Enrollee
										</button>
									</div>
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}
								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table
											className='table table-centered table-nowrap table-striped'
											id='table-to-xls'
										>
											<thead>
												<tr>
													<th width='15%'>Enrollee ID</th>
													<th width='25%'>Info</th>
													<th width='19%'>Contact</th>
													<th width='5%'>DOB</th>
													<th width='5%'>Facility</th>
													<th width='20%'>Plan</th>
													<th width='10%' className='text-right'>
														Created At
													</th>
													{!exportExcel && (
														<th width='1%' className='text-center'>
															<i className='mdi mdi-dots-horizontal' />
														</th>
													)}
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													let sub = null;
													if (
														row?.subscriptions &&
														row?.subscriptions.length > 0
													) {
														sub = row.subscriptions.slice(-1)[0];
														//console.log(row.id, sub, 'subs....');
													}

													return (
														<tr key={row.id}>
															<td>{row.insurance_id}</td>
															<td>
																<div className='font-weight-bold'>
																	{row.first_name} {row.middle_name}{' '}
																	{row.last_name}
																</div>
																<div>
																	{row.gender} | {row.occupation}
																</div>
																<div className='font-weight-bold'>
																	{row.insurance_id}
																</div>
																{row.genotype} | {row.blood_group}
																{row?.source === 'equity' && (
																	<>
																		<br />
																		<span className='badge badge-info'>
																			Equity
																		</span>
																	</>
																)}
															</td>
															<td>
																{row.lga}
																<br />
																{row.phone}
																{row.email && (
																	<>
																		<br />
																		{row.email}
																	</>
																)}
															</td>
															<td>{row.dob}</td>
															<td>{row.facilities[0].name}</td>
															<td>
																{row?.benefactor_id != null ? (
																	<strong className='font-size-12 text-muted'>
																		Dependant
																	</strong>
																) : row?.package ? (
																	<>
																		<div className='font-size-13 font-weight-medium'>
																			{row?.package?.name}
																		</div>
																		<Currency
																			value={
																				sub?.is_family_plan === 1
																					? row?.package?.family_amount
																					: row?.package?.amount
																			}
																		/>
																		{sub && (
																			<>
																				<div className='font-size-12 font-weight-medium text-secondary'>
																					{formatDate(sub.start_date, 'll')} -{' '}
																					{formatDate(
																						sub.expiration_date,
																						'll'
																					)}
																				</div>
																				<span className='badge badge-info'>
																					{sub.is_family_plan === 1
																						? 'Family Plan'
																						: 'Individual Plan'}
																				</span>
																			</>
																		)}
																	</>
																) : (
																	<strong className='font-size-12 text-muted'>
																		None
																	</strong>
																)}

																{/* {row?.package ? (<>
                                                    <div className="font-size-13 font-weight-medium">{row?.package?.name}</div>
                                                    <Currency value={sub?.is_family_plan === 1 ? (row?.package?.family_amount) : (row?.package?.amount)} />
                                                    {sub && (<>
                                                        <div className="font-size-12 font-weight-medium text-secondary">
                                                            {formatDate(sub.start_date, 'll')} - {formatDate(sub.expiration_date, 'll')}
                                                        </div>
                                                        <span className="badge badge-info">
                                                            {sub.is_family_plan === 1 ? 'Family Plan' : 'Individual Plan'}
                                                        </span>
                                                    </>)}
                                                </>)
                                                    : row?.benefactor_id !== null ?
                                                        (<strong className="font-size-12 text-muted">Dependant</strong>)
                                                        : (<strong className="font-size-12 text-muted">None</strong>)} */}
															</td>
															<td className='text-right'>
																{formatDateBr(row.created_at)}
															</td>

															{!exportExcel && (
																<td width='1%' className='text-center'>
																	<div className='dropdown'>
																		<a
																			href='#'
																			className='dropdown-toggle card-drop'
																			data-toggle='dropdown'
																			aria-expanded='false'
																		>
																			<i className='mdi mdi-dots-horizontal font-size-18'></i>
																		</a>
																		<ul className='dropdown-menu dropdown-menu-right'>
																			<li>
																				<a
																					style={{ cursor: 'pointer' }}
																					onClick={() => initDetails(row)}
																					className='dropdown-item'
																				>
																					View Details
																				</a>
																			</li>
																		</ul>
																	</div>
																</td>
															)}
														</tr>
													);
												})}
											</tbody>
										</table>
										<div className='row'>
											<div className='col-sm-12 col-md-5'>
												Showing {meta?.from} - {meta?.to} of {meta?.total}{' '}
												Results
											</div>
											<div className='col-sm-12 col-md-7'>
												<Paginate data={data} onPageChange={handlePageClick} />
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Details
					handleCloseModal={handleCloseModal}
					enrolleeDetails={enrolleeDetails}
				/>
			</Modal>

			<Modal
				show={msState}
				onHide={closeModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				//size="sm"
			>
				<Modal.Header closeButton={toggleCloseBtn}>
					<Modal.Title>{msTitle}</Modal.Title>
				</Modal.Header>

				<ModalSearch
					searchValues={searchValues}
					setSearchValues={setSearchValues}
					closeModal={closeModal}
					onSearchSubmit={onSearchSubmit}
					onExportSubmit={onExportSubmit}
					exportData={exportData}
					exporting={exporting}
					exportReady={exportReady}
					resetExport={resetExport}
					lgas={lgas}
					packages={packages}
					searchType={searchType}
					mdas={mdas}
					facilities={facilities}
				/>
			</Modal>
		</>
	);
}
