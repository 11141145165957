import { useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppContext from './../context';
import { useQuery, useQueryClient } from 'react-query';

import { notifications, notificationsCount } from '../Services';
import useAudio from '../hooks/useAudios';

import logo from '../assets/images/edhic_logo.jpg';

const HeaderNavbar = ({ children, history }) => {
	const { dispatch, userData, logoutUser, baseUrl } = useContext(AppContext);
	const queryClient = useQueryClient();

	const [playing, toggle] = useAudio();

	const location = window.location.pathname;

	const { data } = useQuery(
		['totalNotifications'],
		() => notificationsCount(),
		{
			staleTime: 3000,
		}
	);

	// Prefetch Notification Query
	const prefetchMotifs = async () => {
		await queryClient.prefetchQuery({
			queryKey: ['notifications', 'page=1'],
			queryFn: () => notifications('?page=1'),
			staleTime: 60 * 60 * 1000,
		});
	};

	// Prefect Notifications
	useEffect(() => {
		prefetchMotifs();
	}, []);

	// Handle Notification Count and Notification Sound Interval
	useEffect(() => {
		let intervalId;
		const notificationInterval = localStorage.getItem('bms_notif_interval')
			? localStorage.getItem('bms_notif_interval')
			: 60000;

		if (data?.counts?.unread !== 0) {
			intervalId = setInterval(() => {
				toggle();
			}, notificationInterval);
		} else {
			clearInterval(intervalId);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [data?.counts?.unread, toggle]);

	const handleLogout = () => {
		logoutUser(dispatch);
		history.replace('/login');
	};

	return (
		<header id='page-topbar'>
			<div className='navbar-header'>
				<div className='d-flex'>
					{/* <!-- LOGO --> */}
					<div className='navbar-brand-box'>
						<Link to='/' className='logo logo-dark'>
							<span className='logo-sm'>
								<img src={logo} alt='' width='50' />
							</span>
							<span className='logo-lg'>
								<img src={logo} alt='' width='65' />
							</span>
						</Link>

						<Link to='/' className='logo logo-light'>
							<span className='logo-sm'>
								<img src={logo} alt='' height='22' />
							</span>
							<span className='logo-lg'>
								<img src={logo} alt='' height='19' />
							</span>
						</Link>
					</div>

					<button
						type='button'
						className='btn btn-sm px-3 font-size-16 header-item waves-effect'
						id='vertical-menu-btn'
					>
						<i className='fa fa-fw fa-bars'></i>
					</button>
					<div className='header-navbar-title font-size-18'>
						Benefits Management System
					</div>
				</div>

				<div className='d-flex align-items-center'>
					<Link to='/notifications' className='mr-4'>
						<div className='btn btn-link text-danger'>
							{data?.counts?.unread === 0 ? (
								<span>
									{location === '/notifications' ? (
										<i className='fa fa-bell font-size-24 mr-1'></i>
									) : (
										<i className='far fa-bell font-size-24 mr-1'></i>
									)}
								</span>
							) : (
								<span>
									{/* <button onClick={toggle}>{playing ? 'Pause' : 'Play'}</button> */}
									{location === '/notifications' ? (
										<lord-icon
											src='https://cdn.lordicon.com/msetysan.json'
											trigger='loop'
											state='loop'
											colors='primary:#f46969'
										></lord-icon>
									) : (
										<lord-icon
											src='https://cdn.lordicon.com/psnhyobz.json'
											trigger='loop'
											state='loop'
											colors='primary:#f46969'
										></lord-icon>
									)}

									<span
										className='position-absolute font-size-12 font-weight-bold'
										style={{
											fontFamily: 'Poppins',
										}}
									>
										{data?.counts.unread}
									</span>
								</span>
							)}
						</div>
					</Link>

					<div className='dropdown d-inline-block'>
						<button
							type='button'
							className='btn header-item waves-effect'
							id='page-header-user-dropdown'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'
						>
							<img
								className='rounded-circle header-profile-user'
								src={`${baseUrl}assets/images/icon-user.svg`}
								alt=''
							/>
							<span className='d-none d-xl-inline-block ml-1'>
								{userData?.first_name} {userData?.last_name}
							</span>
							<i className='mdi mdi-chevron-down d-none d-xl-inline-block'></i>
						</button>
						<div className='dropdown-menu dropdown-menu-right'>
							{/* <!-- item--> */}
							<Link className='dropdown-item' to='/change-password'>
								<i className='bx bx-wrench font-size-16 align-middle mr-1'></i>
								Settings
							</Link>
							<Link className='dropdown-item' to='/change-password'>
								<i className='bx bx-lock-open font-size-16 align-middle mr-1'></i>
								Change Password
							</Link>
							<div className='dropdown-divider'></div>
							<Link
								className='dropdown-item text-danger'
								onClick={handleLogout}
								to='/login'
							>
								<i className='bx bx-power-off font-size-16 align-middle mr-1 text-danger'></i>
								Logout
							</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};
export default withRouter(HeaderNavbar);
