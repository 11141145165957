import * as React from 'react';
import AppContext from '../../../context';
import {
	packages,
	packageCreate,
	packageUpdate,
	packageDelete,
	benefits as selectBenefits,
} from '../../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import Form from './../form';
import useModal from './../../../hooks/useModal';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMsg, Currency } from './../../../components/Components';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initValues = {
	state: 'Edo State',
	benefits: [],
};

export default function RegisterInvoice({
	currentIndex,
	handleSubmitPayment,
	values,
}) {
	const { dispatch, paymentMethods, checkArray, exemptions } =
		React.useContext(AppContext);
	const [invoice, setInvoice] = React.useState(null);
	const [error, setError] = React.useState(null);
	//const [payment_method, setPaymentMethod] = React.useState(null);
	const [exemption_id, set_exemption_id] = React.useState(null);
	const [paymentMethodData, setPaymentMethodData] = React.useState(null);
	const [filter_plan, set_filter_plan] = React.useState('');
	const { register, errors, handleSubmit, control, watch, setValue } = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		defaultValues: {
			//payment_method: filter_plan,
			//payment_method: 'bank_payment',
		},
	});

	const payment_method = watch('payment_method');

	React.useEffect(() => {
		setInvoice(values?.invoice);
		// set_filter_plan(
		//   values?.plan?.package?.is_public === 1 &&
		//     values?.enrollee?.corporate_id === ""
		//     ? "bank_payment"
		//     : values?.plan?.package?.is_public === 1 &&
		//       values?.enrollee?.corporate_id !== ""
		//     ? "corporate"
		//     : "exemption"
		// );

		// console.log(values?.enrollee? ,"crp");

		set_filter_plan(
			values?.plan?.package?.name === 'State Equity Health Plan' ||
				values?.plan?.package?.name === 'Equity Health Plan' ||
				values?.plan?.package?.name === 'BMGF Health Plan' ||
				values?.plan?.package?.name === 'Pensioners Health Plan'
				? 'exemption'
				: values?.enrollee?.corporate_id
				? 'corporate'
				: values?.plan?.package?.is_public === 0
				? 'exemption'
				: 'bank_payment'
		);
	}, [values?.invoice]);

	/* React.useEffect(() => {
        if(paymentMethods && paymentMethods.length > 0){
            setPaymentMethodData(paymentMethods.filter(dt => dt.value === filter_plan));
        }
    }, [paymentMethods]); */

	React.useEffect(() => {
		setValue('payment_method', filter_plan);
		if (paymentMethods && paymentMethods.length > 0) {
			setPaymentMethodData(
				paymentMethods.filter((dt) => dt.value === filter_plan)
			);
		}
	}, [filter_plan, paymentMethods, setValue, values]);

	const onSubmit = (data) => {
		data = {
			...data,
			transaction_id: invoice?.transaction_id,
			amount: invoice?.amount,
		};

		handleSubmitPayment(data);
	};

	return (
		<>
			{currentIndex === 3 && (
				<>
					<h5 className='text-dark mb-3'>Invoice Details</h5>

					<div className='invoice-title clearfix'>
						<h4 className='float-right font-size-16'>
							Invoice # {invoice?.transaction_id}
						</h4>
						{/* <div className="mb-4">
                    <img src="assets/images/logo-dark.png" alt="logo" height="20" />
                </div> */}
					</div>

					<hr />

					<div className='row'>
						<div className='col-6'>
							<address>
								<strong>Enrollee Details:</strong>
								<br />
								{invoice?.enrollee?.first_name} {invoice?.enrollee?.middle_name}{' '}
								{invoice?.enrollee?.last_name}
								<br />
								{invoice?.enrollee?.address}
								<br />
								{invoice?.enrollee?.lga}, {invoice?.enrollee?.state}
							</address>
						</div>
						<div className='col-6 text-right'>
							<address>
								<strong>Invoice Date:</strong>
								<br />
								{moment(invoice?.created_at).format('ll')}
								<br />
							</address>
						</div>
					</div>
					<div className='row'>
						<div className='col-6 mt-3'>
							<address>
								<strong>Package:</strong>
								<br />
								<b>{invoice?.package?.name}</b>
								<br />
								{<Currency value={invoice?.amount} />}
							</address>
						</div>
					</div>

					<h5 className='text-dark py-3'>Payment Method</h5>
					<form
						id='form-registration-invoice'
						onSubmit={handleSubmit(onSubmit)}
					>
						{checkArray(paymentMethodData) &&
							paymentMethodData.map((row, index) => {
								return (
									<div
										key={row.value}
										className='custom-control custom-radio custom-control-select'
									>
										<input
											type='radio'
											id={`method-${row.value}`}
											value={row.value}
											disabled={row.disabled}
											name='payment_method'
											className='custom-control-input'
											ref={register({
												required: 'Please select a payment method!',
											})}
										/>
										<label
											className='custom-control-label custom-control-label-select custom-control-label-select-single'
											htmlFor={`method-${row.value}`}
										>
											<span>{row.label}</span>
										</label>
									</div>
								);
							})}
						<ErrorMsg errors={errors} name='payment_method' />

						<div className='row'>
							{/* {getValues("payment_method") !== 'exemption' && getValues("payment_method") !== '' && (
                        <div className="form-group col-12">
                            <label>Teller Ref.</label>
                            <input type="text" className="form-control"
                                name="teller_ref"
                                placeholder="Teller Ref."
                                ref={register({
                                    required: "Teller ref. is required!"
                                })}
                            />
                            <ErrorMsg errors={errors} name="teller_ref" />
                        </div>)} */}

							{payment_method === 'exemption' && (
								<>
									<div className='form-group col-12'>
										<label>Exemption Category</label>

										<select
											className='form-control'
											name='exemption_category'
											onChange={(e) => set_exemption_id(e.target.value)}
											ref={register({
												required: 'Exemption is required!',
											})}
										>
											<option value=''> - Select -</option>
											{checkArray(exemptions) > 0 &&
												exemptions.map((row, index) => {
													return (
														<option key={row.value} value={row.value}>
															{row.label}
														</option>
													);
												})}
										</select>

										<ErrorMsg errors={errors} name='exemption_category' />
									</div>

									{exemption_id === '1' && (
										<div className='form-group col-12'>
											<label>Employee ID</label>
											<input
												type='text'
												className='form-control'
												name='employee_number'
												placeholder='Employee ID'
												ref={register({
													required: 'Employee ID is required!',
												})}
											/>
											<ErrorMsg errors={errors} name='employee_number' />
										</div>
									)}
								</>
							)}

							{payment_method === 'bank_payment' && (
								<>
									<div className='form-group col-12'>
										<label>Bank Name</label>
										<input
											type='text'
											className='form-control'
											name='bank_name'
											placeholder='Bank Name'
											ref={register({
												required: 'Bank name is required!',
											})}
										/>
										<ErrorMsg errors={errors} name='bank_name' />
									</div>

									<div className='form-group col-12'>
										<label>Teller No.</label>
										<input
											type='text'
											className='form-control'
											name='teller_ref'
											placeholder='Teller No.'
											ref={register({
												required: 'Teller No. is required!',
											})}
										/>
										<ErrorMsg errors={errors} name='teller_ref' />
									</div>

									<div className='form-group col-12 form-date-picker'>
										<label>Payment Date</label>
										<Controller
											name='payment_date'
											control={control}
											rules={{ required: 'Payment date is required!' }}
											render={({ onChange, value }) => (
												<DatePicker
													selected={value}
													onChange={onChange}
													className='form-control'
													placeholderText='Select payment date'
													dateFormat='MMMM d, yyyy'
												/>
											)}
										/>
										<ErrorMsg errors={errors} name='payment_date' />
									</div>
								</>
							)}
						</div>
						<div className='text-right'>
							<button type='submit' className='btn btn-success btn-main'>
								Submit Registration
							</button>
						</div>
					</form>
				</>
			)}
		</>
	);
}
