import * as React from 'react';
import AppContext from '../../../context';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment/moment';

import { accessRoles } from '../../../utils/utils';

import {
	approveFacilityChange,
	declineFacilityChange,
	fetchFacilityChangeComments,
	createFacilityChangeComments,
} from '../../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const roles = accessRoles('facility-change');

export default function HistoryDetails({
	handleCloseModal,
	changeDetails,
	setCurrentPage,
	query,
	queryName,
	history,
}) {
	const { dt, notify, dispatch, errorResponse, userData } =
		React.useContext(AppContext);

	const [showQuery, setShowQuery] = React.useState(false);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [reason, setReason] = React.useState('');
	const [startRequest, setStartRequest] = React.useState('');
	const [commentQ, setCommentQ] = React.useState('');

	const queryClient = useQueryClient();

	const { uuid, created_at, enrollee, from, to, status, comment } =
		changeDetails;

	const {
		mutate: handleApprove,
		status: approveStatus,
		isLoading: approveLoading,
	} = useMutation((values) => approveFacilityChange(values), {
		onSuccess: () => {
			handleCloseModal(false);
			notify(
				'success',
				'Change Request Approved',
				'Facility change request successfully approved!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			queryClient.invalidateQueries({ queryKey: ['totalChanges'] });
			setCurrentPage('pending-history');
		},
	});

	const {
		mutate: handleDecline,
		status: declineStatus,
		isLoading: declineLoading,
	} = useMutation((values) => declineFacilityChange(values), {
		onSuccess: () => {
			handleCloseModal(false);
			notify(
				'success',
				'Change Request Declined',
				'Facility change request successfully declined!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			queryClient.invalidateQueries({ queryKey: ['totalChanges'] });
			setCurrentPage('pending-history');
		},
	});

	const { data: comments, error } = useQuery(
		['fetchComments', changeDetails.uuid],
		() => fetchFacilityChangeComments(uuid),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, dispatch, history }),
			enabled: showQuery,
		}
	);

	const { mutate: handleSubmitQuery } = useMutation(
		(
			id,
			data = {
				comments: [
					{
						comment: commentQ,
					},
				],
			}
		) => createFacilityChangeComments(id, data),
		{
			onSuccess: () => {
				if (showConfirm) {
					if (reason === 'approve') {
						setStartRequest('approve-req');
					} else {
						setStartRequest('decline-req');
					}
				}

				notify(
					'success',
					'Comment Submitted',
					'Comment successfully submitted!'
				);
			},
			onError: (error) => {
				if (error.response.status === 422) {
					notify('danger', 'Comment not Submitted', 'Please provide a comment');
				} else {
					errorResponse({ error, dispatch });
				}
			},
			onSettled: () =>
				queryClient.refetchQueries(['fetchComments', changeDetails.uuid]),
		}
	);

	const initApprove = async () => {
		setShowConfirm(true);
		setReason('approve');
	};

	const initApproveReq = async (id) => {
		const data = {
			change_requests: [id],
		};

		handleApprove(data);
	};

	const initDecline = async () => {
		setShowConfirm(true);
		setReason('decline');
	};

	const initDeclineReq = async (id) => {
		const data = {
			change_requests: [id],
		};

		handleDecline(data);
	};

	if (changeDetails === null || changeDetails === undefined) {
		return <p>Loading...</p>;
	}

	return (
		<>
			{showQuery ? (
				<>
					<Modal.Body>
						<div className='row'>
							<div className='col-12 text right'>
								<div className='card'>
									<div className='h3 m-0'>Query/Comments</div>
									<div className='card-body'>
										<div className='my-3'>
											{comments &&
												comments.data.map((comment) => (
													<div className='row'>
														<div className='col-9'>
															<p
																className='m-0 my-1'
																style={{ fontWeight: 'bold' }}
															>
																<span className='fw-bold text-primary mx-2'>{`${comment.author.first_name} ${comment.author.last_name}: `}</span>{' '}
																{comment.comment}
															</p>
														</div>
														<div className='col-3'>
															<p className='text-muted m-0'>
																{moment(comment.created_at).format(
																	'DD MMM YYYY'
																)}
															</p>
														</div>
													</div>
												))}
										</div>
										{status === 'pending' && (
											<div className='form-group col-12'>
												<h6 className='text-muted mb-3'>Query</h6>

												<textarea
													className='form-control'
													name='query'
													cols='30'
													rows='5'
													placeholder='Your query here'
													onChange={(e) => setCommentQ(e.target.value)}
												></textarea>

												<div
													className='d-flex flex-row float-right'
													style={{ gap: '10px' }}
												>
													<button
														className='btn mt-3'
														onClick={() => setShowQuery(!showQuery)}
														style={{
															background: '#ccc',
															color: '#fff',
															// width: '20px',
														}}
													>
														Back
													</button>

													<button
														className='btn mt-3'
														onClick={() => handleSubmitQuery(uuid)}
														style={{
															background: '#389e7f',
															color: '#fff',
															// width: '20%',
														}}
													>
														Submit Comment
													</button>
												</div>
											</div>
										)}
										{status !== 'pending' && (
											<div
												className='d-flex flex-row float-right'
												style={{ gap: '10px' }}
											>
												<button
													className='btn mt-3'
													onClick={() => setShowQuery(!showQuery)}
													style={{
														background: '#ccc',
														color: '#fff',
														// width: '20px',
													}}
												>
													Back
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</>
			) : showConfirm ? (
				<Modal.Body>
					<div className='row'>
						<div className='col-12'>
							<h5 className='text-muted mb-3'>
								Please add a Comment with your reason of {'  '}
								{reason === 'approve' ? 'approving' : 'declining'}
								{'  '}
								this request.
							</h5>

							{status === 'pending' && (
								<div className='form-group col-12'>
									<textarea
										className='form-control'
										name='query'
										cols='30'
										rows='5'
										placeholder='Your comment here'
										onChange={(e) => setCommentQ(e.target.value)}
									></textarea>

									<div
										className='d-flex flex-row float-right'
										style={{ gap: '10px' }}
									>
										<button
											className='btn mt-3'
											onClick={() => {
												setShowConfirm(!showConfirm);
												setStartRequest('');
											}}
											style={{
												background: '#ccc',
												color: '#fff',
											}}
										>
											Back
										</button>

										{startRequest === '' && (
											<button
												className='btn mt-3'
												onClick={() => handleSubmitQuery(uuid)}
												style={{
													background: '#389e7f',
													color: '#fff',
												}}
											>
												Submit Comment
											</button>
										)}

										{startRequest !== '' && (
											<button
												className='btn mt-3'
												onClick={() =>
													startRequest === 'approve-req'
														? initApproveReq(uuid)
														: initDeclineReq(uuid)
												}
												style={{
													background: '#389e7f',
													color: '#fff',
												}}
											>
												{startRequest === 'approve-req' ? 'Approve' : 'Decline'}
											</button>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			) : (
				<>
					<Modal.Body>
						<div className='row'>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Enrollee</h6>
								<b>
									{enrollee?.first_name} {enrollee?.last_name}
								</b>
							</div>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1'>Enrollee ID</h6>
								<b>{enrollee?.insurance_id}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>Date of Birth</h6>
								<b>{enrollee?.dob}</b>
							</div>
							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>Gender</h6>
								<b>{enrollee?.gender}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>Office Address</h6>
								<b>{enrollee?.office_address}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>Residential Address</h6>
								<b>{enrollee?.address}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>Phone</h6>
								<b>{enrollee?.phone}</b>
							</div>

							<div className='col-md-6 text right'>
								<h6 className='text-muted mb-1 mt-3'>MDA</h6>
								<b>{enrollee?.business_name}</b>
							</div>

							<div className='col-12'>
								<hr />
								<h5 className='text-primary mb-2'>Facility Change Info</h5>

								<div className='row mb-3'>
									<div className='col-md-6 text right'>
										<h6 className='text-muted mb-1'>From</h6>
										<b>{from?.name}</b>
									</div>

									<div className='col-md-6 text right'>
										<h6 className='text-muted mb-1'>Created Date</h6>
										<b>{moment(created_at).format('DD/MM/YYYY')}</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-md-6 text right'>
										<h6 className='text-muted mb-1'>To</h6>
										<b>{to?.name}</b>
									</div>

									<div className='col-md-6 text right'>
										<h6 className='text-muted mb-1'>Status</h6>
										<b>{status}</b>
									</div>
								</div>

								<div className='row mb-3'>
									<div className='col-12 text right'>
										<h6 className='text-muted mb-1'>Comment</h6>
										<div className='card'>
											<div className='card-body'>
												<b>{comment || 'No Comments yet.'}</b>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='w-100 d-flex flex-row justify-content-center my-3'>
								<Button
									type='button'
									onClick={() => {
										setShowQuery(true);
									}}
									className='mx-2 bg-warning'
									data-toggle='tooltip'
									data-placement='top'
									title='Use this to ask questions and get answers relating to this facility change request.'
									style={{
										// background: '#fce45d',
										color: '#fff',
										width: '20%',
										borderRadius: '15px',
										border: 'none',
									}}
								>
									Query
								</Button>
								{status === 'pending' && (
									<>
										{roles.includes(userData.role.name) && (
											<>
												<Button
													type='button'
													onClick={() => {
														initApprove(uuid);
													}}
													disabled={approveLoading ? true : false}
													className='mx-2'
													style={{
														background: '#389e7f',
														color: '#fff',
														width: '30%',
													}}
												>
													{approveLoading ? 'approving...' : 'Approve'}
												</Button>
												<Button
													type='button'
													onClick={() => {
														initDecline(uuid);
													}}
													disabled={declineLoading ? true : false}
													className='btn-danger'
													style={{
														width: '30%',
													}}
												>
													{declineLoading ? 'declining...' : 'Decline'}
												</Button>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<input
							type='button'
							onClick={handleCloseModal}
							className='btn btn-default'
							value='Close'
						/>
					</Modal.Footer>
				</>
			)}
		</>
	);
}
