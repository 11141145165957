import * as React from 'react';
import AppContext from '../../context';
import {
	corporates,
	corporatesCreate,
	corporatesUpdate,
	adminUserDelete,
	adminUsers,
	corporatesActivate,
	corporatesDeactivate,
	addCorporatePayment,
} from '../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Form from './form';
import PaymentForm from './addPaymentForm';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Currency, Paginate } from './../../components/Components';
import CorporateEnrollees from './corporateEnrollees';

const initValues = {
	no_of_employees: 1,
	waiting_period: 90,
};

export default function Corporates({ history }) {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = React.useState('corporates');
	const [queryName] = React.useState('corporates');
	const [formID] = React.useState('form-corporate');

	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const { toggleFormState } = useFormState(formID);

	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		currentPath,
		formActionType,
		errorResponse,
		formatDate,
		notify,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);
	const [showAdd, setShowAdd] = React.useState(false);
	const [selected, setSelected] = React.useState('');
	const [selectedName, setSelectedName] = React.useState('');

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	const handleCloseModal = () => {
		closeModal(false);
		setDefaultValues(initValues);
	};

	/* Page data */
	const { isLoading, isFetching, isError, data, error } = useQuery(
		[queryName, query],
		() => corporates(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const {
		mutate: handleSubmitAdd,
		status: addStatus,
		isLoading: addLoading,
	} = useMutation((values) => corporatesCreate(values), {
		onSuccess: () => {
			handleCloseModal();
			notify(
				'success',
				'User Account Added',
				'New admin user successfully added!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			toggleFormState(false);
		},
	});

	/* Activate*/
	const {
		mutate: handleActivate,
		status: activateStatus,
		isLoading: activateLoading,
	} = useMutation((values) => corporatesActivate(values), {
		onSuccess: () => {
			notify(
				'success',
				'Corporate Account Activated',
				'Corporate successfully activated!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
		},
	});

	/* DeActivate*/
	const {
		mutate: handleDeactivate,
		status: deActivateStatus,
		isLoading: deActivateLoading,
	} = useMutation((values) => corporatesDeactivate(values), {
		onSuccess: () => {
			notify(
				'success',
				'Corporate Account Deactivated',
				'Corporate successfully Deactivated!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
		},
	});

	/*Add payment */
	const {
		mutate: handleAddPayment,
		status: paymentStatus,
		isLoading: paymentLoading,
	} = useMutation(({ id, values }) => addCorporatePayment({ id, values }), {
		onSuccess: () => {
			handleCloseModal();
			notify('success', 'Corporate Payment Added', 'Payment successfull!');
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			toggleFormState(false);
		},
	});

	/* Edit data */
	const {
		mutate: handleSubmitEdit,
		status: editStatus,
		isLoading: editLoading,
	} = useMutation(({ id, values }) => corporatesUpdate({ id, values }), {
		onSuccess: () => {
			handleCloseModal();
			notify(
				'success',
				'User Account Modified',
				'Admin user successfully modified!'
			);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
			toggleFormState(false);
		},
	});

	/* Delete data */
	const {
		mutate: handleSubmitDelete,
		status: deleteStatus,
		isLoading: deleteLoading,
	} = useMutation((id) => adminUserDelete(id), {
		onSuccess: () => {
			notify(
				'success',
				'User Account Deleted',
				'Admin user successfully deleted!'
			);
		},
		onMutate: (id) => {
			queryClient.cancelQueries([queryName, query]);
			const previousData = queryClient.getQueryData([queryName, query]);
			const updateValue = previousData?.data;

			const removeDeleted = updateValue.filter((dt) => dt.id !== id);
			const newData = { ...previousData, data: removeDeleted };
			return queryClient.setQueryData([queryName, query], newData);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => {
			queryClient.refetchQueries([queryName, query]);
		},
	});

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				adminUsers(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setStateData(dispatch, 'formActionType', 1);
		setModalTitle('Create Corporate');
		setModalState(true);
	};

	const initEdit = (data) => {
		setStateData(dispatch, 'formActionType', 2);
		setModalTitle('Edit Corporate');
		setDefaultValues(data);
		setModalState(true);
	};

	const initAddPayment = (data) => {
		setStateData(dispatch, 'formActionType', 3);
		setModalTitle('Add Payment');
		setDefaultValues(data);
		setModalState(true);
	};

	const onSubmit = (data) => {
		toggleFormState(true, 'saving...');
		if (formActionType === 1) {
			handleSubmitAdd(data);
		} else if (formActionType === 3) {
			handleAddPayment({ id: defaultValues.id, values: data });
		} else {
			handleSubmitEdit({ id: defaultValues.id, values: data });
		}
	};

	// const initDelete = (id) => {
	//   const conf = window.confirm("Are you sure?");
	//   if (!conf) return;
	//   handleSubmitDelete(id);
	// };

	const initActivate = (id) => {
		handleActivate(id);
	};
	const initDeactivate = (id) => {
		handleDeactivate(id);
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{currentPage === 'corporates' && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col float-right'>
										<div className='text-sm-right'>
											<button
												type='button'
												onClick={initAdd}
												className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
											>
												<i className='mdi mdi-plus mr-1'></i>Create Corporate
											</button>
										</div>
									</div>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th>Details</th>
												<th>Policy Duration</th>
												<th>Balance</th>
												<th className='text-center'>Status</th>
												<th className='text-right'>Created At</th>
												<th className='text-center'>Actions</th>
											</tr>
										</thead>
										<tbody>
											{data &&
												data?.data?.length > 0 &&
												data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															<td>
																<b>{row.name}</b>
																<br />
																{row.no_of_employees} employees
															</td>
															<td>{row.duration} months</td>
															<td>
																<Currency value={row.balance || 0.0} />
															</td>
															<td className='text-center'>
																<span
																	className={`badge ${
																		row?.status === 'active'
																			? 'badge-success'
																			: 'badge-danger'
																	} font-size-12`}
																>
																	{row?.status}
																</span>
															</td>
															<td className='text-right'>
																{formatDate(row.created_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDeactivate(row?.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-lock text-danger mr-1'></i>
																				Deactivate
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initActivate(row?.id)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-unlock-alt text-success mr-1'></i>{' '}
																				Activate
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initEdit(row)}
																				className='dropdown-item'
																			>
																				<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																				Edit
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					setShowAdd(true);
																					initAddPayment(row);
																				}}
																				className='dropdown-item'
																			>
																				<i className='fas fa-plus-square text-success mr-1'></i>{' '}
																				Add Payment
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					setSelected(row?.id);
																					setSelectedName(row?.name);
																					setCurrentPage('corpEnrollees');
																				}}
																				className='dropdown-item'
																			>
																				<i className='fas fa-users text-success mr-1'></i>{' '}
																				View Enrollees
																			</a>
																		</li>

																		{/* <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => initDelete(row.id)}
                                      className="dropdown-item"
                                    >
                                      <i className="fas fa-trash-alt text-danger mr-1"></i>{" "}
                                      Delete
                                    </a>
                                  </li> */}
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
								{data && data?.data?.length > 0 && (
									<Paginate data={data} onPageChange={handlePageClick} />
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			{currentPage === 'corpEnrollees' && (
				<CorporateEnrollees
					setCurrentPage={setCurrentPage}
					id={selected}
					history={history}
					name={selectedName}
				/>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				{showAdd ? (
					<PaymentForm
						handleCloseModal={handleCloseModal}
						defaultValues={defaultValues}
						onSubmit={onSubmit}
						formID={formID}
					/>
				) : (
					<Form
						handleCloseModal={handleCloseModal}
						defaultValues={defaultValues}
						onSubmit={onSubmit}
						formID={formID}
					/>
				)}
			</Modal>
		</>
	);
}
