import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data }) => {
	return (
		<ExcelFile
			filename='Claims'
			element={
				<button
					className='btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2'
					type='button'
				>
					<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
					Download file
				</button>
			}
		>
			<ExcelSheet data={data} name='Claims'>
				{
					<ExcelColumn
						label='Enrollee'
						value={(col) => col?.enrollee?.full_name}
					/>
				}
				{
					<ExcelColumn
						label='CIN'
						value={(col) => col?.enrollee?.insurance_id}
					/>
				}
				<ExcelColumn label='Facility' value={(col) => col?.facility?.name} />
				<ExcelColumn label='Amount' value='total_amount' />
				<ExcelColumn label='Submitted On' value='date_of_submission' />
				<ExcelColumn label='Auth Code' value='authorization_no' />
				<ExcelColumn label='Referral Code' value='referral_code' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExportExcel;
