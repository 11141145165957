import { useState, useEffect, useContext } from 'react';
import AppContext from '../../context';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Paginate } from '../../components/Components';

import { notifications, updateNotification } from '../../Services';

const Notifications = () => {
	const queryClient = useQueryClient();
	const history = useHistory();

	const { formatDate } = useContext(AppContext);

	const [query, setQuery] = useState('page=1');

	const { data, isLoading } = useQuery(
		['notifications', query],
		() => notifications(`?${query}`),
		{
			keepPreviousData: true,
		}
	);

	const prefetchNextPage = async (page = 1) => {
		const nextPage = page + 1;

		await queryClient.prefetchQuery(['notifications', `page=${nextPage}`], () =>
			notifications(`?page=${nextPage}`)
		);
	};

	useEffect(() => {
		prefetchNextPage(1);
	}, []);

	const handlePageClick = async ({ selected }) => {
		const page = selected + 1;

		setQuery(`page=${page}`);

		prefetchNextPage(page);
	};

	const { mutate: handleUpdate } = useMutation(
		([id, values]) => updateNotification(id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['totalNotifications']);
				queryClient.invalidateQueries(['notifications']);
			},
		}
	);

	const markAsRead = (id) => {
		handleUpdate([
			id,
			{
				read: true,
			},
		]);
	};

	const handleNotifInterval = (value) => {
		localStorage.setItem('bms_notif_interval', value);
		window.location.reload();
	};

	const regexClaim = /claims\/([^/]+)\/view/;
	const regexAuth = /auth-codes\/([^/]+)\/view/;
	const regexReferral = /referrals\/([^/]+)\/view/;

	if (isLoading) return <h4>Loading...</h4>;

	return (
		<div className='container'>
			<div className='row justify-content-end'>
				<div className='form-group'>
					<label>Notification Interval</label>
					<select
						className='form-control'
						onChange={(e) => handleNotifInterval(e.target.value)}
						defaultValue={localStorage.getItem('bms_notif_interval') || 60000}
					>
						<option value={60000}>1 minute</option>
						<option value={180000}>3 minutes</option>
						<option value={300000}>5 minutes</option>
					</select>
				</div>
			</div>

			{data?.total === 0 && (
				<div>
					<h5 className='font-weight-bold'>
						There are currently no Notifications
					</h5>
				</div>
			)}

			{data?.data && (
				<div>
					{data?.data?.map((notification) => (
						<div
							key={notification.id}
							className={`card p-3 mb-2 ${
								notification.read === 1 ? 'bg-transparent border' : ''
							} notifications-width`}
						>
							<div className='col-12'>
								<div className='row justify-content-between'>
									<div className='row px-3'>
										<h5
											className={`${
												notification.notification_type_id === 2
													? 'text-warning'
													: notification.notification_type_id === 3
													? 'text-success'
													: 'text-info'
											} ml-2 font-weight-bold text-capitalize`}
										>
											{notification.notification_type.name}
										</h5>
									</div>

									{notification.read === 0 && (
										<p
											className='p-0 btn btn-link'
											onClick={() => markAsRead(notification.id)}
										>
											Mark as Read
										</p>
									)}
								</div>

								<p
									className={`${
										notification.read === 1 ? 'border border-white' : 'border'
									} rounded py-2 px-3 m-0`}
									style={{
										color: '#aaa',
										cursor: 'pointer',
									}}
									onClick={() => {
										const id = notification.url.match(
											notification.notification_type_id === 2
												? regexClaim
												: notification.notification_type_id === 3
												? regexReferral
												: regexAuth
										)[1];

										markAsRead(notification.id);

										history.push(
											`${
												notification.notification_type_id === 2
													? `/claims?type=notification&id=${id}`
													: notification.notification_type_id === 3
													? `/referrals?type=notification&id=${id}`
													: `/authorizations?type=notification&id=${id}`
												// : '/authorizations?type=notification'
											}`
										);
									}}
								>
									{notification.message}

									<span className='d-block mt-2'>
										Facility: {notification.enrollee?.facilities[0].name}
									</span>
								</p>
								<span className='d-flex justify-content-end mt-2 text-primary	 font-size-12'>
									{formatDate(notification.created_at, 'llll')}
								</span>
							</div>
						</div>
					))}

					<div className='d-flex mt-3'>
						<Paginate data={data} onPageChange={handlePageClick} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Notifications;
