import * as React from 'react';
import AppContext from '../../../context';
import {
	inactiveEnrollees,
	enrolleeData,
	totals,
	enrolleeActivate,
} from '../../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Paginate } from './../../../components/Components';
import useModal from './../../../hooks/useModal';
import useFormState from './../../../hooks/useFormState';
import Details from './../details';
import useRouter from './../../../hooks/useRouter';
import { useForm } from 'react-hook-form';

const initValues = {
	services: [],
	contact: {},
};

export default function InactiveRegistrations() {
	const { register, errors, handleSubmit } = useForm({ criteriaMode: 'all' });
	const history = useHistory();
	const router = useRouter();

	const queryClient = useQueryClient();
	const {
		dispatch,
		setQueryString,
		useQueryString,
		notify,
		formActionType,
		errorResponse,
		formatDate,
		currentPath,
	} = React.useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = React.useState(initValues);
	const searchRef = React.useRef();

	const [queryName] = React.useState('inactive-enrollees');
	const [formID] = React.useState('form-enrollee');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const [searchValues, setSearchValues] = React.useState({});
	const [meta, setMeta] = React.useState({});
	const [enrolleeDetails, setEnrolleeDetails] = React.useState({});

	/* const [query, setQuery] = React.useState({
        page: (queryString.get("page") && !isNaN(queryString.get("page"))
            ? Number(queryString.get("page")) : 1)
    }); */

	const [query, setQuery] = React.useState(router.query);

	const handleCloseModal = () => {
		setModalState(false);
		setDefaultValues(initValues);
	};

	/* Page data */
	const { isLoading, isFetching, isError, data, error } = useQuery(
		[queryName, query],
		() => inactiveEnrollees(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
			//onSuccess: () =>
		}
	);

	// Totals
	const { data: totalsData, isLoading: totalsLoading } = useQuery(
		'total-count',
		() => totals(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data && data.total > 0) {
			const newMeta = { from: data?.from, to: data?.to, total: data?.total };
			setMeta(newMeta);
		}

		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				inactiveEnrollees(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	React.useEffect(() => {
		const rQquery = router.query;
		setQuery(rQquery);
		setSearchValues(rQquery);

		/* Clean up */
		return () => setQuery({});
	}, [router.query]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initDetails = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);

		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;

		setEnrolleeDetails(row);
		setModalTitle(title);
		setModalState(true);
	};

	const continueReg = (data) => {
		history.push('/enrollee/create', { continueData: data });
	};

	const handleSearchChange = (e) => {
		const { name, value } = e.target;
		setSearchValues({ ...searchValues, [name]: value });
	};

	const onSearchSubmit = (values) => {
		let newSearch = {};
		Object.entries(values).forEach(async ([key, value]) => {
			console.log(value, 'value...');
			if (value) {
				newSearch = { ...newSearch, [key]: value };
			}
		});

		if (Object.keys(newSearch).length === 0) {
			alert('At least one search value is required!');
			return;
		}

		history.push(`${currentPath}${setQueryString(newSearch)}`);
	};

	const { mutate: handleActivate } = useMutation((id) => enrolleeActivate(id), {
		onSuccess: () => {
			notify(
				'success',
				'Enrollee Activated',
				'Enrollee successfully activated!'
			);
		},
		onMutate: (id) => {
			queryClient.cancelQueries([queryName, query]);
		},
		onError: (error) => errorResponse({ error, dispatch }),
		onSettled: () => queryClient.refetchQueries([queryName, query]),
	});

	const clearSearch = () => {
		setSearchValues({});
		setQuery({});
		history.push(currentPath);
	};

	const handleStatusActivate = (id) => {
		handleActivate(id);
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='pb-10 btn-tabs' style={{ display: 'flex' }}>
							<div className='col-sm-4'>
								<div
									className='dash-card dash-card-info dash-icon-4'
									style={{ alignItems: 'baseline' }}
								>
									<div className='dci-title'>
										Total Enrollees
										<div className='dci-content'>
											{totalsData && totalsData.sub_totals.toLocaleString()}
										</div>
									</div>
								</div>
							</div>

							<div className='col-sm-4'>
								<div
									className='dash-card dash-card-info dash-icon-2'
									style={{ alignItems: 'baseline' }}
								>
									<div className='dci-title'>
										Inactive Enrollees
										<div className='dci-content'>
											{totalsData &&
												totalsData.inactive_enrollees.toLocaleString()}
										</div>
									</div>
								</div>
							</div>

							
							{meta &&
								Object.keys(meta).length !== 0 &&
								meta.total !== totalsData.inactive_enrollees && (
									<div className='col-sm-4'>
										<div
											className='dash-card dash-card-info dash-icon-5'
											style={{ alignItems: 'baseline' }}
										>
											<div className='dci-title'>
												Search Results
												<div className='dci-content'>
													{meta && meta.total.toLocaleString()}
												</div>
											</div>
										</div>
									</div>
								)}
						</div>
						<div className='card'>
							<div className='card-body'>
								<form id='form-search' onSubmit={handleSubmit(onSearchSubmit)}>
									<div className='row mb-2'>
										<div className='input-group col-sm-6'>
											<input
												type='text'
												className='form-control mr-3'
												onChange={handleSearchChange}
												name='q'
												defaultValue={searchValues?.q}
												ref={register}
												placeholder='Enrollee name, Phone'
											/>
											<input
												type='text'
												className='form-control mr-3'
												onChange={handleSearchChange}
												ref={register}
												name='employee_number'
												defaultValue={searchValues?.employee_number}
												placeholder='Enrollee ID'
											/>
											<div className='input-group-append'>
												{(query.q || query.employee_number) && (
													<button
														className='btn btn-secondary'
														type='button'
														onClick={clearSearch}
													>
														<i class='mdi mdi-close'></i>
													</button>
												)}
												<button className='btn btn-success' type='submit'>
													Search
												</button>
											</div>
										</div>
									</div>
								</form>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div className='table-responsive'>
										<table className='table table-centered table-nowrap  table-striped'>
											<thead>
												<tr>
													<th width='15%'>Enrollee ID</th>
													<th width='25%'>Name</th>
													<th width='15%'>Phone</th>
													<th width='15%'>Enrollment Plan</th>
													<th width='15%'>Status</th>
													<th width='15%'>Start Date</th>
													<th width='14%'>End Date</th>
													<th width='1%' className='text-center'>
														<i className='mdi mdi-dots-horizontal font-size-18' />
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													return (
														<tr key={row.id}>
															{/* <td>{row.insurance_id}</td> */}
															<td>{row.id}</td>
															<td>
																{row.first_name} {row.last_name}
															</td>
															<td>{row.phone}</td>
															<td>
																{!row.package ? 'none' : row.package.name}
															</td>
															<td>{row.status}</td>

															<td>
																{formatDate(row.created_at, 'MMM DD, YYYY')}
															</td>
															<td>
																{formatDate(row.created_at, 'MMM DD, YYYY')}
															</td>

															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => initDetails(row)}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																		{row.enrolled_at === null ? (
																			<li>
																				<a
																					style={{ cursor: 'pointer' }}
																					onClick={() => continueReg(row)}
																					className='dropdown-item'
																				>
																					Continue Enrollment
																				</a>
																			</li>
																		) : (
																			<li>
																				<a
																					style={{ cursor: 'pointer' }}
																					onClick={() =>
																						handleStatusActivate(row.id)
																					}
																					className='dropdown-item'
																				>
																					Activate
																				</a>
																			</li>
																		)}
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										<div className='row'>
											<div className='col-sm-12 col-md-5'>
												Showing {meta?.from} - {meta?.to} of {meta?.total}{' '}
												Results
											</div>
											<Paginate data={data} onPageChange={handlePageClick} />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Details
					handleCloseModal={handleCloseModal}
					enrolleeDetails={enrolleeDetails}
				/>
			</Modal>
		</>
	);
}
