import * as React from 'react';
import AppContext from '../../../context';
import {
	packages,
	packagesList as selectPackages,
	packageCreate,
	packageUpdate,
	packageDelete,
	benefits as selectBenefits,
} from '../../../Services';
import { useQuery, useMutation, useQueryClient, queryCache } from 'react-query';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import Form from './../form';
import useModal from './../../../hooks/useModal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMsg, Currency } from './../../../components/Components';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initValues = {
	is_family_plan: 'false',
	start_date: new Date(),
};

export default function RegisterPackage({
	currentIndex,
	handleSubmitPackage,
	packages,
	values,
}) {
	const { dispatch, checkArray, checkObject, capitalize } =
		React.useContext(AppContext);
	const { register, errors, handleSubmit, control, watch } = useForm({
		defaultValues: initValues,
		criteriaMode: 'all',
		mode: 'onChange',
	});

	// const watchPackageID = watch('package_id');

	const [dependantCount, setDependantCount] = React.useState(0);
	const [durationCount, setDurationCount] = React.useState(1);
	const [benefits, setBenefits] = React.useState([]);
	const [is_family_plan, set_is_family_plan] = React.useState(
		initValues.is_family_plan
	);
	const [packageId, setPackageId] = React.useState(null);
	const [packagesCus, setPackagesCus] = React.useState(null);

	React.useEffect(() => {
		let package_id = watch('package_id');
		setPackageId(package_id);

		// return () => package_id.unsubscribe();
	}, [watch]);

	if (typeof packages === 'undefined') {
		packages = packagesCus;
	}

	/* Modal hook */
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();

	const showPlan = ({ benefits, name }) => {
		const groupData = benefits.reduce(
			(h, dt) =>
				Object.assign(h, {
					[dt.type]: (h[dt.type] || []).concat({ id: dt.id, name: dt.name }),
				}),
			{}
		);
		setBenefits(groupData);
		setModalTitle(name);
		setModalState(true);
	};

	/* Packages */
	useQuery('select-packages', selectPackages, {
		onError: (error) => setPackagesCus([]),
		onSuccess: (data) => setPackagesCus(data),
	});

	return (
		<>
			{currentIndex === 2 && (
				<>
					<h3 className='text-dark mb-2'>Select Package</h3>
					<p className='mb-3 text-muted'>
						Select an insurance place which suits your need.
					</p>

					<form
						id='form-register-package'
						onSubmit={handleSubmit(handleSubmitPackage)}
					>
						<div className='row'>
							<div className='form-group col-md-4'>
								<label>Plan Type</label>
								<select
									className='form-control'
									name='is_family_plan'
									onChange={(e) => set_is_family_plan(e.target.value)}
									//onChange={e => console.log(e.target.value, 'true', 'e.target.value')}
									ref={register}
								>
									<option value='false'>Standard Plan</option>
									<option value='true'>Family Plan</option>
								</select>
							</div>

							<div className='form-group col-md-4 form-date-picker'>
								<label>
									Start Date <span className='text-danger'>*</span>
								</label>
								<Controller
									name='start_date'
									control={control}
									rules={{ required: 'Start date is required!' }}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className='form-control'
											placeholderText='Select start date'
											dateFormat='MMMM d, yyyy'
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='start_date' />
							</div>

							<div className='form-group col-md-4'>
								<label>
									Duration <span className='text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='duration'
									ref={register({
										required: 'Duration is required!',
									})}
									onChange={(e) =>
										setDurationCount(Number(e.target.value / 12))
									}
								>
									<option value=''>- Select -</option>
									<option value='12'>1 Year</option>
									<option value='24'>2 Years</option>
									<option value='36'>3 Years</option>
								</select>
								<ErrorMsg errors={errors} name='duration' />
							</div>
						</div>

						<hr />
						{is_family_plan === 'true' ? (
							<>
								<div className='form-group '>
									<label>Number of Dependants</label>
									<input
										type='text'
										className='form-control'
										name='dependant_count'
										placeholder='Input number of Dependants'
										ref={register({
											required: 'Number of Dependants',
										})}
										onChange={(e) => {
											setDependantCount(e.target.value);
										}}
									/>
								</div>
								<hr />
							</>
						) : (
							''
						)}

						{packages.length > 0
							? packages
									.filter((pack) => {
										if (values?.enrollee?.corporate_id) {
											return pack?.is_public === 1;
										} else {
											if (is_family_plan === 'true') {
												return pack?.is_family_allowed === 1;
											}
											return true;
										}
									})
									.map((row, index) => (
										<div
											key={row.id}
											className='custom-control custom-radio custom-control-select'
										>
											<input
												type='radio'
												id={`plan-${row.id}`}
												value={row.id}
												name='package_id'
												className='custom-control-input'
												ref={register({
													required: 'Please select a plan!',
												})}
											/>
											<label
												className='custom-control-label custom-control-label-select'
												htmlFor={`plan-${row.id}`}
											>
												<span>{row.name}</span>
												{is_family_plan === 'true' && (
													<span>
														<Currency
															value={
																dependantCount > 0
																	? Number(packageId) === row.id
																		? row.amount *
																		  (+1 + +dependantCount) *
																		  durationCount
																		: row.amount * (+1 + +dependantCount)
																	: row.family_amount
															}
														/>
													</span>
												)}
												{is_family_plan === 'false' && (
													<span>
														{/* {console.log(row.id, Number(packageId))} */}
														<Currency
															value={
																Number(packageId) === row.id
																	? row.amount * durationCount
																	: row.amount
															}
														/>
													</span>
												)}
												<span onClick={() => showPlan(row)}>View Plan</span>
											</label>
										</div>
									))
							: ''}
						<ErrorMsg errors={errors} name='package_id' />

						<div className='text-right'>
							<button
								type='submit'
								disabled={!checkArray(packages)}
								className='btn btn-success btn-main'
							>
								Continue
							</button>
						</div>
					</form>
				</>
			)}

			<Modal show={modalState} onHide={closeModal} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{checkObject(benefits) && (
						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							{Object.entries(benefits).map((value, key) => {
								return (
									<React.Fragment key={value[0]}>
										<thead>
											<tr>
												<th width='100%'>{capitalize(value[0])}</th>
											</tr>
										</thead>
										<tbody>
											{value[1] &&
												value[1].map((row, index) => {
													return (
														<tr key={row.id}>
															<td scope='row'>{row.name}</td>
														</tr>
													);
												})}
										</tbody>
									</React.Fragment>
								);
							})}
						</table>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
