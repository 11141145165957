import * as React from 'react';
import { useQuery } from 'react-query';

import { facilityChangeHistoryCount } from '../../../Services';
import AppContext from '../../../context';

import PendingFacilityChanges from './pendingChangeHistory';
import RejectedFacilityChanges from './rejectedChangeHistory';
import ApprovedFacilityChanges from './approvedChangeHistory';
import AllFacilityChanges from './allChangeHistory';

export default function EnrolleeFacilityChange({ history }) {
	const [currentPage, setCurrentPage] = React.useState('all-history');

	const { dispatch, useQueryString, errorResponse } =
		React.useContext(AppContext);

	let queryString = useQueryString();

	const [query, setQuery] = React.useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
	});

	const {
		data: totalChangeCount,
		error,
		isLoading,
	} = useQuery(['totalChanges'], () => facilityChangeHistoryCount(), {
		keepPreviousData: true,
		staleTime: 5000,
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	if (isLoading) {
		return <p>loading...</p>;
	}

	const { all, approved, declined, pending } = totalChangeCount;

	return (
		<>
			{totalChangeCount && (
				<div className='pb-10 btn-tabs' style={{ display: 'flex' }}>
					<div className='col-sm-3'>
						<div
							className={`btn dash-card dash-card-info dash-icon-4 ${
								currentPage === 'all-claims' ? 'btn-tabbed' : ''
							} btn-sm`}
							onClick={() => {
								setQuery({ page: 1 });
								setCurrentPage('all-history');
							}}
							style={{ alignItems: 'baseline' }}
						>
							<div className='dci-title'>
								All Changes
								<div className='dci-content'>{all ? all : 0}</div>
							</div>
						</div>
					</div>

					<div className='col-sm-3'>
						<div
							className={`btn dash-card dash-card-info dash-icon-1 ${
								currentPage === 'approved-history' ? 'btn-tabbed' : ''
							} btn-sm`}
							onClick={() => {
								setQuery({ page: 1 });
								setCurrentPage('approved-history');
							}}
							style={{ alignItems: 'baseline' }}
						>
							<div className='dci-title'>
								Approved Changes
								<div className='dci-content'>{approved ? approved : 0}</div>
							</div>
						</div>
					</div>

					<div className='col-sm-3'>
						<div
							className={`btn dash-card dash-card-info dash-icon-5 ${
								currentPage === 'pending-history' ? 'btn-tabbed' : ''
							} btn-sm`}
							onClick={() => {
								setQuery({ page: 1 });
								setCurrentPage('pending-history');
							}}
							style={{ alignItems: 'baseline' }}
						>
							<div className='dci-title'>Pending Changes</div>
							<div className='dci-content'>{pending ? pending : 0}</div>
						</div>
					</div>

					<div className='col-sm-3'>
						<div
							className={`btn dash-card dash-card-info dash-icon-2 ${
								currentPage === 'rejected-history' ? 'btn-tabbed' : ''
							} btn-sm`}
							onClick={() => {
								setQuery({ page: 1 });
								setCurrentPage('rejected-history');
							}}
							style={{ alignItems: 'baseline' }}
						>
							<div className='dci-title'>Rejected Changes</div>
							<div className='dci-content'>{declined ? declined : 0}</div>
						</div>
					</div>
				</div>
			)}

			{currentPage === 'all-history' && (
				<AllFacilityChanges
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'approved-history' && (
				<ApprovedFacilityChanges
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'pending-history' && (
				<PendingFacilityChanges
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}

			{currentPage === 'rejected-history' && (
				<RejectedFacilityChanges
					history={history}
					setQuery={setQuery}
					queryString={queryString}
					query={query}
				/>
			)}
		</>
	);
}
